import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { HttpModule } from '@angular/http';

@Component({
  selector: 'app-list-estado',
  templateUrl: './list-estado.component.html',
  styleUrls: ['./list-estado.component.css'],
  providers: [SserService, StarifasService]
})
export class ListEstadoComponent implements OnInit {
  @Input() serListEstVis: boolean;
  @Output() cerrarSerListEstEvento = new EventEmitter();

  servicios: Servicio[];

  fechaIni: string;
  fechaFin: string;
  message: string[];
  ventanaTarifas: boolean[];
  estados: Estados[];
  estadoSel: string;
  visible: boolean;
  semana: string;
  urgentes: boolean;
  idCiudad:string = "";

  constructor(private sser: SserService, private sempresas: StarifasService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.visible = false;
    this.urgentes = false;
    this.semana = '0';
    this.estadoSel = 'todos';
    this.serListEstVis = false;
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.actualizar();
    this.ventanaTarifas = new Array();
    this.getEstados();


  }
  actualizar() {


    const fech_Ini = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fech_Fin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (fech_Ini==fech_Fin){
      this.semana='0';
   } else this.semana='1';

    if (this.semana == '0') {
      if (this.estadoSel == "todos menos finalizado") {
        this.sser.getSerFech(fech_Ini, null, null, null, "todos menos finalizado", null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });

      } else if (this.estadoSel == "todos") {
        this.sser.getSerFech(fech_Ini, null, null, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, this.estadoSel, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    } else if (this.semana == '1') {
      if (this.estadoSel == "todos menos finalizado") {
        this.sser.getSerFech(fech_Ini, null, null, null, "todos menos finalizado", fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });

      } else if (this.estadoSel == "todos") {
        this.sser.getSerFech(fech_Ini, null, null, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, this.estadoSel, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    }

  }
  actualizaUltimoMes(){
    this.semana='1';
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('month').format('DD/MM/YYYY');

  }
  actualizaConFechaSemana() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').format('DD/MM/YYYY');
  }
  actualizaConFechaProxSem() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaSemAnt() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaHoy() {
    this.semana = '0';
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaConFechaAyer() {
    this.semana = '0';
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaManana() {
    this.semana = '0';
    this.fechaIni = moment().add(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaPasado() {
    this.semana = '0';
    this.fechaIni = moment().add(2, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(2, 'days').format('DD/MM/YYYY');
  }
  asignarTarifa() {

  }
  asignarEstado(estado: string) {
    this.estadoSel = estado;

  }
  actualizarLista() {
    this.ngOnInit();
  }
  getEstados() {
    this.sempresas.getEstados().subscribe(result => {
      this.estados = result;
      this.estados.unshift({ nombre: 'todos menos finalizado', id: '100', visible: false, core: 0 });
      this.estados.unshift({ nombre: 'todos', id: '101', visible: false, core: 0 });
    });
  }
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  actualizarConEspera() {
    this.wait(1000);
    this.actualizar();
  }
}