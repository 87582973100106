import { Component } from '@angular/core';
import { Servicio } from './comun/servicio';
import { Conductor } from './comun/conductor';
import { Estados } from './comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { Router} from '@angular/router';
import { SwPush, SwUpdate } from "@angular/service-worker";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  
vapidKeys = {
  "publicKey": "BNA7JDQrcAbHb3HMMgmQNeDHPprlkgaGA1WBDyHGl8nvzCIuS3QwEEqLCnOhVrFg6nJqmipkRhxYcS7HGC_Qzgs",
  "privateKey": "SLKknD7_Q1H4UW49ItP3wtrKRSElBvmlhTVeZ4siP-w"
};


  title = 'Taxi';
  conductores: Conductor[];
  estados: Estados[];
  nuevoServicioVis: boolean;
  planServicioVis: boolean;
  listServicioVis: boolean;
  empresaListVis: boolean;
  agenServicioVis: boolean;
  empresaVis: boolean;
  mantEmpresaVis: boolean;

  servicios: Servicio[];
  servicio: Servicio;
  headerConfig: any;
  events: any[];


// inyecto ServiciosTaxi
  constructor(private router: Router,private swPush: SwPush) {
    this.nuevoServicioVis = false;
    this.planServicioVis  = false;
    this.listServicioVis  = false;
    this.agenServicioVis = false;
    this.mantEmpresaVis = false;

    if (this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        serverPublicKey: this.vapidKeys.publicKey
      })
        .then(sub => {
          localStorage.setItem('Tmpsub', JSON.stringify(sub));
        })
        .catch(err => {
          if(localStorage.getItem('debug')==='1') console.error("No se puede suscribir a las notificaciones", err)
        });


    } else {
      if(localStorage.getItem('debug')==='1') console.log("Warning::::::> No hay service worker");
    }

  }

  ngOnInit(): void {
  }
}
