import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Propietario } from '../../comun/propietario';
import { Global } from '../../comun/global';

@Component({
  selector: 'app-list-empt',
  templateUrl: './list-empt.component.html',
  styleUrls: ['./list-empt.component.css'],
  providers: [SserService, SempresasService, SfactService]
})
export class ListEmptComponent implements OnInit {
  global: Global = new Global();

 
  @Input() fechaIni: string;
  @Input() fechaFin: string;
  msgs: any;
  empresas: Empresas[]=[];
  sfechaIni: string;
  sfechaFin: string;
  anio: string = moment().format('YY');
  idCiudad:string="";

  @Input() propietario: Propietario;
  @Output() cerraraleVisEvento = new EventEmitter();

  pdfEmpreVis: boolean;
  base = 0;
  iva = 0;

  servicios: Servicio[];

  constructor(private sser: SserService, private sempresas: SempresasService, private sfact: SfactService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.iva= +this.propietario.iva;
    this.sfechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYYMMDD');
    this.sfechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYYMMDD');

    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.pdfEmpreVis = false;

    this.getSerFact();
  }


  getIVA(cantidad: string) {
    let iva = +this.propietario.iva;
    return (+cantidad + (+cantidad * iva) / 100);
  }
  getSerFact() {
    const datos = { 'fechaIni': this.fechaIni, 'fechaFin': this.fechaFin,'idCiudad':this.idCiudad };
    this.sser.getSerFactCli(JSON.stringify(datos)).subscribe(result => {
      this.servicios = result;
      for (let ser of this.servicios) {
        this.base += this.getCalc(ser);
        if (ser.estado == this.global.ESTADO_FINALIZADO) {
          if (ser.tarificacionPorTiempo == 1) {
            //if (ser.idservicio=='2282') 
            
            if(this.getCantDispo(ser)>1) this.base += this.getValDispo(ser);

          }
        }
        //console.log('Servicio >>>>>>'+ser.idservicio);
        //console.log('Servicio >>>>>>'+this.base);
      }
    });
  }
  //Finalizado-Por ruta tarifa Sin Iva para la unidad
  //Finalizado-Por tiempo tarifa Sin Iva para la unidad
  //Anulado tarifa Sin Iva para la unidad
  getCalc(ser: Servicio): number {
     
    if (ser.estado == this.global.ESTADO_FINALIZADO) {
      if (ser.tarificacionPorTiempo == 0) {
        //if (ser.idservicio=='2282') console.log('0>>>>>>');
        let a = Number(this.getCalc1(ser));
        return a;
      } else {
        
         let a = Number(this.getCalc2(ser));
        return a;
      }
    } else if (ser.estado == this.global.ESTADO_ANULADO) {
      //Ver tiempo de  Anulación
      let iva = +this.propietario.iva;
      let tiempoMaxAnulacion = +this.propietario.tiempoMaxAnulacion;
      let tarifaAnulacionSinIva = this.round1(+ser.tarifaAnulacion / (1 + (iva / 100)), 1);

      let tFR = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
      let fFR = moment(ser.FechaDeRecogida, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();

      let dif = this.round1(((fFR - tFR) / 60000), 1);

      if (dif < tiempoMaxAnulacion) {
        return Number(tarifaAnulacionSinIva);
      } else {
        return 0;
      }
    }
  }
  //Finalizado-Por ruta tarifa y paradas
  getCalc1(servicio: Servicio) {
    //console.log('|oooooo  getCalc1  oooooooo|>>>>>>'+servicio.idservicio);
    let a = 0;
    let iva = +this.propietario.iva;
    let tarifaParadaSinIva = this.round1(+servicio.tarifaParada / (1 + (iva / 100)), 1);
    let tarifaSinIva = this.round1(+servicio.tarifa / (1 + (iva / 100)), 1);
    let tarifaAnulacionSinIva = this.round1(+servicio.tarifaAnulacion / (1 + (iva / 100)), 1);

    if (servicio.parada && ((+servicio.tiempoDeEspera) >= (+this.propietario.tiempoParadaCortesia))) {
      a = ((tarifaParadaSinIva) * (+servicio.tiempoDeEspera)) / 60;
      a = this.round1(a, 1);
    }
    if (servicio.estado == this.global.ESTADO_FINALIZADO) return (tarifaSinIva + a);
    else return (tarifaAnulacionSinIva);
  }

  getCalc2(servicio: Servicio) {
   
    let iva = +this.propietario.iva;
    let tarifaSinIva = this.round1(+servicio.tarifa / (1 + (iva / 100)), 1);
    let val = this.getCant(servicio);
    
    return this.round1((tarifaSinIva * val), 1);
  }

  getCalc3(ser: Servicio) {
    if (ser.estado == this.global.ESTADO_FINALIZADO) {
      if (ser.tarificacionPorTiempo == 1) {
        // quito ivas y busco la diff en minutos para la base
        let iva = +this.propietario.iva;
        let tarifaSinIva = this.round1(+ser.tarifa / (1 + (iva / 100)), 1);
        let val = 1;
        return this.round1((tarifaSinIva * val), 1);

      } else {
        //console.log('|ooooooo getCalc3 2 ooooooooo|>>>>>>'+ser.idservicio);
        return this.getCalc2(ser);
      }
    } else {
      //console.log('|ooooooo getCalc3 3 ooooooooo|>>>>>>'+ser.idservicio);
      return this.getCalc(ser);
    }
  }
  getCant(ser: Servicio): number {
    let val = 1;
    return val;
  }

  //calcula el numero de unidades de una dispo
  getCantDispo(ser: Servicio): number {
    let val = 1;
    if (ser.tarificacionPorTiempo == 1){
      //if (ser.idservicio=='2084') console.log('>>>>>>'+2084);
      let init =0;
      let finn =0;
      //if (ser.idservicio=='2051') console.log('>>>>>>ser.tiempoInicioRuta='+ser.tiempoInicioRuta);
      if(ser.tiempoInicioRuta && ser.tiempoFinRuta){
        init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
        finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
        let dif = 0;
        if(init && finn){
          dif = this.round1(((finn - init) / 60000), 1);
          val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);
          if (val < 1) val = 1;
          if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
                val = Math.trunc(val)+1;
            }else{
              val = Math.trunc(val);
            }
        } else {
          console.log('ERROR en servicio >>>>>>'+ser.idservicio);
          val=1;
        } 
      }else val=1;
    }    
    return val;
  }

  //calcula el valor de una dispo que ha superado el tiempo minimo
  getValDispo(ser: Servicio): number {
    let val = 1;
    let tarifaParada=0;

    let init =0;
    let finn =0;
    
    if(ser.tiempoInicioRuta && ser.tiempoFinRuta){
       init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
       finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
   
      if(init && finn && finn>init){
        
        let dif = this.round1(((finn - init) / 60000), 1);//en minutos
        
        // si es menos del tiempo de ruta se cobra una unidad
        // si se supera, se estima el valor
        val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);
        
        if (val < 1) val = 1;
        if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
            val = Math.trunc(val)+1;
        }else{
          val = Math.trunc(val);
        }
      } else {
        console.log('ERROR en servicio >>>>>>'+ser.idservicio);
        val=1;
      } 
    }else val=1;
        //para dispos la tarfifa extra es la tarifa parada
        let iva = +this.propietario.iva;
        tarifaParada = this.round1(+ser.tarifaParada / (1 + (iva / 100)), 1);
    return (val*tarifaParada);
  }




  round1(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
