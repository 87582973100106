import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { Global } from '../../../comun/global';
import { Compania } from '../../../comun/compania';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Horarios } from '../../../comun/horarios';
import { Vehiculo } from '../../../comun/vehiculo';
import { Ciudad } from '../../../comun/ciudad';


@Component({
  selector: 'app-crud-conductor-list',
  templateUrl: './crud-conductor-list.component.html',
  styleUrls: ['./crud-conductor-list.component.css'],
  providers: [StarifasService,SempresasService, SconductoresService]
})
export class CrudConductorListComponent implements OnInit {
  @Input() conductorListVis: boolean;
  @Input() detalleConductorvisible: boolean;
  // @Output() cerrarConductorListEvento = new EventEmitter();
  color: string;

  conductorVis: boolean;
  detalleVis: boolean;
  conductores: Conductor[];
  companias: Compania[];
  message: string[];
  vehiculos: Vehiculo[];
  extbool: boolean = true;
  id0:any;
  ciudades: Ciudad[] = [];
  idCiudad: string;

  constructor(private staf: StarifasService,private sconductores: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.conductorVis = true;
    this.detalleVis = false;
    this.conductorListVis = false;
    this.detalleConductorvisible = false;
    this.getVehiculos();
    this.getCompanias(this.idCiudad);
  }

  borraConductor(id: string) {
    this.message[id] = '¡¡Atencion, ha inactivado el conductor!!';
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 2500);
    let cond =JSON.stringify({'id':id});
    this.sconductores.borraConductor(cond).subscribe();
    this.actualizarLista();

  }
  actualizaFotos(){
    this.actualizaLista(this.id0);
  }
  actualizaLista(id: any) {
    this.id0=id;
    if ( id === '1') {
      if(this.idCiudad){
        let cond =JSON.stringify({'idCiudad':this.idCiudad});
        this.sconductores.getConductores(cond).subscribe(result => {
          this.conductores = result;
        });

      }else{
        this.sconductores.getConductores(null).subscribe(result => {
          this.conductores = result;
        });
      }
    }else {
      let cond =JSON.stringify({'idCompania':id,'idCiudad':this.idCiudad});
      this.sconductores.getConductores(cond).subscribe(result => {
        this.conductores = result;
      });
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizaFotos();
  }

  getCompanias(idCiudad:string) {
    let config = JSON.stringify({ 'estado': 'activo'});
    if(idCiudad!=null) config = JSON.stringify({ 'estado': 'activo', 'idCiudad': idCiudad });
    
    this.sconductores.getCompaniaS(config).subscribe(result => {
      this.companias = result;
      this.companias.unshift({nombre: 'todos', id: '1', cif: '', email: '', visible: false,
      telefono: '', direccion: '', estado: '', fechaAlta: '', fechaBaja: '',
       contacto: '', poblacion: ''});
      // recarga conductores
      this.conductores = [];
      this.actualizaLista('1');
      
    });
  }
  getVehiculos(){
    this.sconductores.getVehiculos(null).subscribe(result => {
      this.vehiculos = result;
    });
  }
  getModeloMatricula(idVehiculo:string){
    if(this.vehiculos){
      for (let veh of this.vehiculos) {
        if(idVehiculo == veh.id) return ((veh.matricula?veh.matricula:'Matrícula no definida')+'--'+(veh.modelo?veh.modelo:'Modelo no definido'));
      }
    }
  }
}
