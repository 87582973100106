import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar-scheduler';


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements AfterViewInit {

  @Input() options;
  @ViewChild('calendar')
  public calendarRef: ElementRef;   //this.calendarRef.nativeElement
  public calendarControl: FormControl; //this.calendarControl = new FormControl();

  ngAfterViewInit() {
    //const containerEl: JQuery = $('app-schedule');
    //containerEl.fullCalendar(this.options);
    this.calendarRef.nativeElement(this.options)
}

}
