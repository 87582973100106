import { Component, OnInit } from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';


@Component({
  selector: 'app-cifrado',
  templateUrl: './cifrado.component.html',
  styleUrls: ['./cifrado.component.css']
})
export class CifradoComponent implements OnInit {


  constructor() { }

  ngOnInit() {
 
  }

}
