import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { ScomunService } from '../../../servicios/scomun.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Incidencia } from '../../../comun/incidencia';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';

@Component({
  selector: 'app-crud-incidencias-list',
  templateUrl: './crud-incidencias-list.component.html',
  styleUrls: ['./crud-incidencias-list.component.css'],
  providers: [ScomunService]
})
export class CrudIncidenciasListComponent implements OnInit {
  @Input() incidenciasListVis: boolean;
  @Input() cerrarIncidenciasListEvento: boolean;
  hayser:boolean;
  sortOrder: number;
  sortOptions: any[];
  sortKey:any;
  sortField: string;
  incidencias: Incidencia[]=[];

  constructor(private scomun: ScomunService) {
  }

  ngOnInit() {
    this.hayser=false;
    this.getIncidencias();
    this.sortOptions = [
      { label: 'fecha mas reciente', value: '!fecha' },
      { label: 'fecha mas antiguo', value: 'fecha' },
      { label: 'Estado', value: 'estado' },
      { label: 'App', value: 'app' },
      { label: 'Autor', value: 'autor' }
    ];
  }
  getIncidencias() {
    this.hayser=false;
    this.scomun.getIncidencias(null).subscribe(result => {
      this.incidencias = result;
      this.hayser=true;
    });
  }
  onSortChange(event) {
    let value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
}

