export class Coordinador {
    nombre: string;
    id: string;
    contrasenia: string;
    telefono: string;
    email: string;
    estado: string;
    idCompania: string;
    fechaAlta: string;
    fechaBaja: string;
    visible: boolean;

}
