import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SsesionService } from '../../../../servicios/ssesion.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Cliente } from '../../../../comun/cliente';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import {Md5} from 'ts-md5/dist/md5';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {InputMaskModule} from 'primeng/inputmask';


@Component({
  selector: 'app-nuevo-usuario-admin',
  templateUrl: './nuevo-usuario-admin.component.html',
  styleUrls: ['./nuevo-usuario-admin.component.css'],
  providers: [SsesionService]
})
export class NuevoUsuarioAdminComponent implements OnInit {
  @Input() altaUsuarioAdminVis: boolean;
  @Output() cerrarAltaUsuarioAdminEvento = new EventEmitter();

  email: string;
  contrasena: string;

  constructor(private ssesion: SsesionService) { }

  ngOnInit() {

  }
  alta() {
    if ( this.contrasena && this.contrasena.length > 0 && this.email && this.email.length > 0) {
      const usuario = ({ 'usuario': this.email.toString(), 'hash':  Md5.hashStr(this.contrasena.toString())});
      // if(localStorage.getItem('debug')==='1') console.log('usuario= ' + JSON.stringify(usuario));
      this.ssesion.setSesionAdm(JSON.stringify(usuario)).subscribe();
      this.cerrarAltaUsuarioAdminEvento.emit();
    }
  }
}