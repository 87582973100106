import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-preferencias',
  templateUrl: './crud-preferencias.component.html',
  styleUrls: ['./crud-preferencias.component.css'],
  providers: [SempresasService]
})
export class CrudPreferenciasComponent implements OnInit {
  @Input() preferenciaVis: boolean;
  @Output() cerrarPreferenciaEvento = new EventEmitter();

  preferenciaNueva: Preferencia = new Preferencia();

  constructor(private sempresasService: SempresasService) { }

  ngOnInit() {
    this.preferenciaVis = false;
  }
  new() {
    this.preferenciaNueva.estado = 'activo';
    this.preferenciaNueva.fechaAlta = moment().format('YYYY-MM-DD');
    this.preferenciaNueva.fechaBaja = '0000-00-00';
    this.preferenciaNueva.visible = false;

    this.sempresasService.setPreferencia(this.preferenciaNueva).subscribe();
    this.cerrarPreferenciaEvento.emit();
  }
}
