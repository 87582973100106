import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ScomunService } from '../../../servicios/scomun.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Incidencia } from '../../../comun/incidencia';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-crud-incidencias',
  templateUrl: './crud-incidencias.component.html',
  styleUrls: ['./crud-incidencias.component.css'],
  providers: [ScomunService]
})
export class CrudIncidenciasComponent implements OnInit {
  @Input() incidenciasVis: boolean;
  @Output() cerrarIncidenciasEvento = new EventEmitter();
  nuevaIncidencia: Incidencia = new Incidencia();

  selectedValues = [];
  apps = [{'nombre':'Admin'},{'nombre':'Conductor'},{'nombre':'Cliente'},{'nombre':'Cargas'}];
  prioridad = [{'nombre':'Alta'},{'nombre':'Media'},{'nombre':'Baja'}];
  impacto= [{'nombre':'Afecta a un servicio crítico.'},{'nombre':'Afecta a muchos usuarios'},{'nombre':'Afecta a un único usuario'}];
  urgencia= [{'nombre':'Impide la realización de un trabajo urgente'},{'nombre':'Existe una fecha límite'},{'nombre':'No es urgente'}];
  checked: boolean = true;

  constructor(private scomun: ScomunService) { }

  ngOnInit() {
    this.incidenciasVis = false;
  
  }
  asignoPrioridad(app: string) {
    this.nuevaIncidencia.prioridad = app;
  }
  asignoImpacto(app: string) {
    this.nuevaIncidencia.impacto = app;
  }
  asignoUrgencia(app: string) {
    this.nuevaIncidencia.urgencia = app;
  }
  asignoApp(app: string) {
    this.nuevaIncidencia.app = app;
  }
  new() {
    this.nuevaIncidencia.fecha=moment().format();
    this.nuevaIncidencia.estado='Estudio';
    this.nuevaIncidencia.autor= localStorage.getItem('username');
    this.scomun.insertIncidencia(JSON.stringify(this.nuevaIncidencia)).subscribe();
    this.cerrarIncidenciasEvento.emit();
  }
}
