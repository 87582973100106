import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { HttpModule } from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { Notificaciones } from '../../comun/notificaciones';
import { SmailService } from '../../servicios/smail.service';
import { Message } from 'primeng/api';
import { Global } from '../../comun/global';
import { Alertas } from '../../comun/alertas';
import { ConfirmationService } from 'primeng/api';
import { Alert } from 'selenium-webdriver';
import { Propietario } from '../../comun/propietario';
import { ConfirmDialog } from 'primeng/primeng';

@Component({
  selector: 'app-new-ale',
  templateUrl: './new-ale.component.html',
  styleUrls: ['./new-ale.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService, ConfirmationService]
})
export class NewAleComponent implements OnInit {

  nuevaAle: Alertas = new Alertas();
  propietario: Propietario;
  @Input() visible;
  @Input() emailResp;
  @Input() asunto;
  @Input() cuerpo;
  @Input() destacar;

  hayPush: boolean = false;


  @Output() cerrarNAEvento = new EventEmitter();
  ssCondMails: any[] = [];
  ssCliMails: any[] = [];
  selectedValuesCli: any[] = [];
  selectedValuesCond: any[] = [];


  constructor(private sser: SserService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getPropietario();
    this.nuevaAle.importancia = "Informativo";
    this.getMailsCondCli();
  }
  actualizar() {
    if(localStorage.getItem('debug')==='1') console.log("---------------------actualizar----------------------------");
    this.ngOnInit();
  }
  estaSeguro() {

    //if(localStorage.getItem('debug')==='1') console.log("Llego a envio la alerta");
    this.confirmationService.confirm({
      message: '¿Esta seguro de enviar la alerta?',
      header: '¡¡¡ Atención !!!',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.enviar();
      },
      reject: () => {
        //if(localStorage.getItem('debug')==='1') console.log("No envio la alerta");
      }
    });
  }

  enviar() {
/*     if (!this.hayPush) { */
      if (this.nuevaAle.cuerpo && this.nuevaAle.titulo && !(this.nuevaAle.titulo == "") && !(this.nuevaAle.cuerpo == "")
        && (this.selectedValuesCli.length > 0 || this.selectedValuesCond.length > 0)) {
        this.nuevaAle.de = this.propietario.emailAdmin;
        this.nuevaAle.fechaAlta = moment().format('YYYY-MM-DD HH:mm:ss');
        this.nuevaAle.estado = "Activo";

        this.nuevaAle.para = "";

        for (let cli of this.selectedValuesCli) {
          this.nuevaAle.para += cli.email + ",";
        }
        for (let con of this.selectedValuesCond) {
          this.nuevaAle.para += con.email + ",";
        }
        this.nuevaAle.para = this.nuevaAle.para.substring(0, this.nuevaAle.para.length - 1);

        var datos = JSON.stringify(this.nuevaAle);

        this.sser.nuevo(datos).subscribe();

        this.visible = false;
        this.cerrarNAEvento.emit();
      }
/*     } else {
      if (this.nuevaAle.cuerpo && this.nuevaAle.titulo && !(this.nuevaAle.titulo == "") && !(this.nuevaAle.cuerpo == "")
        && (this.selectedValuesCli.length > 0 || this.selectedValuesCond.length > 0)) {
        this.setPushNot();
      }
    } */
  }


  setPushNot() {
    let payload = {
      'notification': {
        'title': this.nuevaAle.titulo,
        'body': this.nuevaAle.cuerpo,
        'icon': '/assets/icons/icon-96x96.png'
      }
    };
    let datos = {
      'subject': 'mailto:admin@luxucar.es',
      'publicKey': this.propietario.publicKey,
      'privateKey': this.propietario.privateKey,
      ///////////////////////////////
      'endpoint':'https://fcm.googleapis.com/fcm/send/eC2UYPmrw04:APA91bHTe9V1khmzBFBpD4IfhOpqywTyP_4HkRZnxyYs2rMTrv7PiZkfRPVDvBHYe4Haco7qf1qanJe5fzr3J2Y4Do8zXtrDf0VgkdtXzomqyWDGTEMXOHFewnpuxlkFTwxoyNCBHcvN',
      'publicKey256': 'BDI1O4csCNxJLWDdgtE239KvDYgG9sx4bBhwotvzDpaVBFRo8dANJFESh1-AwzdaCtykqky8RRMuTEcJ176glhs',
      'authToken': 'z-RD7BH-i03RI0KQNX-K1Q',
      'payload': JSON.stringify(payload)
    };
    this.sser.setPushNotify(JSON.stringify(datos)).subscribe(result=>{
      this.visible = false;
      this.cerrarNAEvento.emit();
    });
  }


  getPropietario() {
    this.sser.getPropietario().subscribe(result => {
      for (let res of result) {
        this.propietario = res;
        break;
      }
    });
  }
  getMailsCondCli() {
    var datos = JSON.stringify({ "esCondCli": '0' });
    this.sser.getMailsCondCli(datos).subscribe(result1 => {
      this.ssCliMails = result1;
      var datos = JSON.stringify({ "esCondCli": '1' });
      this.sser.getMailsCondCli(datos).subscribe(result => {
        this.ssCondMails = result;


        let c = null;
        if (this.emailResp && this.ssCondMails && this.ssCondMails.length > 0) {
          this.selectedValuesCond = [];
          for (let c of this.ssCondMails) {
            let aa = c.email.replace(/\s/g, "").toLowerCase();
            let bb = this.emailResp.replace(/\s/g, "").toLowerCase()
            if (aa == bb) {
              this.selectedValuesCond.push(c);
            }
          }
        }
        if (this.emailResp && this.ssCliMails && this.ssCliMails.length > 0) {
          this.selectedValuesCli = [];
          for (let c of this.ssCliMails) {
            let aa = c.email.replace(/\s/g, "").toLowerCase();
            let bb = this.emailResp.replace(/\s/g, "").toLowerCase()

            if (aa == bb) {
              this.selectedValuesCli.push(c);

            }
          }
        }
        if (this.asunto) this.nuevaAle.titulo = this.asunto;
        if (this.cuerpo) this.nuevaAle.cuerpo = this.cuerpo;
        if (this.destacar) this.nuevaAle.importancia = this.destacar;
      });
    });
  }
  cerrar() {
    this.nuevaAle.titulo = '';
    this.nuevaAle.cuerpo = '';
    this.nuevaAle.importancia = '';
    this.selectedValuesCli = [];
    this.selectedValuesCond = [];
    this.cerrarNAEvento.emit();
  }
}

