import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import {HttpModule} from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { SmailService } from '../servicios/smail.service';
import { Servicio } from '../comun/servicio';
import { Propietario } from '../comun/propietario';
import { Cliente } from '../comun/cliente';

export class Notificaciones {
    
    propietario:Propietario;
    smail:SmailService;

    constructor(smail:SmailService) {
        this.smail = smail;
        this.getPropietario();
    }
    sendAnuladoMail(condEmail:string, idConductor:string, servicio:Servicio){
      if(!(idConductor==null)){
        let datosp={'idConductor':idConductor,'body':'Se ha anulado el servicio '+servicio.idservicio+', por favor entre en la app lo antes que pueda y revise sus asignaciones. Gracias'}
        this.smail.sendPush(JSON.stringify(datosp)).subscribe();
      }
        if(condEmail==null) condEmail=this.propietario.emailNotificaciones;
        const msg ="<!doctype html>"+
        "<html>"+
        "<head>"+
        "	<meta charset=\"utf-8\">"+
        "	<link rel=\"stylesheet\" href=\"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css\" integrity=\"sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm\" crossorigin=\"anonymous\">"+
        "	<title>LUXUCAR</title>"+
        "</head>"+
        "<body>"+
        "<img class=\"img-responsive center-block\" src=\"" +this.propietario.logo+"\" alt=\" "+this.propietario.nombreComercial+" \" ><br>"+
        "<hr>"+
        "<br>"+
        "<table class=\"table table-bordered text-center\">"+
        "  <thead>"+
        "    <tr style=\"background-color: grey;color: white\">"+
        "      <th>Fecha</th>"+
        "      <th>Origen</th>"+
        "      <th>Destino</th>"+
        "      <th>Estado</th>"+
        "    </tr>"+
        "  </thead>"+
        "  <tbody>"+
        "    <tr style=\"background-color: blue;color: white\">"+
        "      <td> " +servicio.FechaDeRecogida+" </td>"+
        "      <td> " +servicio.Origen+" </td>"+
        "      <td> "+servicio.Destino+" </td>"+
        "      <td> ANULADO POR EL CLIENTE </td>"+
        "    </tr>"+
        "  </tbody>"+
        "<tfoot>"+
        "<tr>"+
        "<th colspan=\"4\" style=\"background-color: blue;color: white; text-align: center\"><strong>Actualice el estado de sus servicios en la app</strong></th>"+
        "</tr>"+
        "</tfoot>"+
        "</table>"+
        "<br>"+
        "<hr>"+
        "<br>"+
        "</body>"+
        "</html>";
        //if(localStorage.getItem('debug')==='1') console.log('------------>>>>>>>>>>>>>>>msg='+msg);
        const datos={'from':this.propietario.emailNotificaciones,'to':condEmail,'subject': this.propietario.nombreComercial+', ¡¡¡ Atencion, hay cambios en su planificación!!!','message':msg};
        this.smail.sendEmail(JSON.stringify(datos)).subscribe();
      }
      sendAsignadoServicioMail(condEmail:string, idConductor:string, servicio:Servicio){
        if(!(idConductor==null)){
          let datosp={'idConductor':idConductor,'body':'Luxucar le ha asignado un servicio, por favor entre en la app lo antes que pueda y haga click en la pestaña "Asignaciones" para su revisión. Gracias'}
          this.smail.sendPush(JSON.stringify(datosp)).subscribe();
        }
        if(condEmail==null) condEmail=this.propietario.emailNotificaciones;
        const msg ="<!doctype html>"+
        "<html>"+
        "<head>"+
        "	<meta charset=\"utf-8\">"+
        "	<link rel=\"stylesheet\" href=\"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css\" integrity=\"sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm\" crossorigin=\"anonymous\">"+
        "	<title>LUXUCAR</title>"+
        "</head>"+
        "<body>"+
        "<img class=\"img-responsive center-block\" src=\"" +this.propietario.logo+"\" alt=\" "+this.propietario.nombreComercial+" \" ><br>"+
        "<hr>"+
        "<br>"+
        "<table class=\"table table-bordered text-center\">"+
        "  <thead>"+
        "    <tr style=\"background-color: grey;color: white\">"+
        "      <th>Fecha</th>"+
        "      <th>Origen</th>"+
        "      <th>Destino</th>"+
        "      <th>Estado</th>"+
        "    </tr>"+
        "  </thead>"+
        "  <tbody>"+
        "    <tr style=\"background-color: blue;color: white\">"+
        "      <td> " +servicio.FechaDeRecogida+" </td>"+
        "      <td> " +servicio.Origen+" </td>"+
        "      <td> "+servicio.Destino+" </td>"+
        "      <td> </td>"+
        "    </tr>"+
        "  </tbody>"+
        "<tfoot>"+
        "<tr>"+
        "<th colspan=\"4\" style=\"background-color: blue;color: white; text-align: center\"><strong>En su app-asignaciones, encontrara el procedimiento para aprobar/rechazar el servicio</strong></th>"+
        "</tr>"+
        "</tfoot>"+
        "</table>"+
        "<br>"+
        "<hr>"+
        "<br>"+
        "</body>"+
        "</html>";
        //if(localStorage.getItem('debug')==='1') console.log('------------>>>>>>>>>>>>>>>msg='+msg);
        const datos={'from':this.propietario.emailNotificaciones,'to':condEmail,'subject': this.propietario.nombreComercial+', ¡¡¡ Atencion, se le ha asignado un servicio!!!','message':msg};
        this.smail.sendEmail(JSON.stringify(datos)).subscribe();

      }
      getPropietario () {
        let  propietarios: Propietario[];
        this.smail.getPropietario().subscribe(result => {
          propietarios = result;
          for (const prop of propietarios) {
             this.propietario = prop;
             //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
             break;
          }
        });
      }

      
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

}
