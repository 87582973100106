import { Component } from '@angular/core';
import { SsesionService } from '../../../../../servicios/ssesion.service';
import { SempresasService } from '../../../../../servicios/sempresas.service';
import { Servicio } from '../../../../../comun/servicio';
import { Conductor } from '../../../../../comun/conductor';
import { Estados } from '../../../../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-detalle-usuarios-admin',
  templateUrl: './detalle-usuarios-admin.component.html',
  styleUrls: ['./detalle-usuarios-admin.component.css'],
  providers: [SsesionService, SempresasService]
})
export class DetalleUsuariosAdminComponent implements OnInit {
  @Input() usu: any;
  @Output() cerrardetalleUsuariosAdminEvento = new EventEmitter();
  estados: Estados[];

  constructor(private ssesion: SsesionService, private sempresas: SempresasService) {}

  ngOnInit() {
    this.getEstadosbd();
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.usu.estado = estado;
  }
  actualizaUsu() {
    //if(localStorage.getItem('debug')==='1') console.log('this.usu =' + JSON.stringify(this.usu));
    this.ssesion.setUsuAdm(JSON.stringify(this.usu)).subscribe();
    this.cerrardetalleUsuariosAdminEvento.emit();
  }
}
