export class Cargas {
    id: string;
    nombre: string;
    email: string;
    hostname: string;
    subject: string;
    username: string;
    password: string;
    ruta: string;
    empresa: string;
    idEmpresa: string;
    cliente: string;
    idCliente: string;
    estado: string;
    visible:boolean;
    idTipoDeServicio:string;
    tipoDeServicio:string;
    idCiudad:string;
    ciudad:string;
}
