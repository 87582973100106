import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as moment from 'moment';
import * as jQuery from "jquery";

(window as any).$ = (window as any).jQuery = jQuery;

if (environment.production) {
  enableProdMode();
}
//caduca el tkn cada 24 h
const date = localStorage.getItem('hoy');
const hoy = moment().format('DD/MM/YYYY');
 if(!(date===hoy)) localStorage.removeItem('username');

if (environment.production) {
enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {if(localStorage.getItem('debug')==='1') console.log(err);
});
