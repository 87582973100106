import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Propietario } from '../../comun/propietario';
import { Compania } from '../../comun/compania';
import { Global } from '../../comun/global';

@Component({
  selector: 'app-list-compt',
  templateUrl: './list-compt.component.html',
  styleUrls: ['./list-compt.component.css'],
  providers: [SserService, SempresasService, SfactService]
})
export class ListComptComponent implements OnInit {
  global: Global = new Global();

  @Input() compania: Compania;
  @Input() fechaIni: string;
  @Input() fechaFin: string;

  msgs: any;
  sfechaIni: string;
  sfechaFin: string;
  anio: string = moment().format('YY');
  index:number[]=[];
  idCiudad:string="";
  @Input() propietario: Propietario;
  @Output() cerraraleVisEvento = new EventEmitter();

  pdfCondVis: boolean;
  base = 0;
  iva = 0;

  servicios: Servicio[];

  constructor(private sser: SserService, private sempresas: SempresasService, private sfact: SfactService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.iva= +this.propietario.iva;
    this.sfechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYYMMDD');
    this.sfechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYYMMDD');

    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.pdfCondVis = false;

    this.getSerFact();
  }

  getIVA(cantidad: string) {
    let iva = +this.propietario.iva;
    return (+cantidad + (+cantidad * iva) / 100);
  }
  getSerFact() {
    const datos = {'fechaIni': this.fechaIni, 'fechaFin': this.fechaFin,'idCiudad':this.idCiudad };
    this.sser.getSerFactComp(JSON.stringify(datos)).subscribe(result => {
      this.servicios = result;
      if(localStorage.getItem('debug')==='1') console.log("num="+this.servicios.length);
      this.index=[];
      for (let ser of this.servicios) {
        this.base += this.getCalc(ser);
        if (ser.estado == this.global.ESTADO_FINALIZADO) {
          if (ser.tarificacionPorTiempo == 1) {
            if(this.getCantDispo(ser)>1) this.base += this.getValDispo(ser);
          }
        }
      }
      if(localStorage.getItem('debug')==='1') console.log("num="+this.servicios.length);
    });
  }
  //Finalizado-Por ruta tarifa Sin Iva para la unidad
  //Finalizado-Por tiempo tarifa Sin Iva para la unidad
  //Anulado tarifa Sin Iva para la unidad
  getCalc(ser: Servicio): number {
    if (ser.estado == this.global.ESTADO_FINALIZADO) {
      if (ser.tarificacionPorTiempo == 0) {
        let a = Number(this.getCalc1(ser));
        return a;
      } else {
        //if(ser.idservicio=="2748")if(localStorage.getItem('debug')==='1') console.log("2748 tiempo");
         let a = Number(this.getCalc2(ser));
          return a;
      }
    } else if (ser.estado == this.global.ESTADO_ANULADO) {
      let iva = +this.propietario.iva;
      let tiempoMaxAnulacion = +this.propietario.tiempoMaxAnulacion;
      let tarifaAnulacionSinIva = this.round1(+ser.tarifaAnuCon / (1 + (iva / 100)), 1);

      let tFR = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
      let fFR = moment(ser.FechaDeRecogida, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();

      let dif = this.round1(((fFR - tFR) / 60000), 1);

      if (dif < tiempoMaxAnulacion) {
        return Number(tarifaAnulacionSinIva);
      } else {
        return 0;
      }
    }
  }
  //Finalizado-Por ruta tarifa y paradas
  getCalc1(servicio: Servicio) {
    let a = 0;
    let iva = +this.propietario.iva;
    let tarifaParadaSinIva = this.round1(+servicio.tarifaParadaCon / (1 + (iva / 100)), 1);
    let tarifaSinIva = this.round1(+servicio.tarifaConductor / (1 + (iva / 100)), 1);
    let tarifaAnulacionSinIva = this.round1(+servicio.tarifaAnuCon / (1 + (iva / 100)), 1);

    if (servicio.parada && ((+servicio.tiempoDeEspera) >= (+this.propietario.tiempoParadaCortesia))) {
      a = ((tarifaParadaSinIva) * (+servicio.tiempoDeEspera)) / 60;
      a = this.round1(a, 1);
    }
    if (servicio.estado == this.global.ESTADO_FINALIZADO) return (tarifaSinIva + a);
    else return (tarifaAnulacionSinIva);
  }
  //Finalizado-por tiempo tarifa * minutos diff
  getCalc2(servicio: Servicio) {

    // quito ivas y busco la diff en minutos para la base total
    let iva = +this.propietario.iva;
    let tarifaSinIva = this.round1(+servicio.tarifaConductor / (1 + (iva / 100)), 1);
    let val = this.getCant(servicio);
    /* if(servicio.idservicio=="1514")if(localStorage.getItem('debug')==='1') console.log("1514 tarifaSinIva="+tarifaSinIva);
    if(servicio.idservicio=="1514")if(localStorage.getItem('debug')==='1') console.log("1514 val="+val); */
    return this.round1((tarifaSinIva * val), 1);
  }
  //Finalizado-Por tiempo tarifa Sin Iva para la unidad
  getCalc3(ser: Servicio) {
    if (ser.estado == this.global.ESTADO_FINALIZADO) {
      if (ser.tarificacionPorTiempo == 1) {
        // quito ivas y busco la diff en minutos para la base
        let iva = +this.propietario.iva;
        let tarifaSinIva = this.round1(+ser.tarifaConductor / (1 + (iva / 100)), 1);
        let val = 1;
        return this.round1((tarifaSinIva * val), 1);

      } else {
        //if(localStorage.getItem('debug')==='1') console.log('|ooooooo getCalc3 2 ooooooooo|>>>>>>'+ser.idservicio);
        return this.getCalc2(ser);
      }
    } else {
      //if(localStorage.getItem('debug')==='1') console.log('|ooooooo getCalc3 3 ooooooooo|>>>>>>'+ser.idservicio);
      return this.getCalc(ser);
    }
  }
  getCant(ser: Servicio): number {
    let val = 1;
    return val;
  }

  //calcula el numero de unidades de una dispo
  getCantDispo(ser: Servicio): number {
    let val = 1;
    if (ser.tarificacionPorTiempo == 1){
      //if (ser.idservicio=='2748') if(localStorage.getItem('debug')==='1') console.log('>>>>>>'+2084);
      let init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
      let finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
      let dif = 0;
      if(init && finn){
        dif = this.round1(((finn - init) / 60000), 1);//min
        val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);
      }  
      if (val < 1) val = 1;
      if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
          val = Math.trunc(val)+1;
      }else{
        val = Math.trunc(val);
      }
      //if(ser.idservicio=="1514")if(localStorage.getItem('debug')==='1') console.log("1514 val="+val);
    }
    return val;
  }

  //calcula el valor de una dispo que ha superado el tiempo minimo
  getValDispo(ser: Servicio): number {
    let val = 1;
    let tarifaParada=0;

    //if (ser.idservicio=='2084') if(localStorage.getItem('debug')==='1') console.log('>>>>>>'+2084);
    let init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
    let finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
    let dif = this.round1(((finn - init) / 60000), 1);//en minutos
    
    // si es menos del tiempo de ruta se cobra una unidad
    // si se supera, se estima el valor
    val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);
    
    if (val < 1) val = 1;
    if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
        val = Math.trunc(val)+1;
    }else{
      val = Math.trunc(val);
    }
      //para dispos la tarfifa extra es la tarifa parada
      let iva = +this.propietario.iva;
      tarifaParada = this.round1(+ser.tarifaParadaCon / (1 + (iva / 100)), 1);

    return (val*tarifaParada);
  }




  round1(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
