
import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { Servicio } from '../../../comun/servicio';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import {ConfirmationService} from 'primeng/api';
import { Propietario } from '../../../comun/propietario';


@Component({
  selector: 'app-ser-urg',
  templateUrl: './ser-urg.component.html',
  styleUrls: ['./ser-urg.component.css'],
  providers: [SserService, ConfirmationService]
})
export class SerUrgComponent implements OnInit {
/*   @Input() idServicio: string; */
  @Output() cerrarSerUrgEvento = new EventEmitter();
  propietario: Propietario;

  
  constructor(private sser:SserService,private confirmationService: ConfirmationService ) { }

  save8(){
    this.confirmationService.confirm({
      message: 'Va a modificar el servicio urgente a 8h ¿Esta seguro?',
      accept: () => {

         let propietario = JSON.stringify({ 'id': this.propietario.id, 'horasUrgente':'8'});

         this.sser.setPropietario(propietario).subscribe();
            this.cerrarSerUrgEvento.emit();
        }
      });
  }
  save24(){
    this.confirmationService.confirm({
      message: 'Va a modificar el servicio urgente a 8h ¿Esta seguro?',
      accept: () => {

         let propietario = JSON.stringify({ 'id': this.propietario.id, 'horasUrgente':'24'});

         this.sser.setPropietario(propietario).subscribe();
            this.cerrarSerUrgEvento.emit();
        }
      });
  }
  save48(){
    this.confirmationService.confirm({
      message: 'Va a modificar el servicio urgente a 8h ¿Esta seguro?',
      accept: () => {

         let propietario = JSON.stringify({ 'id': this.propietario.id, 'horasUrgente':'48'});

         this.sser.setPropietario(propietario).subscribe();
            this.cerrarSerUrgEvento.emit();
        }
      });
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }

  ngOnInit() {
    this.getPropietario();
  }
  getPropietario () {
    let  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
         break;
      }
    });
  }

}
