import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-cargas',
  templateUrl: './mant-cargas.component.html',
  styleUrls: ['./mant-cargas.component.css']
})
export class MantCargasComponent implements OnInit {
  @Input() mantCargasVis: boolean;
  @Output() cerrarMantCargasEvento = new EventEmitter();
  cargasListVis:boolean;
  cargasVis:boolean;

  constructor() { }

  ngOnInit() {
  }

}
