import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ScheduleModule } from 'primeng/primeng';
import { SserService } from '../servicios/sser.service';
import { SconductoresService } from '../servicios/sconductores.service';
import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Horarios } from '../comun/horarios';
import { Cargas } from '../comun/cargas';
import { Evento } from '../comun/evento';
import { ToggleButtonModule } from 'primeng/primeng';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-cargas',
  templateUrl: './cargas.component.html',
  styleUrls: ['./cargas.component.css'],
  providers: [SserService, MessageService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class CargasComponent implements OnInit {
  @Input() cargasVis: boolean;
  @Output() cerrarCargasEvento = new EventEmitter();
  fich: string = null;
  text: any;
  ok: string = "ok";

  numSerCarg: number = 0;
  fechSerCarg: string = '';

  servicios: string[] = [];

  url: URL;
  msgs: Message[] = [];
  cargaA:  Cargas = new Cargas();
  cargaS:  Cargas = new Cargas();
  cargaT:  Cargas = new Cargas();
  cargaAm: Cargas = new Cargas();

  constructor(private sser: SserService, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.iniciaServiciosAlsa();
    this.iniciaServiciosServicar();
    this.iniciaServiciosTopClass();
    this.iniciaServiciosAmerica();

  }
  iniciaServiciosAmerica() {
    const datos = JSON.stringify({ 'nombre': 'America Miramar' });
    this.sser.getCargas(datos).subscribe(result => {
      if (result) {
        for (const rs of result) {
          this.cargaAm = rs;
        }
      }
    });
  }
  iniciaServiciosTopClass() {
    const datos = JSON.stringify({ 'nombre': 'TOP CLASS AUTOS' });
    this.sser.getCargas(datos).subscribe(result => {
      if (result) {
        for (const rs of result) {
          this.cargaT = rs;
        }
      }
    });
  }
  iniciaServiciosServicar() {
    const datos = JSON.stringify({ 'nombre': 'Servicar' });
    this.sser.getCargas(datos).subscribe(result => {
      if (result) {
        for (const rs of result) {
          this.cargaS = rs;
        }
      }
    });
  }
  iniciaServiciosAlsa() {
    const datos = JSON.stringify({ 'nombre': 'Alsa' });
    this.sser.getCargas(datos).subscribe(result => {
      if (result) {
        for (const rs of result) {
          this.cargaA = rs;
        }
      }
    });
  }
  buscaServiciosAmerica() {
    this.getFicherosAmerica();
  }
  buscaServiciosServicar() {
    this.getFicherosServicar();
  }
  buscaServiciosAlsa() {
    this.getFicherosAlsa();
  }

  buscaServiciosTopClass() {
    this.getFicherosTopClass();
  }


  instruccionesAlsa() {
    this.instrucciones(this.cargaA);
  }
  instruccionesServicar() {
    this.instrucciones(this.cargaS);
  }
  instruccionesTopClass() {
    this.instrucciones(this.cargaT);
  }
  instruccionesAmerica() {
    this.instrucciones(this.cargaAm);
  }



  instrucciones(carga: Cargas) {
    this.messageService.clear();
    this.messageService.add({ severity: 'info', summary: 'Se lee el mailbox:<b>' + carga.username + '</b>', detail: '<br><br><br>¡¡¡Solo se leen mails que proceden de la cuenta: <b>' + carga.email + '</b>!!! <br> y deben tener un subject con el texto: <b>' + carga.subject + '</b>' });
  }
  getFicheros(carga: Cargas): string {
    let datos = JSON.stringify({
      'email_from': carga.email, //'vatpc <vatpc0@gmail.com>',
      'email_subject': carga.subject, //'ficheros',
      'hostname': carga.hostname,//'{luxucar.es:993/imap/ssl/novalidate-cert/norsh}INBOX',//'{imap.gmail.com:993/imap/ssl}INBOX',
      'username': carga.username, //'11221133114411@luxucar.es',
      'password': carga.password, //'2018;#Temp',
      'ruta': carga.ruta, //"..\\ficheros\\"
    });
    return datos;
  }

  getFicherosTopClass() {
    let datos = this.getFicheros(this.cargaT);

    this.sser.getMail2Fich(datos).subscribe(result => {

      if (result) {
        this.numSerCarg = 0;

        for (let res in result) {

          const bstr = atob(result[res]);
          //if(localStorage.getItem('debug')==='1') console.log('=====>>>'+bstr);
          let domparser = new DOMParser();
          let domdoc = domparser.parseFromString(bstr, 'text/html');
          var fecha = '';
          let elements = domdoc.querySelectorAll("td.tdtituloRuta");
          [].forEach.call(elements, function (element) {
            //if(localStorage.getItem('debug')==='1') console.log('--->>>' + element.innerHTML);
            var lines = element.innerHTML.split("FECHA DE INICIO Y FIN DEL SERVICIO -");
            let i = 0;

            for (let line of lines) {
              //if(localStorage.getItem('debug')==='1') console.log(i+'--->>>' + line);
              if (i == 1) {
                let re = /\<\/p>/gi;
                fecha = line.replace(re, "");
                //if(localStorage.getItem('debug')==='1') console.log(i+'--->>>' + fecha);
              }
              i++;
            }

          });
          let i = 0;
          elements = domdoc.querySelectorAll("td.tddatoshojaruta");
          var lines = '';
          [].forEach.call(elements, function (element) {
            //if(localStorage.getItem('debug')==='1') console.log('--->>>' + element.innerHTML);
            lines = element.innerHTML;
          });
          this.ParseaLineaTopClass(fecha, lines, i);
        }
      }
    });
  }
  
  getFicherosServicar() {
    let datos = this.getFicheros(this.cargaS);

    this.sser.getMail2Fich(datos).subscribe(result => {
      let ficheros = result['ficheros'];
      //if(localStorage.getItem('debug')==='1') console.log('======>>>>>'+JSON.stringify(ficheros));
      if (ficheros && ficheros.length > 0) {
        for (let fich of ficheros) {
          if (fich && !(fich == "")) {
            if (fich.is_attachment) {
              const bstr = atob(fich.attachment);

              const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
              const wsname: string = wb.SheetNames[0];
              const ws: XLSX.WorkSheet = wb.Sheets[wsname];
              this.text = XLSX.utils.sheet_to_csv(ws, { FS: ";" });

              var lines = this.text.split("\n");
              let i = 0;
              for (let line of lines) {
                if (i > 1) this.ParseaLineaServicar(line, i);
                i++;
                this.numSerCarg = (i - 2);
              }
              this.numSerCarg += 1; //carga desde 1
            }
          }
        }
      }
    });
  }

  getFicherosAlsa() {
    let datos = this.getFicheros(this.cargaA);

    this.sser.getMail2Fich(datos).subscribe(result => {
      //  let ficheros = result['ficheros'];
      if (result) {
        this.numSerCarg = 0;

        for (let res in result) {

          const bstr = atob(result[res]);
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          this.text = XLSX.utils.sheet_to_csv(ws, { FS: ";" });

          var lines = this.text.split("\n");
          let i = 0;
          let j = 0;
          for (let line of lines) {
            if (j > 1) {
              if (this.ParseaLineaAlsa(line, 1)) i++;
            }
            j++;
          }
          this.numSerCarg += i;
        }
        if (this.servicios.length > 0) {

          //if(localStorage.getItem('debug')==='1') console.log(' Inserto servicio:= '+JSON.stringify(this.servicios));

          this.sser.newServicioI(JSON.stringify(this.servicios)).subscribe();
          /* for(let ser of this.servicios){
            //if(localStorage.getItem('debug')==='1') console.log("origen="+ser.Origen);
          } */
          this.servicios = [];
        }
        this.resultado();
      }
    });
  }




  getFicherosAmerica(){
    let datos = this.getFicheros(this.cargaAm);

    this.sser.getMail2Fich(datos).subscribe(result => {

      if (result) {
        this.numSerCarg = 0;

        for (let res in result) {

          const bstr = atob(result[res]);
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          this.text = XLSX.utils.sheet_to_csv(ws, { FS: ";" });

          var lines = this.text.split("\n");
          let i = 0;
          let j = 0;
          for (let line of lines) {
            if (j > 2) {
              if (this.ParseaLineaAmerica(line, 1)) i++;
            }
            j++;
          }
          this.numSerCarg += i;
        }
        if (this.servicios.length > 0) {

          //if(localStorage.getItem('debug')==='1') console.log(' Inserto servicio:= '+JSON.stringify(this.servicios));

          this.sser.newServicioI(JSON.stringify(this.servicios)).subscribe();
          /* for(let ser of this.servicios){
            //if(localStorage.getItem('debug')==='1') console.log("origen="+ser.Origen);
          } */
          this.servicios = [];
        }
        this.numSerCarg=this.numSerCarg-2;
        this.resultado();
      }
    });

  }
  ParseaLineaAmerica(line: string, i: number): boolean {

    let anio = moment().format('YYYY');
    var campos = line.split(";");
    
    let origen = campos[0];
    let destino = campos[1];
    let fechaDeRecogida = campos[2];
    let horaDeRecogida  = campos[3];
    let cartel = campos[5];
    let cartelLink = campos[6];
    let vuelo = campos[7];
    let comentario = campos[8];
    let pasajero = campos[9];


    
       
      const fdr = moment(fechaDeRecogida+' '+horaDeRecogida, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      const fdp = moment().format('YYYY-MM-DD HH:mm:ss');
      const empresa = this.cargaAm.empresa;
      const idEmpresa = this.cargaAm.idEmpresa;
      const cliente = this.cargaAm.cliente;
      const idCliente = this.cargaAm.idCliente;
      const estado = this.cargaAm.estado;
      const idTipoDeServicio = this.cargaAm.idTipoDeServicio;
      const tipoDeServicio = this.cargaAm.tipoDeServicio;
      const idCiudad = this.cargaAm.idCiudad;
      const ciudad = this.cargaAm.ciudad;

      let servicio = JSON.stringify({
        'empresa': empresa, 'idEmpresa': idEmpresa, 'cliente': cliente, 'idCliente': idCliente, 'estado': estado, 
        'Origen': origen, 'Destino': destino, 'FechaDeRecogida': fdr, 'FechaDePeticion': fdp, 'pasajero': pasajero, 'vuelo': vuelo,
        'idTipoDeServicio': idTipoDeServicio, 'tipoDeServicio': tipoDeServicio, 'imgCartel':cartelLink, 'cartel':cartel,'comentarioCli':comentario,
        'idCiudad':idCiudad,'ciudad':ciudad
      });

      this.servicios.push(servicio);
      return true;
  }


  ParseaLineaAlsa(line: string, i: number): boolean {

    let anio = moment().format('YYYY');
    var campos = line.split(";");
    let vuelo = '';
    let dia = campos[0];
    let hora = campos[1];
    let origen = campos[2];
    let cod1post = campos[3];
    let destino = campos[4];
    let pasajero = campos[5];


    if (!(origen == null) && (origen.indexOf("APTO") >= 0)) {
      var vuelos = origen.split("APTO");
      vuelo = vuelos[1];
      origen = 'Aeropuerto'
    }
    if (!(destino == null) && destino.indexOf('APTO') >= 0) {
      var vuelos = destino.split("APTO");
      vuelo = vuelos[1];
      destino = 'Aeropuerto'
    }
    if (!(vuelo == null) && vuelo.indexOf('/') >= 0) {
      var vuelos = vuelo.split("/");
      vuelo = vuelos[1];
    }

    if (origen == 'Aeropuerto' && destino == 'Aeropuerto') {
      //no se hace nada    
    } else if (origen == 'Aeropuerto') {
      destino = destino + ' ' + cod1post;
    } else {
      origen = origen + ' ' + cod1post;
    }

    if (!((dia == '' || dia == null) && (hora == '' || hora == null))) {

      vuelo = vuelo.trim();
      var vuelos = vuelo.split(" ");
      let q = '';
      let next = false;
      for (let vue of vuelos) {
        if (next) {
          q = q + vue;
          next = false;
        }
        if (vue.indexOf('IB') >= 0) {
          if (vue.length == 2) {
            next = true;
          }
          q = vue;
        }

      }

      const fdr = moment(dia + '-' + anio + ' ' + hora, 'DD-MMM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      this.fechSerCarg = moment(dia + '-' + anio + ' ' + hora, 'DD-MMM-YYYY HH:mm').format('YYYY-MM-DD');
      const fdp = moment().add(i, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      const empresa          = this.cargaA.empresa;
      const idEmpresa        = this.cargaA.idEmpresa;
      const cliente          = this.cargaA.cliente;
      const idCliente        = this.cargaA.idCliente;
      const estado           = this.cargaA.estado;
      const idTipoDeServicio = this.cargaA.idTipoDeServicio;
      const tipoDeServicio   = this.cargaA.tipoDeServicio;
      const idCiudad         = this.cargaA.idCiudad;
      const ciudad           = this.cargaA.ciudad;

      let servicio = JSON.stringify({
        'empresa': empresa, 'idEmpresa': idEmpresa, 'cliente': cliente, 'idCliente': idCliente, 'estado': estado, 'cartel': 'Coordinador IBL',
        'Origen': origen, 'Destino': destino, 'FechaDeRecogida': fdr, 'FechaDePeticion': fdp, 'pasajero': pasajero, 'vuelo': q,
        'idTipoDeServicio': idTipoDeServicio, 'tipoDeServicio': tipoDeServicio,'idCiudad':idCiudad,'ciudad':ciudad
      });

      this.servicios.push(servicio);
      return true;
    } else {
      return false;
    }
  }

  ParseaLineaServicar(line: string, i: number) {
    if (line == '') { } else {
      let anio = moment().format('YYYY');
      var campos = line.split(";");
      let vuelo = '';
      let dia = campos[0];
      let hora = campos[1];
      let origen = campos[3];
      let destino = campos[5];
      let pasajero = campos[6];
      let cartel = campos[7];

      if (!(origen == null) && (origen.indexOf("#") >= 0)) {
        var vuelos = origen.split(" ");
        vuelo = vuelos[0];
        origen = 'Aeropuerto'
      }
      if (!(destino == null) && destino.indexOf('#') >= 0) {
        var vuelos = destino.split(" ");
        vuelo = vuelos[0];
        destino = 'Aeropuerto'
      }
      if (!(vuelo == null) && vuelo.indexOf('#') >= 0) {
        let re = /\#/gi;
        vuelo = vuelo.replace(re, "");
      }


      const fdr = moment(dia + '-' + anio + ' ' + hora, 'DD-MMM-YYYY HHmm').format('YYYY-MM-DD HH:mm:ss');
      this.fechSerCarg = moment(dia + '-' + anio + ' ' + hora, 'DD-MMM-YYYY HH:mm').format('YYYY-MM-DD');
      const fdp = moment().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      const empresa          = this.cargaS.empresa;
      const idEmpresa        = this.cargaS.idEmpresa;
      const cliente          = this.cargaS.cliente;
      const idCliente        = this.cargaS.idCliente;
      const estado           = this.cargaS.estado;
      const idTipoDeServicio = this.cargaS.idTipoDeServicio;
      const tipoDeServicio   = this.cargaS.tipoDeServicio;
      const idCiudad         = this.cargaS.idCiudad;
      const ciudad           = this.cargaS.ciudad;

      let servicio = JSON.stringify({
        'empresa': empresa, 'idEmpresa': idEmpresa, 'cliente': cliente, 'idCliente': idCliente, 'estado': estado, 'cartel': cartel,
        'Origen': origen, 'Destino': destino, 'FechaDeRecogida': fdr, 'FechaDePeticion': fdp, 'pasajero': pasajero, 'vuelo': vuelo,
        'idTipoDeServicio': idTipoDeServicio, 'tipoDeServicio': tipoDeServicio,'idCiudad':idCiudad,'ciudad':ciudad
      });

      if(localStorage.getItem('debug')==='1') console.log(i + ' Inserto servicio:= ' + this.numSerCarg + ' en t:=' + moment().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss'));
      this.sser.newServicioI(servicio).subscribe();
      this.resultado();

    }
  }


  ParseaLineaTopClass(fecha: string, lines: any, i: number) {

    if (lines == '') { } else {
      let anio = moment(fecha, 'DD/MM/YY').format('DD/MM/YYYY');

      // if(localStorage.getItem('debug')==='1') console.log('anio=' + anio);

      let re = /\<\/p>/gi;
      lines = lines.replace(re, "");
      var campos = lines.split("<p>");

      let lugares = campos[4];
      var lcampos = lugares.split("-");
      let origen = lcampos[0];
      re = /\:/gi;
      origen = origen.replace(re, "");
      let destino = lcampos[1];

      let hora = campos[5];
      let horas = hora.split("/");
      hora = horas[0];


      let vuelo = campos[6];
      let pasajero = campos[7];
      let cartel = campos[8];

      let fdr = moment(anio + ' ' + hora, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');

      this.fechSerCarg = moment(anio + ' ' + hora, 'DD-MMM-YYYY HH:mm').format('YYYY-MM-DD');

      const fdp = moment().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      const empresa          = this.cargaT.empresa;
      const idEmpresa        = this.cargaT.idEmpresa;
      const cliente          = this.cargaT.cliente;
      const idCliente        = this.cargaT.idCliente;
      const estado           = this.cargaT.estado;
      const idTipoDeServicio = this.cargaT.idTipoDeServicio;
      const tipoDeServicio   = this.cargaT.tipoDeServicio;
      const idCiudad         = this.cargaT.idCiudad;
      const ciudad           = this.cargaT.ciudad;

      let servicio = JSON.stringify({
        'empresa': empresa, 'idEmpresa': idEmpresa, 'cliente': cliente, 'idCliente': idCliente, 'estado': estado, 'cartel': cartel,
        'Origen': origen, 'Destino': destino, 'FechaDeRecogida': fdr, 'FechaDePeticion': fdp, 'pasajero': pasajero, 'vuelo': vuelo,
        'idTipoDeServicio': idTipoDeServicio, 'tipoDeServicio': tipoDeServicio,'idCiudad':idCiudad,'ciudad':ciudad
      });

      if(localStorage.getItem('debug')==='1') console.log(i + ' Inserto servicio:= ' + this.numSerCarg + ' en t:=' + moment().add(i, 'seconds').format('YYYY-MM-DD HH:mm:ss'));
      //this.sser.newServicioI(servicio).subscribe();
      this.resultado();

    }
  }
  resultado() {
    this.confirmationService.confirm({
      message: 'Se han cargado: ' + this.numSerCarg + ' con fecha: ' + this.fechSerCarg + ' ',
      accept: () => {
      }
    });
  }
}

