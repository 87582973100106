import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ScomercialgestorService } from '../../../../servicios/scomercialgestor.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { Gestor } from '../../../../comun/gestor';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';

import * as moment from 'moment';

@Component({
  selector: 'app-detalle-gestor',
  templateUrl: './detalle-gestor.component.html',
  styleUrls: ['./detalle-gestor.component.css'],
  providers: [ScomercialgestorService, SempresasService]
})
export class DetalleGestorComponent implements OnInit {
  @Input() gestor: Gestor;
  detalleGestorvisible: boolean;
  gestorNueva: Gestor;
  @Output() guardarGestorEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarGestorListEvento = new EventEmitter();
  dealleVis: boolean;

  estados: Estados[];


  constructor(private sser: ScomercialgestorService, private sempresas: SempresasService) { }

  ngOnInit() {
    this.gestorNueva = JSON.parse(JSON.stringify(this.gestor,this.replacer));
    this.gestorNueva.visible = false;
    this.getEstadosbd();
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  actualizaGestor() {
    this.sser.upGestor(JSON.stringify(this.gestorNueva)).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarGestorListEvento.emit();
  }
  
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }

  asignoEstado(estado: string) {
    this.gestorNueva.estado = estado;
    if (estado === 'inactivo') {
      this.gestorNueva.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.gestorNueva.fechaBaja = '0000-00-00';
      this.gestorNueva.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
}
