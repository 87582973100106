export class Incidencia {
    fecha: string;
    app: string;
    descripcion: boolean;
    estado: string;
    autor:string;
    urgencia:string;
    impacto:string;
    prioridad:string;
    version:string;
}
