import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ScomercialgestorService } from '../../../servicios/scomercialgestor.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Gestor } from '../../../comun/gestor';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';

import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-crud-gestor',
  templateUrl: './crud-gestor.component.html',
  styleUrls: ['./crud-gestor.component.css'],
  providers: [ScomercialgestorService, ConfirmationService]
})
export class CrudGestorComponent implements OnInit {
  
  @Output() cerrarGestorEvento = new EventEmitter();

  nuevoGestor: Gestor = new Gestor();
   selectedValues = [];
  otro = [];
  checked: boolean = true;
  ok:any;

  constructor(private sser: ScomercialgestorService,private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.nuevoGestor.recibirNot=1;
    this.sser.ngOnInit();
  }
  new() {

    if (this.nuevoGestor.nombre !== '' &&
        this.nuevoGestor.direccion !== '' &&
        this.nuevoGestor.telefono !== '' &&
        this.nuevoGestor.email !== '' ) {
          if(this.checked) this.nuevoGestor.recibirNot=1;
          else this.nuevoGestor.recibirNot=0;
          this.nuevoGestor.estado = 'activo';
          this.nuevoGestor.fechaAlta = moment().format('YYYY-MM-DD');
          this.nuevoGestor.fechaBaja = '0000-00-00';

          this.sser.setGestor(JSON.stringify(this.nuevoGestor)).subscribe(result=>{
            
            let msg ="Se ha creado el gestor";
            
            if (result.error){
              msg = "No se ha creador el gestor debido al siguiente error: "+result.error;
            } 

            this.confirmationService.confirm({
              message: msg,
              accept: () => {
                this.cerrarGestorEvento.emit();
              }
            });
       
          });

    }
  }
}
