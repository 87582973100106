import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { EmpresaPeticion } from '../../../../comun/empresa-peticion';
import { Ciudad } from '../../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';
import { StarifasService } from '../../../../servicios/starifas.service';

@Component({
  selector: 'app-detalle-empresa',
  templateUrl: './detalle-empresa.component.html',
  styleUrls: ['./detalle-empresa.component.css'],
  providers: [SempresasService,StarifasService,ConfirmationService]
})
export class DetalleEmpresaComponent implements OnInit {

  @Input() empresa: Empresas;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarEmpresaListEvento = new EventEmitter();

  detalleEmpresavisible: boolean;
  empresaNueva: Empresas;
  estados: Estados[];
  selectedCiudades: Ciudad[] = [];
  notSelectedCiudades: Ciudad[] = [];
  ciudades: Ciudad[] = [];
  ok:any;


  constructor(private sempresas: SempresasService,private staf: StarifasService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getCiudades();
    this.empresaNueva = new Empresas();
    Object.assign(this.empresaNueva, this.empresa);
    this.getEstadosbd();
  }
  
  update() {
    let todok = false;
    if (this.selectedCiudades.length == 0) {
      this.confirmationService.confirm({
        message: "Debe dar valor a todos los campos ",
        accept: () => {
        }
      });
    } else {
        todok = true;
    }
    if (todok) {
      this.empresaNueva.visible = false;
      this.empresa = this.empresaNueva;

      this.sempresas.updateEmpresa(this.empresaNueva).subscribe(result => {

        let msg = "Se ha modificado la empresa";

        if (result.error) {
          msg = "No se ha modificado la empresa debido al siguiente error: " + result.error;
        } else if (result.id) {

          if (this.selectedCiudades.length > 0) {
            let aa = "[";
            for (let cs of this.selectedCiudades) {
              aa += "{\"idEmpresa\": \"" + this.empresaNueva.id + "\", \"idCiudad\": \"" + cs.id + "\"},";
            }
            aa = aa.substring(0, aa.length - 1) + "]";
            this.sempresas.upEmp2ciu(aa).subscribe();
          }
        }

        this.confirmationService.confirm({
          message: msg,
          accept: () => {
            this.cerrarDetalleVisEvento.emit();
            this.actualizarEmpresaListEvento.emit();
          }
        });
      });
    }
  }

  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.empresaNueva.estado = estado;
    if (estado === 'inactivo') {
      this.empresaNueva.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.empresaNueva.fechaBaja = '0000-00-00';
      this.empresaNueva.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      this.notSelectedCiudades = result;
      this.getComp2ciu();
    });
  }
  getComp2ciu() {
    this.sempresas.getEmp2ciu(this.empresa.id).subscribe(result => {
      let cp: Ciudad[] = [];
      for (let res of result) {
        let a = this.ciudades.find(item => item.id == res.idCiudad)
        if (!cp[a.id]) {
          cp[a.id] = a;
          this.selectedCiudades.push(a);
        }
      }
      for (let res of this.selectedCiudades) {
  
        this.notSelectedCiudades = this.notSelectedCiudades.filter(item => item.id !== res.id)
      }
    });
  }

}
