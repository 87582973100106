import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-vehiculo',
  templateUrl: './mant-vehiculo.component.html',
  styleUrls: ['./mant-vehiculo.component.css']
})
export class MantVehiculoComponent implements OnInit {

  @Input() mantVehiculoVis: boolean;
  @Output() cerrarMantVehiculoEvento = new EventEmitter();
  conductorListVis: boolean;
  conductorVis: boolean;
  vehiculoListVis:boolean;
  vehiculoVis:boolean;

  constructor() { }

  ngOnInit() {
    this.mantVehiculoVis = false;
    this.conductorVis = false;
    this.conductorListVis = false;
  }
}
