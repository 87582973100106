import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Compania } from '../comun/compania';
import { CompaniaPeticion } from '../comun/compania-peticion';
import { Global } from '../comun/global';
import { Conductor } from '../comun/conductor';
import { TipoDeServicios } from '../comun/tipo-de-servicios';
import { TipoSer2prefS } from '../comun/tipo-ser2pref-s';
import { HttpClient } from "@angular/common/http";


@Injectable()
export class SconductoresService {
  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para tipo de servicio----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setTipodeservicio(tipoDeServicio: TipoDeServicios): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipodeservicio=') + encodeURIComponent(JSON.stringify(tipoDeServicio)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipodeservicio=') + encodeURIComponent(JSON.stringify(tipoDeServicio)));
  }
  upTipodeservicio(tipoDeServicio: TipoDeServicios): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosU.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&tipodeservicio=') + (JSON.stringify(tipoDeServicio)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipodeservicio=') + encodeURIComponent(JSON.stringify(tipoDeServicio)));
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para conductores----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getConductores(conductor: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (conductor) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS.php?tkn=' + (JSON.stringify(this.tkn))
        + ('&conductor=') + ((conductor)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&conductor=') + encodeURIComponent((conductor)));
    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
    }
  }
  getConductoresE(tarifaConductor: string, idTipoDeServicio: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const conductor = ({ tarifaConductor: tarifaConductor, idTipoDeServicio: idTipoDeServicio });

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS_T.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&conductor=') + (JSON.stringify(conductor)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS_T.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + encodeURIComponent(JSON.stringify(conductor)));

  }
  getConductoresOcupados(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS_O.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorS_O.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));

  }
  getConductoresExentos(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS_O.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&datos=') + datos);

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/horariosS_O.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&datos=') + encodeURIComponent(datos));

  }
  setConductor(conductor: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + encodeURIComponent(conductor));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + encodeURIComponent(conductor));
  }


  upConductor(conductor: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + (conductor));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + encodeURIComponent(conductor));
  }



  borraConductor(conductor: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorD.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&conductor=') + (conductor));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/conductorD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&conductor=') + encodeURIComponent(conductor));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para compañias----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getCompanias(compania: CompaniaPeticion): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (compania) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + (JSON.stringify(this.tkn))
        + ('&compania=') + JSON.stringify(compania));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&compania=') + encodeURIComponent(JSON.stringify(compania)));
    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + (JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
    }
  }
  getCompaniaS(compania: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (compania) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + (JSON.stringify(this.tkn))
        + ('&compania=') + (compania));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&compania=') + (compania));
    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + (JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
    }
  }

  setCompania(compania: Compania): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&compania=') + encodeURIComponent(JSON.stringify(compania)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&compania=') + encodeURIComponent(JSON.stringify(compania)));
  }
  upCompania(compania: CompaniaPeticion): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&compania=') + encodeURIComponent(JSON.stringify(compania)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&compania=') + encodeURIComponent(JSON.stringify(compania)));
  }
  borraCompania(conductor: CompaniaPeticion): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaD.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&compania=') + (JSON.stringify(conductor)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/companiaD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&compania=') + encodeURIComponent(JSON.stringify(conductor)));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para tipos de servicios----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getTipoDeServicios(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoServiciosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para tipoSer2prefS   ----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getTipoSer2pref(tipoSer2prefS: TipoSer2prefS): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefS_id.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + (JSON.stringify(tipoSer2prefS)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefS_id.php?tkn='
      + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + encodeURIComponent(JSON.stringify(tipoSer2prefS)));
  }
  getTipoSer2prefR(tipoSer2prefS: TipoSer2prefS): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefSR.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + (JSON.stringify(tipoSer2prefS)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefSR.php?tkn='
      + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + encodeURIComponent(JSON.stringify(tipoSer2prefS)));
  }
  upTipoSer2pref(tipoSer2prefS: TipoSer2prefS): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + (JSON.stringify(tipoSer2prefS)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefI.php?tkn='
      + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + encodeURIComponent(JSON.stringify(tipoSer2prefS)));
  }
  borraTipoSer2pref(tipoSer2prefS: TipoSer2prefS): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + (JSON.stringify(tipoSer2prefS)));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/tipoSer2prefD.php?tkn='
      + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tipoSer2prefS=') + encodeURIComponent(JSON.stringify(tipoSer2prefS)));
  }

  setVehiculo(vehiculo: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + (vehiculo));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + encodeURIComponent((vehiculo)));
  }
  upVehiculo(vehiculo: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + (vehiculo));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + encodeURIComponent((vehiculo)));
  }
  getVehiculos(vehiculo: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (vehiculo == null) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&vehiculo=') + (vehiculo));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&vehiculo=') + encodeURIComponent((vehiculo)));
    }
  }
  borraVehiculo(vehiculo: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + (vehiculo));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&vehiculo=') + encodeURIComponent((vehiculo)));
  }





  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setCategoria(categoria: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + (categoria));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + encodeURIComponent((categoria)));
  }
  getCategorias(categoria: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (categoria == null) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    } else {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&categoria=') + (categoria));

      return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/vehiculoS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&categoria=') + encodeURIComponent((categoria)));
    }
  }

  upCategoria(categoria: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + (categoria));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + encodeURIComponent((categoria)));
  }

  borraCategoria(categoria: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + (categoria));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/categoriaD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&categoria=') + encodeURIComponent((categoria)));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////#endregion
  setCoordinador(coordinador: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&coordinador=') + (coordinador));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&coordinador=') + encodeURIComponent((coordinador)));
  }
  getCoordinadores(coordinador: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (coordinador ? ('&coordinador=') + (coordinador) : ''));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (coordinador ? ('&coordinador=') + encodeURIComponent((coordinador)) : ''));
  }
  upCoordinador(coordinador: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (coordinador ? ('&coordinador=') + (coordinador) : ''));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/coordinadorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (coordinador ? ('&coordinador=') + encodeURIComponent((coordinador)) : ''));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setComp2ciu(comp2ciu: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&comp2ciu=') + encodeURIComponent(comp2ciu));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&comp2ciu=') + encodeURIComponent(comp2ciu));
  }
  getComp2ciu(idCompania: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    let dat = JSON.stringify({ 'idCompania': idCompania });

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuS.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&comp2ciu=') + (dat));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&comp2ciu=') + encodeURIComponent(dat));
  }
  upComp2ciu(comp2ciu: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&comp2ciu=') + encodeURIComponent(comp2ciu));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantConductor/comp2ciuU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&comp2ciu=') + encodeURIComponent(comp2ciu));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

