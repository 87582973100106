import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Vehiculo } from '../../../comun/vehiculo';

import {HttpModule} from '@angular/http';

@Component({
  selector: 'app-list-fecha',
  templateUrl: './list-fecha.component.html',
  styleUrls: ['./list-fecha.component.css'],
  providers: [SserService, SconductoresService]
})
export class ListFechaComponent implements OnInit {
  @Input() serListFechVis: boolean;
  @Output() cerrarSerListFechEvento = new EventEmitter();
  servicios: Servicio[];
  fecha: string;
  fechaIni: string;
  fechaFin: string;
  message: string[];
  ventanaTarifas: boolean[];
  visible: boolean;
  semana: string;
  urgentes: boolean;
  conductores:Conductor[];
  vehiculos: Vehiculo[];
  idCiudad:string="";
  constructor(private sser: SserService, private sscond:SconductoresService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.urgentes = false;
    this.semana = '0';
    this.visible= false;
    this.serListFechVis = false;
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.actualizar();
    this.ventanaTarifas = new Array ();
    this.getVehiculos();
  }
  actualizar() {
    
    this.visible = true;
    const fech_Ini = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fech_Fin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (fech_Ini==fech_Fin){
      this.semana='0';
   } else this.semana='1';

    if (this.semana == '0') {
        this.sser.getSerFech(fech_Ini, null, null, null, null,null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
    } else if (this.semana == '1') {
      this.sser.getSerFech(fech_Ini, null, null, null, null,fech_Fin,this.idCiudad).subscribe(result => {
        this.servicios = result;
      });
    }
  }
  getVehiculos(){
    this.sscond.getVehiculos(null).subscribe(result => {
      this.vehiculos = result;
    });
  }
  getTelf_Cond(id:string){
    for(let c of this.conductores){
      if(c.id==id) return c.telefono;
    }
  }
  getConductores(){
    this.sscond.getConductores(null).subscribe(result=>{
      this.conductores=result;
    });
  }
  actualizaUltimoMes(){
    this.semana='1';
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('month').format('DD/MM/YYYY');

  }
  actualizaConFechaSemana() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').format('DD/MM/YYYY');
  }
  actualizaConFechaProxSem() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaSemAnt() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaHoy() {
    this.semana = '0';
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaConFechaAyer() {
    this.semana = '0';
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaManana() {
    this.semana = '0';
    this.fechaIni = moment().add(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaPasado() {
    this.semana = '0';
    this.fechaIni = moment().add(2, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(2, 'days').format('DD/MM/YYYY');
  }
  asignarTarifa() {

  }
  asignarConductor(servicio: Servicio) {

  }
  actualizarLista() {
    this.ngOnInit();
  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizar();
  }











}
