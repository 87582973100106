import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-usuarios',
  templateUrl: './mant-usuarios.component.html',
  styleUrls: ['./mant-usuarios.component.css']
})
export class MantUsuariosComponent implements OnInit {
  @Input() mantUsuariosVis: boolean;
  @Output() cerrarMantUsuariosEvento = new EventEmitter();
  usuarioVis: boolean;
  usuarioListVis: boolean;

  constructor() { }

  ngOnInit() {
  }

}
