import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Compania } from '../../../comun/compania';
import { CompaniaPeticion } from '../../../comun/compania-peticion';
import { Empresas } from '../../../comun/empresas';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-compania-list',
  templateUrl: './crud-compania-list.component.html',
  styleUrls: ['./crud-compania-list.component.css'],
  providers: [SconductoresService]
})
export class CrudCompaniaListComponent implements OnInit {
  @Input() companiaListVis: boolean;
  @Input() detalleCompaniavisible: boolean;
  @Output() cerrarCompaniaListEvento = new EventEmitter();

  companias: Compania[];
  message: string[];
  msg: string;
  idCiudad: string;

  constructor(private sconductores: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.companiaListVis = false;
    this.detalleCompaniavisible = false;
    this.getcompanias();
  }
  getcompanias() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sconductores.getCompaniaS(cond).subscribe(result => {
      this.companias = result;
    });
  }
  borraCompania(id: string) {
    const compania: CompaniaPeticion = new CompaniaPeticion();
    compania.id = id;
    this.sconductores.borraCompania(compania).subscribe(result => {
      this.msg = result.message;
    });
    // console.log('------>>>>>>>>' + this.msg);
    if (this.msg === 'OK') {
      this.message[id] = '¡¡Atencion, ha inactivado la empresa!!';
    } else if (this.msg === 'NOK') {
      this.message[id] = '¡¡Atencion, se ha producido un error!!';
    } else {
      this.message[id] = this.msg;
    }
    setTimeout(function () {
      this.message[id] = null;
    }.bind(this), 1500);
    this.msg = '';
    this.actualizarLista();
  }

  actualizarLista() {
    this.ngOnInit();
  }
}