import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { StarifasService } from '../../../../servicios/starifas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Ciudad } from '../../../../comun/ciudad';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../../comun/empresas';
import { Tarifa } from '../../../../comun/tarifa';
import { Ruta } from '../../../../comun/ruta';

@Component({
  selector: 'app-detalle-tarifas',
  templateUrl: './detalle-tarifas.component.html',
  styleUrls: ['./detalle-tarifas.component.css'],
  providers: [SempresasService, StarifasService, SconductoresService]
})
export class DetalleTarifasComponent implements OnInit {

  @Input() tarifa: Tarifa;
  detalletarifavisible: boolean;
  nuevaTarifa: Tarifa;
  ciudades: Ciudad[] = [];
  idCiudad:string="";
  @Output() guardarTarifaEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarTarifaListEvento = new EventEmitter();
  tipoDeServicios: TipoDeServicios[];
  empresas: Empresas[];
  rutas: Ruta[];
  estados: Estados[];
  checked:boolean;

  constructor(private sempresas: SempresasService, private starifas: StarifasService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.checked= this.tarifa.tarificacionPorTiempo==1?true:false;
    this.getEmpresas();
    this.getCiudades();
    this.getRutas();
    this.getEstadosbd();
    this.getTipoDeServicios();
    this.nuevaTarifa= JSON.parse(JSON.stringify( this.tarifa, this.replacer));
    /* this.nuevaTarifa = new Tarifa();
    this.nuevaTarifa.id = this.tarifa.id.toString() ;
    this.nuevaTarifa.idempresa = this.tarifa.idempresa.toString() ;
    this.nuevaTarifa.idruta = this.tarifa.idruta.toString() ;
    this.nuevaTarifa.estado = this.tarifa.estado.toString() ;
    this.nuevaTarifa.fechaAlta = this.tarifa.fechaAlta.toString() ;
    this.nuevaTarifa.fechaBaja = this.tarifa.fechaBaja.toString() ;
    this.nuevaTarifa.tarifa = this.tarifa.tarifa.toString() ;
    this.nuevaTarifa.tarifaConductor = this.tarifa.tarifaConductor.toString() ;
    this.nuevaTarifa.nombre = this.tarifa.nombre.toString() ; */

  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  actualizaTarifa() {
    this.nuevaTarifa.tarificacionPorTiempo = this.checked?1:0;
    this.nuevaTarifa.empresa=null;
    this.nuevaTarifa.ruta=null;
    this.nuevaTarifa= JSON.parse(JSON.stringify( this.nuevaTarifa, this.replacer));
    this.starifas.upTarifa(this.nuevaTarifa).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarTarifaListEvento.emit();
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevaTarifa.idempresa = idEmpresa;
  }
  getRutas() {
    this.starifas.getRutas(null).subscribe(result => {
      this.rutas = result;
    });
  }
  asignoRuta(idRuta: string) {
    this.nuevaTarifa.idruta = idRuta;
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.nuevaTarifa.estado = estado;
    if (estado === 'inactivo') {
      this.nuevaTarifa.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.nuevaTarifa.fechaBaja = '0000-00-00';
      this.nuevaTarifa.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  asignoCiudad(idciudad: string) {
    this.nuevaTarifa.idCiudad = idciudad;
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  asignoTipoDeServicios(idTipoDeServicio: string) {
    this.nuevaTarifa.idTipoDeServicio = idTipoDeServicio;
  }
  TextTipoDeServicio(id:string){

    if(this.tipoDeServicios){
      let a = this.tipoDeServicios.filter(item => item.id == id)
      if (a && a.length > 0) {
        return a[0].nombre;          
      }
    }
  }
}
