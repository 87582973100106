import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-empresa',
  templateUrl: './mant-empresa.component.html',
  styleUrls: ['./mant-empresa.component.css']
})
export class MantEmpresaComponent implements OnInit {
  empresaListVis: boolean;
  empresaVis: boolean;
  @Input() mantEmpresaVis: boolean;
  @Output() cerrarMantEmpresaEvento = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.empresaVis = false;
    this.empresaListVis = false;
  }

}
