import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './servicios/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class LogInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // this.router.navigate(['/']);
    const isLogIn = this.authService.isLoggedIn();
    if ( !isLogIn ) {
      this.router.navigate(['/login']);
    }
    // if(localStorage.getItem('debug')==='1') console.log('canActivate', isLogIn);
    return isLogIn;
  }
}
