import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { PreguntasControl } from '../comun/preguntas-control';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Global } from '../comun/global';

@Component({
  selector: 'app-nueva-contrasenia',
  templateUrl: './nueva-contrasenia.component.html',
  styleUrls: ['./nueva-contrasenia.component.css'],
  providers: [AuthService, ConfirmationService],
  encapsulation: ViewEncapsulation.None

})
export class NuevaContraseniaComponent implements OnInit {
  pregunta: string = "Cargando la pregunta, por favor espere unos segundos";
  contrasenia2: string = "";
  contrasenia1: string = "";
  tkn: string;
  correo: string;
  error:string;
  ok:any;
  vis:any;

  public version: string = environment.VERSION;


  global: Global = new Global();


  constructor(public authService: AuthService, private router: Router, private confirmationService: ConfirmationService) {

    let url = this.router.parseUrl(this.router.url);

    this.tkn = url.queryParams['tkn'];

    let r = JSON.parse(this.tkn);
/*     if(localStorage.getItem('debug')==='1') console.log("r.tkn="+r.tkn);
    if(localStorage.getItem('debug')==='1') console.log("r.correo="+r.correo); */
    this.correo = r.correo;


    this.authService.getUrlTkn(r.correo, r.tkn).subscribe(result => {
      if (!(result && result.length > 0)) this.router.navigate(['/']);
    });
  }

  ngOnInit() {
  }


  Guardar() {

    if (!(this.contrasenia1 === "") && (this.contrasenia1.length > 5) && this.contrasenia1 == this.contrasenia2
      && this.validarContrasenia(this.contrasenia1)) {
      let msg = "Se ha actualizado correctamente su contraseña. Gracias"

      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.authService.setchnpswusr(this.correo, this.contrasenia1).subscribe();
          this.router.navigate(['/']);
        }
      });
    } else {
      let msg = "Rellene correctamente los campos pedidos. "+(this.error?this.error:'');
      this.contrasenia1 = "";
      this.contrasenia2 = "";
      this.error="";
      this.confirmationService.confirm({
        message: msg,
        accept: () => {

        }
      });
    }

  }
  validarContrasenia(texto: string):boolean {
    var numeros = "0123456789";
    var letras = "abcdefghyjklmnñopqrstuvwxyz";
    var letras_mayusculas = "ABCDEFGHYJKLMNÑOPQRSTUVWXYZ";
    var simbolos ="@#~€¬;:.,-_¨^*¿?¡'!·$%&";

    let res = false;
    //NUMEROS
    for (let i = 0; i < texto.length; i++) {
      if (numeros.indexOf(texto.charAt(i), 0) != -1) {
        res = true;
      }
    }
    if (!res) {
      this.error ="Incluya en su contraseña algun número"
      return false;
    }
    res = false;
    // LETRAS
    for (let i = 0; i < texto.length; i++) {
      if (letras.indexOf(texto.charAt(i), 0) != -1) {
        res = true;
      }
    }
    if (!res) {
      this.error ="Incluya en su contraseña alguna letra minuscula"
      return false;
    }
    res = false;
    //MAYUSCULAS
    for (let i = 0; i < texto.length; i++) {
      if (letras_mayusculas.indexOf(texto.charAt(i), 0) != -1) {
        res = true;
      }
    }
    if (!res) {
      this.error ="Incluya en su contraseña alguna letra mayuscula"
      return false;
    }
    res = false;
    //SIMBOLOS
    for (let i = 0; i < texto.length; i++) {
      if (simbolos.indexOf(texto.charAt(i), 0) != -1) {
        res = true;
      }
    }
    if (!res) {
      this.error ="Incluya en su contraseña algún símbolo"
      return false;
    }
    return true;
  }
}
