import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-compania',
  templateUrl: './mant-compania.component.html',
  styleUrls: ['./mant-compania.component.css']
})
export class MantCompaniaComponent implements OnInit {
  @Input() mantCompaniaVis: boolean;
  @Output() cerrarMantCompaniaEvento = new EventEmitter();
  companiaListVis: boolean;
  companiaVis: boolean;

  constructor() { }

  ngOnInit() {
    this.mantCompaniaVis = false;
  }

}
