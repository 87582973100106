import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-ciudad',
  templateUrl: './mant-ciudad.component.html',
  styleUrls: ['./mant-ciudad.component.css']
})
export class MantCiudadComponent implements OnInit {
  @Input() mantCiudadVis: boolean;
  ciudadListVis:any;
  ciudadVis:any;
  @Output() cerrarMantCiudadEvento = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
