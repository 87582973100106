import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SempresasService } from '../../../servicios/sempresas.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../../comun/empresas';
import { Cliente } from '../../../comun/cliente';

@Component({
  selector: 'app-list-cliente',
  templateUrl: './list-cliente.component.html',
  styleUrls: ['./list-cliente.component.css'],
  providers: [SserService, SempresasService]
})
export class ListClienteComponent implements OnInit {
  @Input() serListCliVis: boolean;
  @Output() cerrarSerListCliEvento = new EventEmitter();
  servicios: Servicio[];
  empresas: Empresas[];
  clientes: Cliente[];
  fechaIni: string;
  fechaFin: string;
  message: string[];
  ventanaTarifas: boolean[];
  empresaSel: string;
  clienteSel: string;
  visible: boolean;
  semana: string;
  urgentes: boolean;
  idCiudad:string="";

  constructor(private sser: SserService, private sempresas: SempresasService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.urgentes = false;
    this.semana = '0';
    this.visible = false;
    this.empresaSel = '1';
    this.clienteSel = '1';
    this.serListCliVis = false;
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.actualizar();
    this.ventanaTarifas = new Array();
    this.getEmpresas();
    this.getClientes();

  }
  actualizar() {
    const fech_Ini = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fech_Fin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    
    if (fech_Ini==fech_Fin){
       this.semana='0';
    } else this.semana='1';

    if (this.semana == '0') {
      if (this.empresaSel === '1' && this.clienteSel !== '1') {
        this.sser.getSerFech(fech_Ini, this.clienteSel, null, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else if (this.empresaSel !== '1' && this.clienteSel !== '1') {
        this.sser.getSerFech(fech_Ini, this.clienteSel, null, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else if (this.empresaSel !== '1' && this.clienteSel === '1') {
        this.sser.getSerFech(fech_Ini, null, this.empresaSel, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    } else if (this.semana == '1') {
      if (this.empresaSel === '1' && this.clienteSel !== '1') {
        this.sser.getSerFech(fech_Ini, this.clienteSel, null, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else if (this.empresaSel !== '1' && this.clienteSel !== '1') {
        this.sser.getSerFech(fech_Ini, this.clienteSel, null, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else if (this.empresaSel !== '1' && this.clienteSel === '1') {
        this.sser.getSerFech(fech_Ini, null, this.empresaSel, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    }

  }
  actualizaUltimoMes(){
    this.semana='1';
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('month').format('DD/MM/YYYY');

  }
  actualizaConFechaSemana() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').format('DD/MM/YYYY');
  }
  actualizaConFechaProxSem() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaSemAnt() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaHoy() {
    this.semana = '0';
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaConFechaAyer() {
    this.semana = '0';
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaManana() {
    this.semana = '0';
    this.fechaIni = moment().add(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaPasado() {
    this.semana = '0';
    this.fechaIni = moment().add(2, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(2, 'days').format('DD/MM/YYYY');
  }
  verUrgentes() {
    this.urgentes = true;
  }
  actualizarLista() {
    this.ngOnInit();
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      this.empresas.unshift({
        nombre: 'todos', id: '1', cif: '', direccion: '', telefono: '', contactop: ''
        , mailp: '', visible: false, estado: '', fechaAlta: '', fechaBaja: ''
      });
    });
  }
  getClientes() {
    this.sempresas.getClientes().subscribe(result => {
      this.clientes = result;
      this.clientes.unshift({
        nombre: 'todos', id: '1', direccion: '', telefono: '', email: ''
        , conductoresLista: '', idempresa: '', idsesion: '', estado: '', fechaAlta: ''
        , fechaBaja: '', visible: false, recibirNot: 1,'esAdmin':'0','referencia':''
      });
    });
  }
  getCliente2empresa(empresaId: string) {
    this.sempresas.getCliente2empresa(empresaId).subscribe(result => {
      this.clientes = result;
      this.clientes.unshift({
        nombre: 'todos', id: '1', direccion: '', telefono: '', email: ''
        , conductoresLista: '', idempresa: '', idsesion: '', estado: '', fechaAlta: '', fechaBaja: '', visible: false, recibirNot: 1
        ,'esAdmin':'0','referencia':''
      });
    });
  }
  actualizaListaClientes(idEmpresa: any) {
    this.empresaSel = idEmpresa;
    //if(localStorage.getItem('debug')==='1') console.log('this.empresaSel---->' + this.empresaSel);
    if (idEmpresa === '1') {
      this.getClientes();
    } else {
      this.getCliente2empresa(idEmpresa);
    }
  }
  actualizaListaServicios(idCliente: any) {
    this.clienteSel = idCliente;
    //if(localStorage.getItem('debug')==='1') console.log('this.clienteSel---->' + this.clienteSel);
  }
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  actualizarConEspera() {
    this.wait(1000);
    this.actualizar();
  }
}
