import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-preferencias',
  templateUrl: './mant-preferencias.component.html',
  styleUrls: ['./mant-preferencias.component.css']
})
export class MantPreferenciasComponent implements OnInit {
  @Input() mantPreferenciasVis: boolean;
  @Output() cerrarMantPreferenciasEvento = new EventEmitter();
  preferenciaListVis: boolean;
  preferenciaVis: boolean; 


  constructor() { }

  ngOnInit() {
    this.mantPreferenciasVis = false;
    this.preferenciaVis = false;
    this.preferenciaListVis = false;
  }

}
