import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { Servicio } from '../../../comun/servicio';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { StarifasService } from '../../../servicios/starifas.service';
import {ConfirmationService} from 'primeng/api';
import { ActivatedRoute} from '@angular/router';
import { Estados } from '../../../comun/estados';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService, StarifasService,ConfirmationService]
})
export class EditComponent implements OnInit {
  @Input() idServicio: string;
  @Output() cerrarSerEditEvento = new EventEmitter();
  es: any;
  servicio:Servicio= new Servicio();
  estados: Estados[];


  constructor(private sser:SserService,private starifas: StarifasService,
              private confirmationService: ConfirmationService,private router: ActivatedRoute) { }

  ngOnInit() {
    let datos;
    this.getEstados();
    let id = this.router.snapshot.paramMap.get('idServicio');
    
    if(id && !(id=="")){
      datos={"idservicio":id};
    }else{
       datos={"idservicio":this.idServicio};
    }
    this.sser.getServicio(JSON.stringify(datos)).subscribe(result=>{
        //console.log(JSON.stringify(result));
        if(result) this.servicio = result[0];
        this.servicio.tiempoInicioRuta = moment(this.servicio.tiempoInicioRuta , ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('DD/MM/YYYY HH:mm');
        this.servicio.tiempoFinRuta = moment(this.servicio.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('DD/MM/YYYY HH:mm');
      });
      this.es = {
        firstDayOfWeek: 1,
        dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
        dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
        dayNamesMin: [ "D","L","M","X","J","V","S" ],
        monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
        monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
        today: 'Hoy',
        clear: 'Borrar'
    }
  }
  getEstados() {
    this.starifas.getEstados().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(value:string){
    console.log("this.servicio.estado="+this.servicio.estado);
    this.servicio.estado=value;
  }
  save(){
    this.confirmationService.confirm({
      message: 'Va a modificar el servicio ¿Esta seguro?',
      accept: () => {
          if (this.servicio.tiempoDeEspera && +this.servicio.tiempoDeEspera>0) this.servicio.parada='1';
          else this.servicio.parada='0'

          this.servicio.tiempoInicioRuta = moment(this.servicio.tiempoInicioRuta , ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('YYYY-MM-DD HH:mm:ss');
          this.servicio.tiempoFinRuta = moment(this.servicio.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('YYYY-MM-DD HH:mm:ss');
          this.servicio = JSON.parse(JSON.stringify( this.servicio, this.replacer));

          this.sser.setServicio(this.servicio).subscribe(result=>{
              //console.log(JSON.stringify(result));
              let id = this.router.snapshot.paramMap.get('idServicio');
              if(id && !(id=="")){
                window.close();  //No funciona
                self.close(); 
              }else{
                this.idServicio='';
                this.cerrarSerEditEvento.emit();
              }
            
          });
        }
      });
  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
}
