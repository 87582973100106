import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-preferencias-list',
  templateUrl: './crud-preferencias-list.component.html',
  styleUrls: ['./crud-preferencias-list.component.css'],
  providers: [SempresasService]
})
export class CrudPreferenciasListComponent implements OnInit {
  @Input() preferenciaListVis: boolean;
  @Input() detallePreferenciavisible: boolean;
  @Output() cerrarPreferenciaListEvento = new EventEmitter();

  preferencias: Preferencia[];

  message: string[];
  msg: string;

  constructor(private sempresas: SempresasService) {
    this.message = [];
  }

  ngOnInit() {
    this.preferenciaListVis = false;
    this.detallePreferenciavisible = false;
    this.getPreferencias();
  }
  getPreferencias() {
    this.sempresas.getPreferencias().subscribe(result => {
      this.preferencias = result;
    });
  }
  borraPreferencia(id: string) {

    this.sempresas.borraPreferencia(id).subscribe(result => {
      this.msg = result.message;
    });
    // console.log('------>>>>>>>>' + this.msg);
    if (this.msg === 'OK' ) {
      this.message[id] = '¡¡Atencion, ha inactivado la preferencia!!';
    }else if (this.msg === 'NOK' ) {
      this.message[id] = '¡¡Atencion, se ha producido un error!!';
    }else {
      this.message[id] = this.msg;
    }
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 1500);
    this.msg = '';
    this.actualizarLista();
  }

  actualizarLista() {
    //console.log('------>>>>>>>>');
    this.ngOnInit();
  }
}
