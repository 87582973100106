import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { Compania } from '../../../comun/compania';
import { Conductor } from '../../../comun/conductor';
import { CompaniaPeticion } from '../../../comun/compania-peticion';
import { Acontecimiento } from '../../../comun/acontecimiento';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';
import { ColorPickerModule } from 'primeng/colorpicker';
import { Vehiculo } from '../../../comun/vehiculo';
import { SserService } from '../../../servicios/sser.service';
import { Ciudad } from '../../../comun/ciudad';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-crud-conductor',
  templateUrl: './crud-conductor.component.html',
  styleUrls: ['./crud-conductor.component.css'],
  providers: [SconductoresService,SserService,StarifasService]
})
export class CrudConductorComponent implements OnInit {

  @Input() conductorVis: boolean;
  @Output() cerrarConductorEvento = new EventEmitter();
  ok:any;

  conductorNuevo: Conductor = new Conductor();
  companias: Compania[];
  tipoDeServicios: TipoDeServicios[];
  vehiculos: Vehiculo[];
  imgCond:string = 'assets/img/nohayfoto.jpg';
  uploadedFiles: any[] = [];
  fileToUpload: File = null;
  global:Global = new Global();
  ciudades: Ciudad[] = [];


  constructor(private sser: SserService, private staf: StarifasService, private sconductores: SconductoresService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getCiudades();
    this.conductorVis = false;
  }
  getCompanias(idCiudad:string) {

    let config = JSON.stringify({ 'estado': 'activo'});
    if(idCiudad!=null) config = JSON.stringify({ 'estado': 'activo', 'idCiudad': idCiudad });
    
    this.sconductores.getCompaniaS(config).subscribe(result => {
      this.companias = result;
      if(result && result[0]){ 
        this.conductorNuevo.idCompania=result[0].id;
        this.getVehiculos(result[0].id);
      }
    });
  }
  asignoCiudad(idciudad: string) {
    this.conductorNuevo.idCiudad = idciudad;
    //recarga compañias
    this.getCompanias(idciudad);
    this.getVehiculos(null);
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
    //this.getCompanias(null);
    //this.getVehiculos(null);
  }
  asignoCompania(idCompania: string) {
    this.conductorNuevo.idCompania = idCompania;
    this.getVehiculos(idCompania);
  }

  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  new() {
    if (this.conductorNuevo.idCiudad && this.conductorNuevo.idCiudad !== '' &&
        this.conductorNuevo.idCompania && this.conductorNuevo.idCompania !== '' &&
        this.conductorNuevo.idVehiculo && this.conductorNuevo.idVehiculo !== '' &&
        this.conductorNuevo.nombre && this.conductorNuevo.nombre !== '' &&
        this.conductorNuevo.permisoConducir && this.conductorNuevo.permisoConducir !== '' &&
        this.conductorNuevo.telefono && this.conductorNuevo.telefono !== '' &&
        this.conductorNuevo.email && this.conductorNuevo.email !== '' &&
        this.conductorNuevo.dni && this.conductorNuevo.dni !== '' &&
        this.conductorNuevo.tarifaMinima && this.conductorNuevo.tarifaMinima !== ''){
      this.conductorNuevo.visible = false;
      this.conductorNuevo.estado = 'activo';
      this.conductorNuevo.fechaAlta = moment().format('YYYY-MM-DD');
      this.conductorNuevo.fechaBaja = '0000-00-00';
      if(this.fileToUpload){
        let e = this.conductorNuevo.email;
        var re = /\@/g;
        e = e.replace(re, '_');
        this.conductorNuevo.urlFoto = this.global.SERVIDOR_URL+this.global.DIRBASE_IMG_COND+e+'/'+this.fileToUpload.name;
        this.postFile();
      }
      this.conductorNuevo = JSON.parse(JSON.stringify(this.conductorNuevo, this.replacer));
    
      this.sconductores.setConductor(JSON.stringify(this.conductorNuevo)).subscribe();
      this.cerrarConductorEvento.emit();
    }else{
      this.confirmationService.confirm({
        message: 'Debe rellenar los datos pedidos, gracias.',
        header: '¡¡¡ Atención !!!',
        icon: 'fa fa-question-circle',
        accept: () => {
        },
        reject: () => {
        }
      });
    }
  }
  actualizaVehiculo(idVehiculo: string) {
    this.conductorNuevo.idVehiculo = idVehiculo;
    for (let veh of this.vehiculos) {
      if (idVehiculo == veh.id) {
        //this.conductorNuevo.idTipoDeServicio = veh.idTipoDeServicio;
        break;
      }
    }

  }
  getVehiculos(id) {
    if(id==null){
      this.vehiculos = [];
    }else{
      if (id === '0') {
        this.sconductores.getVehiculos(null).subscribe(result => {
          this.vehiculos = result;
          for (let veh of this.vehiculos) {
            this.conductorNuevo.idVehiculo = veh.id;
            //this.conductorNuevo.idTipoDeServicio = veh.idTipoDeServicio;
            break;
          }
        });
      } else {
        const vehiculo = { 'idCompania': id };
        this.sconductores.getVehiculos(JSON.stringify(vehiculo)).subscribe(result => {
          this.vehiculos = result;
          for (let veh of this.vehiculos) {
            this.conductorNuevo.idVehiculo = veh.id;
            //this.conductorNuevo.idTipoDeServicio = veh.idTipoDeServicio;
            break;
          }
        });
      }
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getImage(){
    if(this.fileToUpload){
      this.imgCond = this.fileToUpload.name;
    }else this.imgCond = 'assets/img/nohayfoto.jpg';
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  postFile() {
    if(this.fileToUpload){

      let e = this.conductorNuevo.email;
      var re = /\@/g;
      e = e.replace(re, '_');

      this.sser.envioFoto(this.fileToUpload,e+'/').subscribe(
        res => {
          console.log('res='+JSON.stringify(res));
        });
      }
  }
}
