import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SfactService } from '../../../servicios/sfact.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../../comun/empresas';
import { Propietario } from '../../../comun/propietario';
import { Global } from '../../../comun/global';

@Component({
  selector: 'app-list-emp-fac',
  templateUrl: './list-emp-fac.component.html',
  styleUrls: ['./list-emp-fac.component.css'],
  providers: [SserService, SempresasService, SfactService]
})
export class ListEmpFacComponent implements OnInit {

  @Input() fechaIni: string;
  @Input() fechaFin: string;
  msgs: any;
  sfechaIni: string;
  sfechaFin: string;
  empresaFac:Empresas;
  empresas: Empresas[]=[];
  propietario: Propietario;
  detVis=false;
  idCiudad:string="";
  @Output() cerrarlefVisEvento = new EventEmitter();

  constructor(private sser: SserService, private sempresas: SempresasService, private sfact: SfactService) { }


  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.sfechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.sfechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');

    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.getPropietario();
    this.buscaEmpresasAfacturar();

  }
  buscaEmpresasAfacturar() {
    const ser = { 'fechaIni': this.fechaIni, 'fechaFin': this.fechaFin,'idCiudad':this.idCiudad };
    this.sfact.getEmpresasAfacturar(JSON.stringify(ser)).subscribe(result=>{
      this.empresas=result;
    });
    
  }
  getPropietario () {
    let  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         break;
      }
    });
  }
  facturar(id:string){
    if(this.detVis){
      this.detVis=false;
    }else{
      for( let e of this.empresas){
        if(e.id == id ){
          this.empresaFac = e;
          this.detVis=true;
          break;
        }
      }
    }
  }
}
