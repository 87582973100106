import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ser-list',
  templateUrl: './ser-list.component.html',
  styleUrls: ['./ser-list.component.css']
})
export class SerListComponent implements OnInit {
  @Input() serListVis: boolean;
  @Output() cerrarSerListEvento = new EventEmitter();
  serListFechVis: boolean;
  SerListComponent: boolean;
  serListCliVis: boolean;
  serListConVis: boolean;
  serListEstVis: boolean;
  serListSTVis:boolean;
  serListPerVis:any;
  

  constructor() { }

  ngOnInit() {
    this.serListVis = false;
  }

}
