import { Component, OnInit ,ViewEncapsulation } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ser',
  templateUrl: './ser.component.html',
  styleUrls: ['./ser.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SerComponent implements OnInit {
  @Output() cerrarSerEvento = new EventEmitter();
  constructor() { }
  idServicio:any;
  serEdit:any;
  serUrg:any;
  ngOnInit() {
  }
  cerrar(){
    this.cerrarSerEvento.emit();
  }

}
