import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { SstService } from '../../../../servicios/sst.service';
import { Compania } from '../../../../comun/compania';
import { Vehiculo } from '../../../../comun/vehiculo';
import { CompaniaPeticion } from '../../../../comun/compania-peticion';
import { Acontecimiento } from '../../../../comun/acontecimiento';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';
import { Categoria } from '../../../../comun/categoria';

@Component({
  selector: 'app-detalle-vehiculo',
  templateUrl: './detalle-vehiculo.component.html',
  styleUrls: ['./detalle-vehiculo.component.css'],
  providers: [SempresasService, SconductoresService, SstService]
})
export class DetalleVehiculoComponent implements OnInit {

  @Input() vehiculo: Vehiculo = new Vehiculo();
  detalleConductorvisible: boolean;

  @Input() categorias: Categoria[];
  @Input() categoriaIds: string;

  @Output() guardarVehiculoEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarVehiculoListEvento = new EventEmitter();
  dealleVis: boolean;

  color: string;
  vehiculoNuevo: Vehiculo = new Vehiculo();
  idCompania: string;

  companias: Compania[];
  estados: Estados[];

  selectedCategorias: Categoria[] = [];
  notSelectedCategorias: Categoria[] = [];

  constructor(private sempresas: SempresasService, private sconductores: SconductoresService, private sst: SstService) { }

  ngOnInit() {
    this.vehiculoNuevo = JSON.parse(JSON.stringify(this.vehiculo, this.replacer));
    this.idCompania = this.vehiculoNuevo.idCompania;
    this.getCatNotSel(this.categoriaIds);
    this.getCatSel(this.categoriaIds);
    this.getCompanias();
    this.getEstadosbd();
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }


  actualizaVehiculo() {
    if (this.selectedCategorias.length > 0) {

      let config = JSON.stringify({ 'tabla': 'veh2cat', 'conEstado': '0' });
      let datos = JSON.stringify({ 'nombre': 'idveh', 'campo': this.vehiculoNuevo.id });

      this.sst.borra(config, datos).subscribe(result => {
        for (let f of this.selectedCategorias) {
          datos = JSON.stringify({'idcat': f.id, 'idveh': this.vehiculoNuevo.id});
          this.sst.actualiza(config, datos).subscribe();
        }
      });


      this.sconductores.upVehiculo(JSON.stringify(this.vehiculoNuevo)).subscribe();
      this.cerrarDetalleVisEvento.emit();
      this.actualizarVehiculoListEvento.emit();
    }
  }





  getCompanias() {
    const companiaPeticion: CompaniaPeticion = new CompaniaPeticion();

    companiaPeticion.estado = 'activo';
    this.sconductores.getCompanias(companiaPeticion).subscribe(result => {
      this.companias = result;
    });
  }




  asignoTipoDeServicios(idTipoDeServicio: string) {
    this.vehiculoNuevo.idTipoDeServicio = idTipoDeServicio;
  }
  asignoColor(color: string) {
    this.vehiculoNuevo.color = color;
  }
  asignoCompania(idCompania: string) {
    this.vehiculoNuevo.idCompania = idCompania;
  }
  asignoEstado(estado: string) {
    this.vehiculoNuevo.estado = estado;
    if (estado === 'inactivo') {
      this.vehiculoNuevo.fechaBaja = moment().format('YYYY-MM-DD');
    } else {
      this.vehiculoNuevo.fechaBaja = '0000-00-00';
      this.vehiculoNuevo.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  getCatSel(categoriaIds: string) {
    //if(localStorage.getItem('debug')==='1') console.log('::::::::::::::::::::::::>>' + categoriaIds);
    this.selectedCategorias = [];
    let ids = [];
    if (categoriaIds) {
      if (categoriaIds.indexOf('-') == -1) {
        ids.push(categoriaIds);
      } else {
        ids = categoriaIds.split('-');
      }
      for (let id of ids) {
        for (let tds of this.categorias) {
          if (tds.id == id) {
            this.selectedCategorias.push(tds);
          }
        }
      }
    }
  }
  getCatNotSel(categoriaIds: any) {
    this.notSelectedCategorias = this.categorias;
    //if(localStorage.getItem('debug')==='1') console.log('::::::::::::::::::::::::>>'+JSON.stringify(this.notSelectedTipoDeServicio));
    let ids = [];
    if (categoriaIds) {
      if (categoriaIds.indexOf('-') == -1) {
        ids.push(categoriaIds);
      } else {
        ids = categoriaIds.split('-');
      }
      for (let id of ids) {
        for (let tds of this.categorias) {
          if (tds.id == id) {
            this.notSelectedCategorias = this.notSelectedCategorias.filter(item => item !== tds);
          }
        }
      }
    }
  }
}
