import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-conductor',
  templateUrl: './mant-conductor.component.html',
  styleUrls: ['./mant-conductor.component.css']
})
export class MantConductorComponent implements OnInit {
  @Input() mantConductoresVis: boolean;
  @Output() cerrarMantConductoresEvento = new EventEmitter();
  conductorListVis: boolean;
  conductorVis: boolean;

  constructor() { }

  ngOnInit() {
    this.mantConductoresVis = false;
    this.conductorVis = false;
    this.conductorListVis = false;
  }

}
