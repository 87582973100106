import { Component } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.css']
})
export class ListaUsuariosComponent implements OnInit {
  @Input() usuarioListVis: boolean;
  @Output() cerrarUsuarioListEvento = new EventEmitter();
  listaUsuariosConductoresVis: boolean;
  listaUsuariosClientesVis: boolean;
  listaUsuariosAdminVis: boolean;
  listaUsuariosGestorVis:any;
  listaUsuariosComercialVis:any;
  constructor() { }

  ngOnInit() {
  }

}
