import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Cargas } from '../../../../comun/cargas';
import { Cliente } from '../../../../comun/cliente';
//import { ConductorPeticion } from '../../../../comun/conductor-peticion';
import { StarifasService } from '../../../../servicios/starifas.service';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { SserService } from '../../../../servicios/sser.service';

@Component({
  selector: 'app-detalle-cargas-list',
  templateUrl: './detalle-cargas-list.component.html',
  styleUrls: ['./detalle-cargas-list.component.css'],
  providers: [SempresasService, SserService,StarifasService,SconductoresService]
})
export class DetalleCargasListComponent implements OnInit {

  @Input() carga: Cargas;
  detalleClientevisible: boolean;
  cargaNueva: Cargas;
  @Output() guardarClienteEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarClienteListEvento = new EventEmitter();
  dealleVis: boolean;
  empresas: Empresas[];
  estados: Estados[];
  clientes: Cliente[];
  selectedValues = [];
  tipoDeServicios: TipoDeServicios[];

  constructor(private sempresas: SempresasService, private sser: SserService,private starifas: StarifasService,private sconductores: SconductoresService) { }

  ngOnInit() {
    this.cargaNueva = JSON.parse(JSON.stringify( this.carga, this.replacer));

    this.getEmpresas();
    this.getClientes(null);
    this.getEstados();
    this.getTipoDeServicios();

  }
  replacer(key,value){
    if (value === null) {
      return undefined;} 
    else{
      return value;
    }
  }
  actualizaCarga() {
      this.carga = JSON.parse(JSON.stringify( this.cargaNueva, this.replacer));
      this.sser.updateCarga(JSON.stringify(this.cargaNueva)).subscribe();
      this.cerrarDetalleVisEvento.emit();
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  asignoTipoDeServicios(idTipoDeServicio: string) {
    this.cargaNueva.idTipoDeServicio = idTipoDeServicio;
    for(const cli of this.tipoDeServicios){
      if(cli.id==idTipoDeServicio){
        this.cargaNueva.tipoDeServicio=cli.nombre;
        break;
      }
    }
  }
  getEmpresas() {
    this.sempresas.getEmpresas(null).subscribe(result => {
      this.empresas = result;
    });
  }
  getClientes(idempresa: string) {
    if(idempresa){
      this.sempresas.getClientesEmpresa(idempresa).subscribe(result => {
        this.clientes = result;
        if(result && result.length>0){

          this.clientes = result;
          this.carga.idCliente=this.clientes[0].id;
        }else{
          this.clientes = [];
        }
      });
    }else{
      this.sempresas.getClientes().subscribe(result => {
        if(result && result.length>0){
          this.clientes = result;
          this.carga.idCliente=this.clientes[0].id;
        }else{
          this.clientes = [];
        }
      });
    }
  }
  getEstados() {
    this.starifas.getEstados().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.carga.idEmpresa = idEmpresa;
    for(const emp of this.empresas){
      if(emp.id==idEmpresa){
        this.cargaNueva.empresa=emp.nombre;
        break;
      }
    }
    this.getClientes(idEmpresa);
  }
  asignoEstado(estado: string) {
    this.carga.estado = estado;
  }
  asignoCliente(idCliente: string) {
    this.carga.idCliente = idCliente;
    for(const cli of this.clientes){
      if(cli.id==idCliente){
        this.cargaNueva.cliente=cli.nombre;
        break;
      }
    }
  }
}
