import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthService, MessageService],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  vis: boolean = false;
  msgs: Message[] = [];
  password: string;
  username: string;
  public version: string = environment.VERSION;

  constructor(public authService: AuthService, private router: Router, private messageService: MessageService) {

  }


  ngOnInit() {
  }

  login(): boolean {

    this.authService.login(this.username, this.password).subscribe(
      data => {
        if (data !== 'NOK') {
          this.router.navigate(['/']);
        } else {
          this.showError();
        }
      });
    return false;
  }
  logout(): boolean {
    this.authService.logout();
    return true;
  }
  showError() {
    this.vis = true;
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'La contraseña que ingresaste es incorrecta o el usuario no existe.', detail: 'Autenticación' });
    setTimeout(() => this.resolve(), 2000)
  }
  resolve() {
    this.vis = false;
    this.msgs = [];
  }
  ccontrasenia(){
    this.router.navigate(['recordarContasenia']);
  }
}
