import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Estados } from '../../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../../servicios/sser.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import {HttpModule} from '@angular/http';
import { Empresas } from '../../../../comun/empresas';
import { Cliente } from '../../../../comun/cliente';
import { Global } from '../../../../comun/global';
import {DataViewModule} from 'primeng/dataview';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-list-ser-tar',
  templateUrl: './list-ser-tar.component.html',
  styleUrls: ['./list-ser-tar.component.css'],
  providers: [SserService,ConfirmationService]
})
export class ListSerTarComponent implements OnInit {
  @Input() servicios: Servicio[];
  @Output() cerrarListSerFechEvento = new EventEmitter();
  displayDialog: boolean;
  selectedSer: Servicio;
  sortOptions: any[];
  sortField: string;
  sortOrder: number;
  showEdit: boolean;
  sortKey:any;
  global:Global= new Global();
  constructor(private sser:SserService,private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.showEdit = false;
    this.sortOptions = [
      { label: 'fecha mas reciente', value: '!FechaDeRecogida' },
      { label: 'fecha mas antiguo', value: 'FechaDeRecogida' },
      { label: 'Estado', value: 'estado' }
    ];
  }

  selectSer(event: Event, ser: Servicio) {
    this.selectedSer = ser;
    this.displayDialog = true;
    event.preventDefault();
  }
  onDialogHide() {
    this.selectedSer = null;
  }
  onSortChange(event) {
    let value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  fech2time(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('YYYY-MM-DD HH:mm:ss');
  }
  actualizar(){
    this.cerrarListSerFechEvento.emit();
  }
  borraAlert(servicio:Servicio){
    this.confirmationService.confirm({
      message: 'Va a Borrar el servicio ¿Esta seguro?',
      accept: () => {
          let ser = {"idservicio":servicio.idservicio};
         this.sser.borraServicio(JSON.stringify(ser)).subscribe(result=>{
            if(localStorage.getItem('debug')==='1') console.log('Borrar servicio :::::>>>>>>'+JSON.stringify(result));
            this.actualizar();
         });
      }
  });
  
}
replacer(key,value){
  if (value === null) {
    return undefined;} 
  else{
    return value;
  }
}
finalizarEstado(servicio:Servicio){
  servicio.estado= this.global.ESTADO_FINALIZADO;
  servicio = JSON.parse(JSON.stringify( servicio, this.replacer));
  this.sser.setServicio(servicio).subscribe();
  //if(localStorage.getItem('debug')==='1') console.log("________>>>>"+JSON.stringify( servicio));
}

}
