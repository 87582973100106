import { Component, OnInit } from '@angular/core';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { SserService } from '../../../servicios/sser.service';
import { Compania } from '../../../comun/compania';
import { CompaniaPeticion } from '../../../comun/compania-peticion';
import { Coordinador } from '../../../comun/coordinador';
import * as moment from 'moment';
import { Input, Output, EventEmitter } from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-crud-coordinador',
  templateUrl: './crud-coordinador.component.html',
  styleUrls: ['./crud-coordinador.component.css'],
  providers: [SconductoresService,SserService,ConfirmationService]
})
export class CrudCoordinadorComponent implements OnInit {

  coordinadorNuevo:Coordinador;
  companias: Compania[];
  @Output() cerrarCoordinadorEvento = new EventEmitter();

  constructor(private sser: SserService,private sconductores: SconductoresService,private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.coordinadorNuevo= new Coordinador();
    this.getCompanias();
  }
  getCompanias() {
    const companiaPeticion: CompaniaPeticion = new CompaniaPeticion();
    companiaPeticion.estado = 'activo';
    this.sconductores.getCompanias(companiaPeticion).subscribe(result => {
      this.companias = result;
    });
  }
  asignoCompania(idCompania: string) {
    this.coordinadorNuevo.idCompania = idCompania;
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  new() {
    this.coordinadorNuevo.visible = false;
    this.coordinadorNuevo.estado = 'activo';
    this.coordinadorNuevo.fechaAlta = moment().format('YYYY-MM-DD');
    this.coordinadorNuevo.fechaBaja = '0000-00-00';
    this.coordinadorNuevo.contrasenia = Md5.hashStr(this.coordinadorNuevo.toString()).toString();
    this.coordinadorNuevo = JSON.parse(JSON.stringify(this.coordinadorNuevo, this.replacer));
   
    this.sconductores.setCoordinador(JSON.stringify(this.coordinadorNuevo)).subscribe(result=>{

      if(result){
        if(result.id) this.cerrarCoordinadorEvento.emit();
        else{
          this.confirmationService.confirm({ message:result.error});
        }
      }
    });
    
  }

}
