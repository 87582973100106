import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../../servicios/sser.service';
import { SconductoresService } from '../../../servicios/sconductores.service';

@Component({
  selector: 'app-list-conductor',
  templateUrl: './list-conductor.component.html',
  styleUrls: ['./list-conductor.component.css'],
  providers: [SserService, SconductoresService]
})
export class ListConductorComponent implements OnInit {
  @Input() serListConVis: boolean;
  @Output() cerrarSerListConEvento = new EventEmitter();
  servicios: Servicio[];
  fecha: string;
  fechaIni: string;
  fechaFin: string;
  message: string[];
  ventanaTarifas: boolean[];
  conductores: Conductor[];
  conductorSel: string;
  visible: boolean;
  semana: string;
  urgentes: boolean;
  idCiudad:string = "";

  constructor(private sser: SserService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.urgentes = false;
    this.visible = false;
    this.semana = '0';
    this.conductorSel = '1';
    this.serListConVis = false;
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.actualizar();
    this.ventanaTarifas = new Array();
    this.getConductores();


  }
  actualizar() {

    const fech_Ini = moment(this.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fech_Fin = moment(this.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (fech_Ini==fech_Fin){
      this.semana='0';
   } else this.semana='1';

    if (this.semana == '0') {
      if (this.conductorSel !== '1') {
        this.sser.getSerFech(fech_Ini, null, null, this.conductorSel, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, null, null,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    } else if (this.semana == '1') {
      if (this.conductorSel !== '1') {
        this.sser.getSerFech(fech_Ini, null, null, this.conductorSel, null,fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      } else {
        this.sser.getSerFech(fech_Ini, null, null, null, null, fech_Fin,this.idCiudad).subscribe(result => {
          this.servicios = result;
        });
      }
    }
  }
  actualizaUltimoMes(){
    this.semana='1';
    this.fechaIni = moment().startOf('month').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('month').format('DD/MM/YYYY');

  }
  actualizaConFechaSemana() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').format('DD/MM/YYYY');
  }
  actualizaConFechaProxSem() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').add(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaSemAnt() {
    this.semana = '1';
    this.fechaIni = moment().startOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
    this.fechaFin = moment().endOf('isoWeek').subtract(1, 'week').format('DD/MM/YYYY');
  }
  actualizaConFechaHoy() {
    this.semana = '0';
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizaConFechaAyer() {
    this.semana = '0';
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaManana() {
    this.semana = '0';
    this.fechaIni = moment().add(1, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(1, 'days').format('DD/MM/YYYY');
  }
  actualizaConFechaPasado() {
    this.semana = '0';
    this.fechaIni = moment().add(2, 'days').format('DD/MM/YYYY');
    this.fechaFin = moment().add(2, 'days').format('DD/MM/YYYY');
  }
  asignarTarifa() {

  }
  asignarConductor(idConductor: string) {
    //if(localStorage.getItem('debug')==='1') console.log('this.conductorSel---->' + idConductor);
    this.conductorSel = idConductor;

  }
  actualizarLista() {
    this.ngOnInit();
  }
  getConductores() {
    this.sconductores.getConductores(null).subscribe(result => {
      this.conductores = result;
      this.conductores.unshift({
        nombre: 'todos', id: '1', /* color: '', */ tipoDeServicio: '', idsesion: '', telefono: '',
        email: '', estado: '', tarifaMinima: '', idCompania: '', fechaAlta: '', fechaBaja: '', color: "",
        dni: '',/*  vehiculo: '', matricula: '', idTipoDeServicio: '', */
        compania: '', permisoConducir: '', visible: false, idVehiculo: '',urlFoto:'',idCiudad:''
      });

    });
  }
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  actualizarConEspera() {
    this.wait(1000);
    this.actualizar();
  }

}