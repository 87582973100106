import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Empresas } from '../../../../comun/empresas';
import { Acontecimiento } from '../../../../comun/acontecimiento';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Horarios } from '../../../../comun/horarios';
import { HorarioPeticion } from '../../../../comun/horario-peticion';

@Component({
  selector: 'app-detalle-horarios',
  templateUrl: './detalle-horarios.component.html',
  styleUrls: ['./detalle-horarios.component.css']
})
export class DetalleHorariosComponent implements OnInit {

  @Input() horario: Horarios;
  detalleHorariovisible: boolean;
  horarioNuevo: Horarios;
  @Output() guardarHorarioEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarHorarioListEvento = new EventEmitter();
  dealleVis: boolean;
  conductores: Conductor[];
  acontecimientos: Acontecimiento[];
  message: string;

  constructor(private sempresas: SempresasService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.horarioNuevo = new Horarios();
    Object.assign(this.horarioNuevo, this.horario);
    this.horarioNuevo.fechaIni = moment(this.horarioNuevo.fechaIni, 'YYYY-MM-DD').format('DD/MM/YYYY');
    this.horarioNuevo.fechaFin = moment(this.horarioNuevo.fechaFin, 'YYYY-MM-DD').format('DD/MM/YYYY');
    this.horarioNuevo.horaIni = moment(this.horarioNuevo.horaIni, 'HH:mm').format('HH:mm');
    this.horarioNuevo.horaFin = moment(this.horarioNuevo.horaFin, 'HH:mm').format('HH:mm');
    this.getConductores();
    this.getEventualidad();
  }
  actualizaHorario() {
    const horarioRes = new HorarioPeticion();
    // Object.assign(horarioRes, this.horario);
    horarioRes.descripcion = this.horarioNuevo.descripcion;
    horarioRes.id = this.horarioNuevo.id;
    horarioRes.idConductor = this.horarioNuevo.idConductor;
    horarioRes.idEventualidad = this.horarioNuevo.idEventualidad;
    //if(localStorage.getItem('debug')==='1') console.log(' this idEventualidad...........' + this.horarioNuevo.idEventualidad + '......' + horarioRes.idEventualidad);
    horarioRes.visible = false;
    horarioRes.fechaIni = moment(this.horarioNuevo.fechaIni, 'DD/MM/YYYY').format('YYYY-MM-DD');
    horarioRes.fechaFin = moment(this.horarioNuevo.fechaFin, 'DD/MM/YYYY').format('YYYY-MM-DD');
    horarioRes.horaIni = moment(this.horarioNuevo.horaIni, 'HH:mm').format('HH:mm');
    horarioRes.horaFin = moment(this.horarioNuevo.horaFin, 'HH:mm').format('HH:mm');

    this.sempresas.updateHorario(horarioRes).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarHorarioListEvento.emit();
  }
  getConductores() {
    this.sconductores.getConductores(null).subscribe(result => {
      this.conductores = result;
    });
  }

  getEventualidad() {
    this.sempresas.getAcontecimientos().subscribe(result => {
      this.acontecimientos = result;
    });
  }
  asignoConductor(idConductor: string) {
    this.horarioNuevo.idConductor = idConductor;
  }

  asignoEventualidad(idEventualidad: string) {
    this.horarioNuevo.idEventualidad = idEventualidad;
  }
}
