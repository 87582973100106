import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-act-urg',
  templateUrl: './act-urg.component.html',
  styleUrls: ['./act-urg.component.css']
})
export class ActUrgComponent implements OnInit {
  @Input() actUrg: boolean;
  @Output() urgentesEvento= new EventEmitter();
  @Output() activarUrgenteEvento = new EventEmitter();
  @Output() desactivarUrgenteEvento = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
