import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { SstService } from '../../../servicios/sst.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Categoria } from '../../../comun/categoria';

@Component({
  selector: 'app-crud-tipodeservicio',
  templateUrl: './crud-tipodeservicio.component.html',
  styleUrls: ['./crud-tipodeservicio.component.css'],
  providers: [SconductoresService,SstService]
})
export class CrudTipodeservicioComponent implements OnInit {

  @Input() tipodeservicioVis: boolean;
  @Output() cerrarTipodeservicioEvento = new EventEmitter();

  tipodeservicioNuevo: TipoDeServicios = new TipoDeServicios();
  categorias: Categoria[] = [];
  selectedCategorias: Categoria[] = [];


  constructor(private sconductores: SconductoresService, private sst: SstService) { }

  ngOnInit() {
    this.tipodeservicioVis = false;
    this.getCategorias();
  }
  new() {


    if (this.selectedCategorias.length > 0) { 
      
      this.tipodeservicioNuevo.visible = false;
       this.sconductores.setTipodeservicio(this.tipodeservicioNuevo).subscribe(result=>{
        let id = result.id;
        if(id){
          let config = JSON.stringify({ 'tabla': 'tds2cat', 'conEstado': '0' });
          let datos = JSON.stringify({ 'nombre': 'idtds', 'campo': id });
    
          this.sst.borra(config, datos).subscribe(result => {
            for (let f of this.selectedCategorias) {
              datos = JSON.stringify({ 'idcat': f.id, 'idtds': id });
              this.sst.actualiza(config, datos).subscribe();
            }
          });
        }
      });
      this.cerrarTipodeservicioEvento.emit();
    }
  }

  getCategorias() {
    this.sconductores.getCategorias(null).subscribe(result => {
      this.categorias = result;
    });
  }
}
