export class Ruta {
    id: string;
    tiempoMedioServicio: string;
    tiempoMedioRecogida: string;
    nombre: string;
    descripcion: string;
    estado: string;
    fechaAlta: string;
    fechaBaja: string;
    visible: boolean;
    idCiudad:string;
}
