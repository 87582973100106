import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ScomercialgestorService } from '../../../servicios/scomercialgestor.service';
import { SfactService } from '../../../servicios/sfact.service';
import { Comercial } from '../../../comun/comercial';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-crud-comercial-list',
  templateUrl: './crud-comercial-list.component.html',
  styleUrls: ['./crud-comercial-list.component.css'],
  providers: [ScomercialgestorService, ConfirmationService, SfactService]
})
export class CrudComercialListComponent implements OnInit {

  @Input() clienteListVis: boolean;
  @Input() cerrarComercialListEvento: boolean;
  @Output() actualizarComercialListEvento = new EventEmitter();

  detalleVis: boolean;
  comerciales: Comercial[] = [];
  message: string[];
  selectedValues = [];
  selCategoriasHTML: SafeHtml[] = [];

  constructor(private sser: ScomercialgestorService, private confirmationService: ConfirmationService,
    private sfact: SfactService, private sanitizer: DomSanitizer) {
    this.message = [];
  }

  ngOnInit() {
    this.getComerciales();
  }
  estaActivo(comercial: Comercial) {
    if (comercial.estado == "activo") return true;
    else return false;
  }
  getComerciales() {
    this.sser.getComerciales(null, null).subscribe(result => {
      this.comerciales = result;
      for(let com of this.comerciales){
        this.getEmpresas(com.id);
      }
    });
  }
  getEmpresas(idComercial: string) {
    const comercial = JSON.stringify({ 'comercial': idComercial });
    let str = '';

    this.sfact.getEmpFullXCom(comercial).subscribe(result => {

      for (let com of result) {
        str += "<li><b>empresa:</b>" + com.nombre + "</li>";
      }
      //if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>'+scat);
      this.selCategoriasHTML[idComercial] = this.sanitizer.bypassSecurityTrustHtml(str);
    });
  }

  actualizarLista() {
    this.ngOnInit();
  }

  borraComercial(comercial: Comercial) {
    comercial.estado = "inactivo";
    comercial.fechaBaja = moment().format('YYYY-MM-DD');
    comercial.fechaAlta = '0000-00-00';
    this.sser.upComercial(JSON.stringify(comercial)).subscribe();
  }
  activarComercial(comercial: Comercial) {
    comercial.estado = "activo";
    comercial.fechaAlta = moment().format('YYYY-MM-DD');
    comercial.fechaBaja = '0000-00-00';
    this.sser.upComercial(JSON.stringify(comercial)).subscribe();
  }
  borrarComercial(comercial: Comercial) {
    this.sser.delComercial(JSON.stringify(comercial)).subscribe();
    this.actualizarLista();
  }
}

