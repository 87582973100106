import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Global } from '../comun/global';
import { Conductor } from '../comun/conductor';
import { Estados } from '../comun/Estados';
import { Ciudad } from '../comun/ciudad';
import { Tarifa } from '../comun/tarifa';
import { Ruta } from '../comun/ruta';
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class StarifasService {
  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  global: Global = new Global();

  constructor(private http: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para Estados----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setEstado(estado: Estados): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&estado=') + encodeURIComponent(JSON.stringify(estado)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&estado=') + encodeURIComponent(JSON.stringify(estado)));
  }
  upEstado(estado: Estados): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosU.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&estado=') + (JSON.stringify(estado)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&estado=') + encodeURIComponent(JSON.stringify(estado)));
  }
  getEstados(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosS.php?tkn=' + (JSON.stringify(this.tkn)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/estadosS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para tarifas----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setTarifa(tarifa: Tarifa): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tarifa=') + encodeURIComponent(JSON.stringify(tarifa)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tarifa=') + encodeURIComponent(JSON.stringify(tarifa)));
  }
  upTarifa(tarifa: Tarifa): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasU.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&tarifa=') + (JSON.stringify(tarifa)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tarifa=') + encodeURIComponent(JSON.stringify(tarifa)));
  }
  borraTarifa(idTarifa: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const tarifa = new Tarifa();
    tarifa.id = idTarifa;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasD.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&tarifa=') + (JSON.stringify(tarifa)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tarifa=') + encodeURIComponent(JSON.stringify(tarifa)));
  }
  getTarifas(idCiudad: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (idCiudad) {
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + (JSON.stringify(this.tkn))
        + ('&tarifa=') + encodeURIComponent(idCiudad));

      return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + ('&tarifa=') + encodeURIComponent(idCiudad));

    } else {

      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + (JSON.stringify(this.tkn)));

      return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));
    }
  }

  getTarifasEmpresas(empresaId: string, estado: string, idCiudad: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    const tarifa = new Tarifa();
    tarifa.idempresa = empresaId;
    if (estado) tarifa.estado = estado;
    if (idCiudad) tarifa.idCiudad = idCiudad;

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&tarifa=') + (JSON.stringify(tarifa)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/tarifasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&tarifa=') + encodeURIComponent(JSON.stringify(tarifa)));

  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para rutas----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setRuta(ruta: Ruta): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasI.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ruta=') + (JSON.stringify(ruta)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ruta=') + encodeURIComponent(JSON.stringify(ruta)));
  }
  upRuta(ruta: Ruta): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasU.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ruta=') + (JSON.stringify(ruta)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ruta=') + encodeURIComponent(JSON.stringify(ruta)));
  }
  borraRuta(ruta: Ruta): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasD.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ruta=') + (JSON.stringify(ruta)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ruta=') + encodeURIComponent(JSON.stringify(ruta)));
  }


  getRutas(idCiudad: any): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (idCiudad) {

      const idc = JSON.stringify({ 'idCiudad': idCiudad });
      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + JSON.stringify(this.tkn)
        + '&ruta=' + idc);
      return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
        + '&ruta=' + encodeURIComponent(idc));

    } else {

      if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + JSON.stringify(this.tkn));
      return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

    }
  }


  getRuta(idRuta: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const ruta = ({ idRuta: idRuta });

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ruta=') + (JSON.stringify(ruta)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantTarifas/rutasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ruta=') + encodeURIComponent(JSON.stringify(ruta)));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios para ciudades               ----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setCiudad(ciudad: Ciudad): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));
  }
  upCiudad(ciudad: Ciudad): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadU.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ciudad=') + (JSON.stringify(ciudad)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));
  }
  borraCiudad(idCiudad: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    const ciudad = new Ciudad();
    ciudad.id = idCiudad;
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadD.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ciudad=') + (JSON.stringify(ciudad)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));
  }
  getCiudades(ciudad: Ciudad): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadS.php?tkn=' + (JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantCiudad/ciudadS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + ('&ciudad=') + encodeURIComponent(JSON.stringify(ciudad)));
  }

}


