import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import 'fullcalendar';
import { ScheduleModule } from 'primeng/primeng';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Horarios } from '../../comun/horarios';
import { Evento } from '../../comun/evento';
import { Global } from '../../comun/global';
import * as moment from 'moment';
import { ToggleButtonModule } from 'primeng/primeng';
import { Routes, RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-plan-home',
  templateUrl: './plan-home.component.html',
  styleUrls: ['./plan-home.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService, SconductoresService]
})
export class PlanHomeComponent implements OnInit {

  global: Global = new Global();

  @Input() planServicioVis: boolean;
  @Output() cerrarVistaEvento = new EventEmitter();
  @Output() ActualizaEvento = new EventEmitter();

  events: any[];
  evento: Evento;
  eventos: Evento[] = [];
  headerConfig: any;
  optionscfg: any;
  servicios: Servicio[];
  servicio: Servicio;
  conductores: Conductor[];
  conductor: Conductor;
  horarios: Horarios[];
  extbool: boolean;
  extIdConductor: string;
  serEdit: boolean = false;
  idCiudad:string="";
  title:string = 'TRAFICO ACTUAL';

  constructor(private sser: SserService, private sconductores: SconductoresService, private router: Router) {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.extbool = true;
    this.extIdConductor = '1';
    this.getServicios(null);
    if (this.extbool) this.getExenciones(null);
  }

  abrirEdit($event: any) {
    if ($event.url) {
      window.open($event.url);
      return false;
    }
  }
  generaColor(estado: string): string {
    var color = '#000000';
    if (estado && estado == this.global.ESTADO_EN_PETICION) {
      color = "#06052f";
    }
    if (estado && estado == this.global.ESTADO_ASIGNADO) {
      color = "#900C3F";
    }
    if (estado && estado == this.global.ESTADO_EN_CAMINO) {
      color = "#3d1c0a";
    }
    if (estado && estado == this.global.ESTADO_FINALIZADO) {
      color = "#3f9f40";
    }
    if (estado && estado == this.global.ESTADO_ANULADO) {
      color = "#ff3f3f";
    }
    if (estado && estado == this.global.ESTADO_ADMINISTRANDO) {
      color = "#ffbc3f";
    }
    if (estado && estado == this.global.ESTADO_EN_RUTA) {
      color = "#0a3909";
    }
    if (estado && estado == this.global.ESTADO_RESERVADO) {
      color = "#6ba5c1";
    }
    if (estado && estado == this.global.ESTADO_EN_POSICION) {
      color = "#ff00fc";
    }

    return color;
  }
  generaColorExencion(estado: string): string {
    var color = '#000000';
    if (estado && estado == "Avería") {
      color = "#fdf300";
    }
    if (estado && estado == "Enfermedad") {
      color = "#6662ff";
    }
    if (estado && estado == "Servicio Privado") {
      color = "#fc1cc6";
    }
    if (estado && estado == "Vacaciones") {
      color = "#00e4fd";
    }
    return color;
  }
  getServicios(conductor: string): void {

    this.eventos = [];
    const fechaFin = moment().add(3, 'month').format('YYYY-MM-DD');
    const fechaIni = moment().subtract(1, 'month').format('YYYY-MM-DD');
    var datos;
    if (conductor == null) { datos = { 'fechaIni': fechaIni, 'fechaFin': fechaFin ,'idCiudad':this.idCiudad}; }
    else { datos = { 'fechaIni': fechaIni, 'fechaFin': fechaFin, 'idConductor': conductor ,'idCiudad':this.idCiudad}; }
    this.sser.getServicios(JSON.stringify(datos)).subscribe(result => {
      this.servicios = result;
      // if(localStorage.getItem('debug')==='1') console.log('Consulta servicios ---->>>>>>' + JSON.stringify(result.json())); // JSON.stringify(this.servicios));
      for (const servicio of this.servicios) {
        let urlBase = this.router.url.split('/home')[0];
        this.evento = {

          'title': '(' + servicio.idservicio + ') --' + (servicio.Conductor?servicio.Conductor:"No asignado") + ' -- ' + this.fech2time(servicio.FechaDeRecogida),//+''+ "\n" + servicio.Origen + "\n" + servicio.Destino,
          'start': moment(servicio.FechaDeRecogida).subtract(servicio.tiempoMedioRecogida, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
          'end': moment(servicio.FechaDeRecogida).add(servicio.tiempoMedioServicio, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
          'color': this.generaColor(servicio.estado),
          'textColor': 'white',
          'url': "javascript:window.open('"+urlBase+"/edit/"+servicio.idservicio+"','','width=1000,height=600,left=50,top=50,toolbar=0');"
        };
        // if(localStorage.getItem('debug')==='1') console.log( "1>>>>>>>" + JSON.stringify(this.evento));
        this.eventos.push(this.evento);
      }
    });
  }
  getExenciones(conductor: string): void {
    if (conductor == null) { conductor = localStorage.getItem('idConductor'); }
    this.eventos = [];
    const fechaFin = moment().add(1, 'month').format('YYYY-MM-DD');
    const fechaIni = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const datos = { 'fechaIni': fechaIni, 'fechaFin': fechaFin, 'idConductor': conductor };
    this.sser.getExenciones(JSON.stringify(datos)).subscribe(result => {
      this.horarios = result;

      for (const horario of this.horarios) {
        //if(localStorage.getItem('debug')==='1') console.log('Consulta servicios ---->>>>>>horario.fechaIni=' + horario.fechaIni);
        //if(localStorage.getItem('debug')==='1') console.log('Consulta servicios ---->>>>>>horario.horaIni=' + horario.horaIni);
        this.evento = {

          'title': '(' + horario.id + ') --' + horario.nombreConductor + '\n' + horario.descripcionEventualidad,//+''+ "\n" + servicio.Origen + "\n" + servicio.Destino,
          'start': moment(horario.fechaIni + ' ' + horario.horaIni).format('YYYY-MM-DD HH:mm:ss'),
          'end': moment(horario.fechaFin + ' ' + horario.horaFin).format('YYYY-MM-DD HH:mm:ss'),
          'color': 'black',
          'textColor': this.generaColorExencion(horario.descripcionEventualidad),
          'url': ''
        };
        // if(localStorage.getItem('debug')==='1') console.log( "1>>>>>>>" + JSON.stringify(this.evento));
        this.eventos.push(this.evento);
      }
    });

  }
  fech2time(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('HH:mm');
  }
  fech2date(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('YYYY-MM-DD');
  }
  // Lista controlada por conductor
  actualizaLista(conductor: string) {

    if (conductor === '1') {
      this.extIdConductor = '1';
      this.getServicios(null);
      if (this.extbool) this.getExenciones(null);
    } else {
      this.extIdConductor = conductor;
      this.getServicios(conductor);
      if (this.extbool) this.getExenciones(conductor);
    }
  }
  ext() {
    this.actualizaLista(this.extIdConductor);
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    if(localStorage.getItem('debug')==='1') console.log("::::::::::::::::::::::::>>>>>"+this.idCiudad);
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sconductores.getConductores(cond).subscribe(result => {
      this.conductores = result;
      this.conductores.unshift({
        nombre: 'todos', id: '1', tipoDeServicio: '', idsesion: '', telefono: '',
        email: '', estado: '', tarifaMinima: '', idCompania: '', fechaAlta: '', fechaBaja: '',
        dni: '', /* vehiculo: '', matricula: '' , idTipoDeServicio: '',*/ color: "",
        compania: '', permisoConducir: '', visible: false, idVehiculo: '0',urlFoto:'',idCiudad:''
      });
    });

    this.optionscfg = {
      locale: 'es',
      editable: false,
      nowIndicator: true,
      weekNumbers: false,
      defaultView: 'agendaDay',
      slotEventOverlap: false,
      intervalEnd: '00:30:00',
      columnHeader: true,
      slotDuration: '00:30:00',
      allDaySlot: false,
      scrollTime: '08:00:00',
      navLinks: true,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source'
    };

    this.headerConfig = {
      left: 'agendaDay,agendaWeek,listDay',
      center: 'title',
      right: ''
    };
  }

}