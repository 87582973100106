import { Component, OnInit } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import {HttpModule} from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { Global } from '../../comun/global';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';

@Component({
  selector: 'app-ser-new-taf',
  templateUrl: './ser-new-taf.component.html',
  styleUrls: ['./ser-new-taf.component.css'],
  providers: [SserService, SconductoresService, SempresasService]
})
export class SerNewTafComponent implements OnInit {
  @Input() serNewVis: boolean;
  @Output() cerrarSerNewTafEvento = new EventEmitter();
  @Output() actualizaListaEvento = new EventEmitter();
  nuevoSer: Servicio;
  empresas: Empresas[];
  clientes: Cliente[];
  tipoDeServicios: TipoDeServicios[];
  fechaRecogida: string;
  extbool:boolean=false;
  global:Global = new Global(); 
  checked: boolean=false;
  numeroRepeticiones:number = 1;
  idCiudad:string="";
  colororigen: any;
  colorpasajero:any;
  colorvuelo:any;
  colorcartel:any;
  starRecord:any;
  colordestino:any;
  ok:any;
  handleFileInput:any;


  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('search1')
  public searchElementRef1: ElementRef;

  public searchControl: FormControl;
  public searchControl1: FormControl;

  public latitude: number;
  public longitude: number;
  public latitude1: number;
  public longitude1: number;
  public zoom: number;


  constructor(private sser: SserService, private sempresas: SempresasService, private sconductores:  SconductoresService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    this.latitude1 = 39.8282;
    this.longitude1 = -98.5795;
    this.serNewVis = false;
    this.nuevoSer = new Servicio();

    this.nuevoSer.FechaDePeticion = moment().format('DD/MM/YYYY HH:mm');
    //
    this.nuevoSer.estado = 'En petición';

    this.getEmpresas();
    this.getClientes();
    this.getTipoDeServicios();
    this.searchControl = new FormControl();
    this.searchControl1 = new FormControl();
    this.setCurrentPosition();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });

      const autocomplete1 = new google.maps.places.Autocomplete(this.searchElementRef1.nativeElement, {
        types: ['address']
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // formulario
          this.nuevoSer.Origen = place.formatted_address;

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
      autocomplete1.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place1: google.maps.places.PlaceResult = autocomplete1.getPlace();
          // formulario
          this.nuevoSer.Destino = place1.formatted_address;
          // verify result
          if (place1.geometry === undefined || place1.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude1 = place1.geometry.location.lat();
          this.longitude1 = place1.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
  ext(){
    
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  getClientes() {
    this.sempresas.getClientes().subscribe(result => {
      this.clientes = result;
      for (const cli of result){
        this.nuevoSer.idCliente = '';
        this.nuevoSer.cliente = cli.nombre;
      }
    });
  }
  getCliente2empresa(empresaId: string) {
    this.sempresas.getCliente2empresa(empresaId).subscribe(result => {
      this.clientes = result;
      for (const cli of result){
        this.nuevoSer.idCliente = cli.id;
        this.nuevoSer.cliente = cli.nombre;
      }
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevoSer.idEmpresa = idEmpresa;
    this.getCliente2empresa(idEmpresa);
    this.empresas.forEach( (item, index) => {
      if (item.id == idEmpresa) {
        this.nuevoSer.Empresa = item.nombre;
      }
    });
  }
  asignoCliente(idCliente: string) {
    this.nuevoSer.idCliente = idCliente;
    this.clientes.forEach( (item, index) => {
      if (item.id == idCliente) {
        this.nuevoSer.cliente = item.nombre;
      }
    });
  }
  asignarTipoDeServicio(idTipoDeServicio: string) {
    this.nuevoSer.idTipoDeServicio = idTipoDeServicio;
    this.tipoDeServicios.forEach( (item, index) => {
      if (item.id == idTipoDeServicio) {
        this.nuevoSer.TipoDeServicio = item.nombre;
      }
    });
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.latitude1 = position.coords.latitude;
        this.longitude1 = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }
  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  new() {
    if (this.fechaRecogida && this.nuevoSer.Origen && this.nuevoSer.Destino) {
      this.nuevoSer.FechaDeRecogida = moment(this.fechaRecogida, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      this.nuevoSer.FechaDePeticion = moment().format('YYYY-MM-DD HH:mm:ss');
      if(this.extbool) this.nuevoSer.estado=this.global.ESTADO_ADMINISTRANDO;
      this.nuevoSer.idtarifa=this.global.TARIFA_DEF_ADM;
      this.nuevoSer.nombreTarifa=this.global.TARIFA_NOM_ADM;
      let cont = 1;
      while (cont<= this.numeroRepeticiones) {
        this.sser.newServicio(this.nuevoSer).subscribe();
        this.wait(500);
        cont++;
      }
      this.cerrarSerNewTafEvento.emit();
    }
  }
}
