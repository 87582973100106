import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Global } from '../comun/global';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ScomunService {
  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios incidencias ----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getIncidencias(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }
  updateIncidencia(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasU.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }
  insertIncidencia(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + (datos));

    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/s_comun/incidenciasI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + encodeURIComponent(datos));
  }


}
