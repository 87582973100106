import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { SserService } from '../../servicios/sser.service';
import { MapsAPILoader } from '@agm/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { SconductoresService } from '../../servicios/sconductores.service';
import { Conductor } from '../../comun/conductor';
import * as moment from 'moment';
//import { } from '@types/googlemaps';
declare const google: any;
import { Ciudad } from '../../comun/ciudad';
import { StarifasService } from '../../servicios/starifas.service';



@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SconductoresService, SserService,StarifasService]
})
export class MapaComponent implements OnInit {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  markers: google.maps.Marker[] = [];
  conductores: Conductor[];
  idCondAct: string;
  extbool: boolean;
  idCiudad: string = "";
  ciudades: Ciudad[] = [];
  constructor(private sser: SserService, private sconductores: SconductoresService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private starifas: StarifasService) { }
  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getConductores();
    this.getCiudades();
    /* this.latitude = 40.416775;
    this.longitude = -3.703790; */
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      let c = this.ciudades.filter(item => item.id == this.idCiudad);
      if (c && c.length > 0) {
        this.latitude = +c[0].latitud;
        this.longitude = +c[0].longitud;
        this.zoom = +c[0].zoom;
        this.getMap();
      }
    });
  }
  getMap() {
    var mapProp = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    //this.getPosGlob();
  }
  setMapType(mapTypeId: string) {
    this.map.setMapTypeId(mapTypeId)
  }
  setCenter() {
    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
    this.map.setZoom(9);
  }
  getConductores() {
    let cond = JSON.stringify({ 'idCiudad': this.idCiudad });
    this.sconductores.getConductores(cond).subscribe(result => {
      this.conductores = result;
      this.conductores.unshift({
        'nombre': 'todos', 'id': '1', 'idsesion': '', 'telefono': '', 'email': '', 'estado': '',
        'tarifaMinima': '', 'idCompania': '', 'fechaAlta': '', 'fechaBaja': '', 'dni': '',
        'permisoConducir': '', 'visible': false, 'color': '', 'compania': '', 'tipoDeServicio': '', 'idVehiculo': '',
        urlFoto: '', idCiudad: ''
      });

      this.getPosiciones('1');
    });
  }
  showPosition(latitude: number, longitude: number, Conductor: string, idConductor: string, tiempo: string) {
    let location = new google.maps.LatLng(latitude, longitude);
    this.map.panTo(location);

    let horaCoche = moment(tiempo, 'YYYY-MM-DD HH:mm:ss').toDate().getTime();;
    let now = moment().toDate().getTime();
    let dif = this.round1(((now - horaCoche) / 60000), 1);

    let iconKURL;
    if (dif > 5) {
      iconKURL = "assets/img/pin1.png"
    } else {
      iconKURL = "assets/img/pin2.png"
    }
    if (this.extbool == true && dif > 5) {

    } else {

      let marker = new google.maps.Marker({
        position: location,
        map: this.map,
        //draggable: true,
        icon: iconKURL,
        title: ' ' + Conductor + ' (' + tiempo + ')'
      });
      marker.addListener('click', () => {
        this.markerHandler(marker);
      });
      this.markers.push(marker);
    }
  }

  round1(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  markerHandler(marker: google.maps.Marker) {
    alert('Conductor: ' + marker.getTitle());
  }
  recargarPos() {
    this.dropMarkets();
    this.getPosiciones(this.idCondAct);
  }


  getPosiciones(id: string) {
    if (id === '1') {
      this.getPosicion(null);
    } else {
      this.getPosicion(id);
    }
  }


  getPosicion(id: string) {
    const config = JSON.stringify({ 'tabla': 'posiciones', 'conEstado': '0' });
    this.sser.lista(config, null, null, null, null).subscribe(result => {
      if (result && result.length > 0) {
        for (let res of result) {
          let c = this.conductores.filter(item => item.id == res.idConductor);
          if (c && c.length > 0) {
            let cond = c[0];
            if (id) {
              if (id == cond.id) this.showPosition(res.latitud, res.longitud, cond.nombre, res.idConductor, res.ultimaActualizacion);
            }
            else this.showPosition(res.latitud, res.longitud, cond.nombre, res.idConductor, res.ultimaActualizacion);
          }
        }
      }
    });
  }

  dropMarkets() {
    for (let m of this.markers) {
      m.setMap(null);
    }
  }
  actualizaConductor(id: string) {
    this.idCondAct = id;
    this.dropMarkets();
    this.getPosiciones(id);
  }

  getPosGlob() {
    let fechaIni = moment().format('YYYY-MM-DD');
    let fechaFin = moment().format('YYYY-MM-DD');
    let idConductor = "22";
    let datos = { "fechaIni": fechaIni, "fechaFin": fechaFin, "idConductor": idConductor };

    let flightPlanCoordinates = [];

    this.sser.getPosGlob(JSON.stringify(datos)).subscribe(result => {
      if (result && result.length > 0) {
        for (let res of result) {
          let latLng = new google.maps.LatLng(res.latitud, res.longitud);
          flightPlanCoordinates.push(latLng);
        }
      }
      this.pintarPolyline(flightPlanCoordinates);
    });

  }

  pintarPolyline(fp: any) {
    let flightPlanCoordinates = fp;
    /* [
      {lat: 37.772, lng: -122.214},
      {lat: 21.291, lng: -157.821},
      {lat: -18.142, lng: 178.431},
      {lat: -27.467, lng: 153.027}
    ]; */

    var flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    flightPath.setMap(this.map);

  }
}
