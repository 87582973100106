import { Component, OnInit } from '@angular/core';
import { Comercial } from '../../comun/comercial';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { ScomercialgestorService } from '../../servicios/scomercialgestor.service';
import { SfactService } from '../../servicios/sfact.service';
import { Propietario } from '../../comun/propietario';
import { Compania } from '../../comun/compania';

@Component({
  selector: 'app-comerciales',
  templateUrl: './comerciales.component.html',
  styleUrls: ['./comerciales.component.css'],
  providers: [SserService, ScomercialgestorService, SfactService]
})
export class ComercialesComponent implements OnInit {

  @Input() facConVis: boolean;
  @Output() cerrarfacConEvento = new EventEmitter();

  comerciales: Comercial[];
  comercial: Comercial;
  selectedValuesCom = [];
  ssComMails: any[] = [];

  fechaIni: string;
  fechaFin: string;
  fechaUltFact: string;
  companiaFact: Compania;
  propietario: Propietario;
  message: string[];
  msgs = [];
  aleVis = false;
  idCiudad:string="";

  constructor(private sser: SserService, private scom: ScomercialgestorService, private sfact: SfactService) { }


  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.getComerciales();
    this.getPropietario();
  }


  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.aleVis = !this.aleVis;
  }
  // fechas Ini
  actualizaIniConFechaHoy() {
    this.fechaIni = moment().format('DD/MM/YYYY');
  }

  actualizaIniConFechaUltimaFacturacion() {
    if (this.fechaUltFact == '') {
      this.fechaIni = moment().format('DD/MM/YYYY');
    } else {
      this.fechaIni = moment(this.fechaUltFact, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
    }
  }
  actualizaIniConFechaAyer() {
    this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
  }
  actualizaFinConFechaUltimaFacturacionplus1() {
    if (this.fechaUltFact == '') {
      this.fechaFin = moment().add(1, 'M').format('DD/MM/YYYY');
    } else {
      this.fechaFin = moment(this.fechaUltFact, 'DD/MM/YYYY').add(1, 'days').add(1, 'M').format('DD/MM/YYYY');
    }
  }
  actualizaFinConFechaHoy() {
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizafinConFechaManana() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(15, 'days').format('DD/MM/YYYY');
  }
  actualizafinConFecha15d() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(1, 'M').format('DD/MM/YYYY');
  }

  getComerciales() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad,"estado":"activo"});
    this.scom.getComercialeS(cond).subscribe(result => {
      this.comerciales = result;
      if (result) {
        for (let r of result) {
          this.actualizoComercial(r);
          break;
        }
      }
    });
  }
  actualizoComercialId(id: string) {

    let t = this.comerciales.filter(item => item.id == id);
    if (t && t.length > 0) {
      this.comercial=t[0];
      const datos = JSON.stringify({ 'cif': t[0].cif });
      this.sfact.getComFact(datos).subscribe(result => {
        if (result && result.length > 0) {
          for (const res of result) {
            if (res) {
              this.fechaUltFact = moment(res.fechaFin, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
          }
        } else this.fechaUltFact = moment().subtract(1, 'M').format('DD/MM/YYYY');
      });
    }else this.fechaUltFact = moment().subtract(1, 'M').format('DD/MM/YYYY');
  }
  actualizoComercial(com: Comercial) {
    this.comercial=com;
    const datos = JSON.stringify({ 'cif': com.cif });
    this.sfact.getComFact(datos).subscribe(result => {
      if (result && result.length > 0) {
        for (const res of result) {
          if (res) {
            this.fechaUltFact = moment(res.fechaFin, 'YYYY-MM-DD').format('DD/MM/YYYY');
          }
        }
      } else this.fechaUltFact = moment().subtract(1, 'M').format('DD/MM/YYYY');
    });
  }
  getPropietario() {
    let propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
        this.propietario = prop;
        //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
        break;
      }
    });
  }
}
