import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-categorias',
  templateUrl: './mant-categorias.component.html',
  styleUrls: ['./mant-categorias.component.css']
})
export class MantCategoriasComponent implements OnInit {
  @Input() mantCategoriasVis: boolean;
  @Output() cerrarMantCategoriasEvento = new EventEmitter();
  categoriasListVis:boolean;
  categoriasVis:boolean;
  constructor() { }

  ngOnInit() {
  }

}
