import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { HttpModule } from '@angular/http';
import { StarifasService } from '../../../servicios/starifas.service';
import { Ciudad } from '../../../comun/ciudad';
import { Global } from '../../../comun/global';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-crud-ciudad',
  templateUrl: './crud-ciudad.component.html',
  styleUrls: ['./crud-ciudad.component.css'],
  providers: [StarifasService,ConfirmationService]
})
export class CrudCiudadComponent implements OnInit {
  @Input() ciudadVis: boolean;
  @Output() cerrarCiudadEvento = new EventEmitter();
  nuevaCiudad: Ciudad = new Ciudad();
  ok:any;

  constructor(private confirmationService: ConfirmationService,private sser: StarifasService) { }

  ngOnInit() { }

  new() {
    if (this.nuevaCiudad.nombre!=null && this.nuevaCiudad.nombre.length > 0){
      this.nuevaCiudad.estado = "activo";
      this.nuevaCiudad.fechaAlta = moment().format('YYYY-MM-DD HH:mm:ss');
      this.sser.setCiudad(this.nuevaCiudad).subscribe(result=>{
            
        let msg ="Se ha creado la ciudad";
        
        if (result.error){
          msg = "No se ha creador la ciudad debido al siguiente error: "+result.error;
        }
        this.confirmationService.confirm({
          message: msg,
          accept: () => {
            this.cerrarCiudadEvento.emit();
          }
        });
      });
    }
  }
}
