export class Comercial {
    id: string;
    nombre: string;
    cif: string;
    direccion: string;
    telefono: string;
    email: string;
    estado: string;
    fechaAlta: string;
    fechaBaja: string;
    visible: boolean;
    porcentajeBase: string;
    porcentajeResto: string;
    numeroDeServicios:string;
    recibirNot:number;
}
