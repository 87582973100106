import { Component, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import { HttpModule} from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Propietario } from '../../comun/propietario';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Compania } from '../../comun/compania';
import { Global } from '../../comun/global';
import { ConfirmationService } from 'primeng/api';
import { StarifasService } from '../../servicios/starifas.service';
import { Ciudad } from '../../comun/ciudad';


@Component({
  selector: 'app-pdf-companias',
  templateUrl: './pdf-companias.component.html',
  styleUrls: ['./pdf-companias.component.css'],
  providers: [SserService, ConfirmationService,StarifasService]
})
export class PdfCompaniasComponent implements OnInit {
  @Input() compania: Compania;
  @Input() sfechaIni: string;
  @Input() sfechaFin: string;
  @Input() base: number;
  @Input() servicios: Servicio[];
  @Input() selectedValuesCond:any[];
  @Input() ssCondMails:any[];

  global: Global = new Global();

  anio:string= moment().format('YY');
  @Output() cerraraleVisEvento = new EventEmitter();

  @Input() propietario: Propietario;
  title = 'LUXUCAR';
  dd: any;
  pdfDocGenerator: any;

  body: any  = [];
  idCiudad:string="";
  ciudades: Ciudad[] = [];

  constructor(private staf: StarifasService,private sser: SserService, private confirmationService: ConfirmationService) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getCiudades();
    moment.locale('es');
  }
  getCiudades() {
    const ciu = new Ciudad();
    // ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getNombreCiudad(idCiudad): string {
    
    let res = '';
    let c = this.ciudades.filter(item => item.id == idCiudad);
    if (c && c.length > 0) {
      res = c[0].nombre;
      
    }
    return res;
  }
  verFac() {
    this.getPDF();
    this.pdfDocGenerator.open();
  }
  downloadFac() {
    this.getPDF();
    this.pdfDocGenerator.download();
  }
  imprimirFac() {
    this.getPDF();
    this.pdfDocGenerator.print();
  }
  getPDF() {
    this.getTableBodyContent();
  }
  savePDF(){
    this.getTableBodyContent();
    const pdfDocGenerator = pdfMake.createPdf( this.dd);
     pdfDocGenerator.getDataUrl((data) => {


      let dirE = this.compania.nombre;
      var re = /\s/g; 
      dirE = dirE.replace(re, '_');
      var re = /\./g; 
      dirE = dirE.replace(re, '_');
      var re = /\,/g; 
      dirE = dirE.replace(re, '_');
      if(dirE.charAt(dirE.length-1)=='_') dirE=dirE.substring(0,dirE.length-1);
      dirE = "/"+dirE+"/";

      moment.locale('es'); 
      var dirF = moment(this.sfechaIni,'YYYYMMDD').format('YYYY')+'/';
      dirF =dirF+ moment(this.sfechaIni,'YYYYMMDD').format('MMMM')+'/';
      data= data.replace(/\s/g, '')
      
      let filtro="";
      if(this.selectedValuesCond.length<this.ssCondMails.length)filtro="_conFiltro";

      let name = 'luxucar_fac_'+'_'+this.sfechaIni+'_'+this.sfechaFin+filtro;
      let ext = ".pdf";
      let dirB = this.global.FAC_COMPANIAS_URL;
      
      let datos={'name':name,'ext':ext,'dirB':dirB,'dirE':dirE+this.getNombreCiudad(this.idCiudad)+"/",'dirF':dirF,'data':data};
      this.sser.upload(JSON.stringify(datos)).subscribe(res=>{
        this.confirmationService.confirm({
          message: 'Se guardo una copia del pdf en el servidor',
           accept: () => {
          }
        });
      });
    });
  }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  dtext(dato: string) {
    return ({ text: dato + '\n',  fontSize: 8, alignment: 'left' });
  }
  dservicios() {
    this.body = [];
    let row = [];
    row.push(this.dcolumn());
    row = this.drows(row);
    return row;
  }
  dcolumn () {
    const col = [];
    col.push(this.dtext('Luxucar ID  / Conductor'));
    col.push(this.dtext('Concepto'));
    col.push(this.dtext('UDS.'));
    col.push(this.dtext('Base'));
    col.push(this.dtext('Base Total'));
    col.push(this.dtext('%IVA'));
    col.push(this.dtext('IVA'));

    return col;
  }
  drows(row: any) {
    for (const ser of this.servicios) {
      let hayId = this.selectedValuesCond.filter(item => item.id == ser.idConductor);
      if(hayId && hayId.length>0) {
        const col = [];
        col.push(this.dtext('Lux'+ser.idservicio+this.anio+'\nconductor='+ser.idConductor));
        col.push(this.dtext(((ser.estado == '(Anulado)') ? 'Anulado' : '') + ser.FechaDeRecogida + '--' + ser.Origen + '--' + ser.Destino));
        col.push(this.dtext(''+this.getCant(ser,'0')));
        col.push(this.dtext(this.getCalc3(ser,'0') + '€'));
        col.push(this.dtext(this.getCalc(ser,'0') + '€'));
        col.push(this.dtext(this.propietario.iva + '%'));
        col.push(this.dtext(this.round1(this.getCalc(ser,'0')* this.round1((+this.propietario.iva)/100,1),1)+ '€'));
        row.push(col);
        if(ser.parada && ser.tarificacionPorTiempo == 0) this.drowParada(ser,row);
        if ((ser.tarificacionPorTiempo == 1) && (this.getCantDispo(ser)>1)) this.drowDispo(ser,row);
      }
    }
    // console.log('row---> ' + JSON.stringify(row));
    return row;
  }

  drowDispo(ser:Servicio, row: any){
    const col = [];
    col.push(this.dtext('Lux'+ser.idservicio+this.anio+'\nconductor='+ser.idConductor));
    col.push(this.dtext('Esta disposición supero el tiempo contratado'));
    col.push(this.dtext(''+(+this.getCantDispo(ser)-(+ser.tiempoTH))));
    col.push(this.dtext(''+(this.getBaseDispo(ser))+'€'));
    col.push(this.dtext(''+(this.getBaseDispo(ser)*(this.getCantDispo(ser)-(+ser.tiempoTH)))+'€'));
    col.push(this.dtext(this.propietario.iva + '%'));
    col.push(this.dtext(this.round1(this.getBaseDispo(ser)*(this.getCantDispo(ser)-(+ser.tiempoTH))* this.round1((+this.propietario.iva)/100,1),1)+ '€'));

    

    row.push(col);
  }

  drowParada(ser:Servicio, row: any){
    const col = [];
    col.push(this.dtext('Lux'+ser.idservicio+this.anio+'\nconductor='+ser.idConductor));
    col.push(this.dtext('Parada de más de '+this.propietario.tiempoParadaCortesia+' min.'));
    col.push(this.dtext(''+this.getCant(ser,'1')));
    col.push(this.dtext(this.getCalc3(ser,'1') + '€'));
    col.push(this.dtext(this.getCalc(ser,'1') + '€'));
    col.push(this.dtext(this.propietario.iva + '%'));
    col.push(this.dtext(this.round1(this.getCalc(ser,'1')* this.round1((+this.propietario.iva)/100,1),1)+ '€'));
    row.push(col);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  dtext1(dato: string) {
    return ({ border: [false, true, false, false],
              fillColor: '#eeeeee',
              align: 'left',
              text: dato + '\n',   style: 'tableHeader'});
  }
  dtext2(dato: string) {
    return ({ border: [false, true, false, false],
              fillColor: '#dddddd',
              text: dato + '\n',   style: 'tableHeader'});
  }
  dservicios1() {

    let row = [];
    row.push(this.dcolumn1());
    row = this.drows1(row);
    return row;
  }
  dcolumn1 () {
    const col = [];
    col.push(this.dtext1('BASE INMPONIBLE'));
    col.push(this.dtext1('TIPO IMPUESTO'));
    col.push(this.dtext1('IMPUESTO'));

    return col;
  }
  drows1(row: any) {
      const col = [];
      col.push( this.dtext2(this.round1(this.base,2) + '€'));
      col.push( this.dtext2('I.V.A.' + this.propietario.iva +'%'));
      col.push( this.dtext2(this.round1(0 + this.base * (+this.propietario.iva) / 100,2) + '€'));
      row.push(col);
    return row;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  dtext3(dato: string) {
    return ({ border: [false, true, false, false],
              fillColor: '#eeeeee',
              align: 'left',
              text: dato + '\n',   style: 'tableHeader'});
  }
  dtext4(dato: string) {
    return ({ border: [false, true, false, false],
              fillColor: '#dddddd',
              text: dato + '\n',   style: 'tableHeader'});
  }
  dservicios2() {
    const row = [];
    row.push([this.dtext1('Total Base Imponible: ' + this.round1(this.base,2) + '€')]);
    row.push([this.dtext1('Total I.V.A. :' + this.round1((0 + this.base * (+this.propietario.iva) / 100), 2) + '€')]);//round1((base*10/100),1)
    row.push([this.dtext1('TOTAL: ' + this.round1((0 + this.base + (0 + this.base * (+this.propietario.iva) / 100)), 2) + '€')]);
    return row;
  }
  dservicios3() {
    const row = [];
    row.push([{ text: this.compania.nombre + '\nCIF: ' + this.compania.cif + '\n'+ this.compania.direccion + '\nNº de Factura: <<PROFORMA>>' +
                  '\n', fontSize: 10, alignment: 'left' }]);
    return row;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getTableBodyContent () {
      this.dd = {
      watermark: { text: 'PROFORMA', color: 'blue', opacity: 0.1, bold: true, italics: false },
      info: {
      title: 'LUXU-' + this.sfechaIni + this.compania.id + this.sfechaFin,
      author: 'luxucar',
      subject: 'factura: ' + 'LUXU-' + this.sfechaIni + this.compania.id + this.sfechaFin,
      keywords: 'keywords for document',
      },
      content: [
        
        {columns: [
          { 
              style: 'tableExample3',
              table: {
                body: this.dservicios3()
              }
          },
          { image: 'logo',width: 100, style: 'rightme' }
        ]},
      
        { text: ' ' + '\n\n', fontSize: 10, alignment: 'left' },

      { text: this.propietario.nombre + '\n', fontSize: 12, alignment: 'left' },
      { text:  'CIF:' + this.propietario.cif + '\n', fontSize: 12, alignment: 'left' },
      { text:  '' + this.propietario.direccion + '\n\n\n', fontSize: 12, alignment: 'left' },
      { text: 'Fechas, del ' + moment(this.sfechaIni,'YYYYMMDD').format('DD/MM/YYYY') + ' al '+ moment(this.sfechaFin,'YYYYMMDD').format('DD/MM/YYYY') + '\n\n', fontSize: 12, alignment: 'left' },

      { text: '\nFACTURA \n\n', fontSize: 14, bold: true, alignment: 'center' },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', '*', 'auto','auto', 'auto', 'auto','auto'],  
          body: this.dservicios()
        }
      },
      { text: ' ' + '\n\n', fontSize: 10, alignment: 'left' },
      {
        style: 'tableExample1',
        table: {
          body: this.dservicios1()
        }
      },
      { text: ' ' + '\n\n', fontSize: 10, alignment: 'left' },
      {
        style: 'tableExample2',
        table: {
          body: this.dservicios2()
        }
      },
      { text: '\n\n'+'Luxucar Drive, S.L. C.I.F.:B88131552 Inscrita en Registro Mercantil de Madrid Tomo:37.726- Folio:176 - Hoja: M673783- Libro: 0 - Inscripción: 1ª ', fontSize: 8, alignment: 'left' }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      rightme:
        {   
            alignment: 'right'
        },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5]
      },
      tableExample: {
        fontSize: 8,
        margin: [0, 0, 0, 0]
      },
      tableExample1: {
        fontSize: 10,
        margin: [180, 0, 0, 0]
      },
      tableExample2: {
        fontSize: 10,
        align: 'right',
        margin: [310, 0, 0, 0]
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      }
    },
    images: {
      logo:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOcAAAB9' +
      'CAYAAABZCXQFAAAACXBIWXMAAC4jAAAuIwF4pT92AAAgAElEQVR4nO' +
      '2dB6wtVdmGB8Hf3nsv2DsWFCvNjiIS7AbsIApYEBVF1KiIxhbFAhGJ' +
      'CoLYexQU1IC9d7H33nv5fSZ5Tt77sWbvcw/3tHvXm0z23rOnrFnz9e' +
      '9baw0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0' +
      'dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHbOx1caesO222' +
      '+689dZbnzJ0dHQsGv/+9793Oeuss07dmHPONXR0dKxJdObs6Fij6MzZ' +
      '0bFG0Zmzo2ONYpuVuMl5znOe4dznPvew9dZbD//973+Hc53rXMN//vO' +
      'fcfufozz861//Gv75z38OHVseoIlLXvKSI11stdVWC3QBjfAbQCO//vW' +
      'vhy0Ny8qc22yzzXCxi11sgSmvfOUrDze4wQ2Gf/zjH+O+b3/728M3v/n' +
      'N8T9eyB/+8IfxRXRs3rjc5S43Cuzzn//8IwP++c9/XqARBDWADvjNJ8d' +
      'c9apXHWkEIf6rX/1qixDmy8qcF7/4xYeLXvSiw21ve9uxM3/4wx8O733' +
      've8dOZ7v61a8+3PnOdx616mc/+9nx/9/+9rdDx+aHC1/4wsNVrnKV4UI' +
      'XutDwt7/9bWQ06GO77bYbhTZMiLb8+9//PpzvfOcb/vrXv450AZ1w/Kc' +
      '//enhzDPPHL9f9rKXHRn2d7/73SjQN1csG3PS0UjDnXbaaXjnO9+5wJ' +
      'Bs4jvf+c5w1llnjfv22GOP4Uc/+tGobZWeHesfV7jCFUatBy3AgDvuu' +
      'OOoOXnHv/jFL4bTTz99OProo0dmrJBWYOJdd911OPDAA4cLXOACoxA/' +
      '9thjx+8wPZoUpt3csKzMCTRPBBIvf7tPc5aX2Jlz/QM/8lrXutZoumI' +
      '97bzzzqM787a3vW0444wzNqAD/U2/C4/5zW9+M5x00knDiSeeOO674Q' +
      '1vODzjGc8YrayXvOQlI/Ny3E9/+tNhc8KyMaedXTvdTzs+P9061jcwV' +
      'dGO5z3veYc73vGOw09+8pPh0EMPHf7yl78sHFPffdJAZdB6/Je+9KXho' +
      'IMOolptePaznz2au29961tH8xhtvLlo0WVPpVQm7Vgd5HtY6pbnt3CRi' +
      '1xkjCHAmLgzt7jFLYbDDz98eMELXrABY1aGc18K7lnt9zhcosc+9rGj/' +
      '3rkkUcOf/rTn4ZLXepSo6beHLDNsMKwc6tp27E0yCy4A2z6+nzqWlSrp' +
      'WqpFjO0zqn/J5MRpLnxjW88XPCCFxzucIc7DMcff/zw8Y9//Gzt9N23N' +
      'GK2Y8r9aWnaE044YfjgBz84PP/5zx+OOeaY4Rvf+MZo6mIOr2csO3NOd' +
      'XhlUvNaXcO2IQMSwWSTCQmgZZ+ZJzQN4ff66XcxS2tlG+on5xHwIUV2p' +
      'StdabjmNa85PP7xj2+aljIU7bat2e4pZmztq3RFUGjfffcdGRSf9pRTT' +
      'hkucYlLrOv86IprTtBi0o4NQb/AeP/3f/83MiPpBYia/CDIfKDFHJkbz' +
      'CKPJPyMmicjJ9Om0EwmTkFKu2gj6ZHrX//6w/Wud72xjQcffPD4HxqU/' +
      '9XevmfazUa7jOBWbSpSS9a+mdp3yCGHjBt9hzZdzxp0WZmz1dn1/1aAa' +
      'EsGxAxhkSYgJwjB8xsks1lllUxWt4yUezyMURkUtN6Nn/lOLAog8kqah' +
      'OAPjEkq5LjjjhvNW6+XTJ7XMM8JE/OMt7nNbUaNS9t8NvLi/E/e83Of+' +
      '9zwgQ98YANNO4tB0Z5Ec8mDkh/FF/79738/rDcsK3NOReBanbolM6WmH' +
      'sRo6gHmZL8a0S21ZP1eN68N1FgtDdnaQNW2akwYBs14u9vdbrj2ta89t' +
      'pdcpUUE+VytQBTtvc997jNc8YpXHL+fdtppw8knn7xwH/ZxHTb6hYjv' +
      's571rPE7PiWBoHy26kvz+bznPW94xSteMTI1AqMzZwOzTNhqtmxpTIp' +
      'G1FRFg1DqiKas5p/1x2rC1CD+1/Inc2sJx5apm6iBF9oLw3BPorKXv/' +
      'zlh2tc4xrDEUccMWrOluVTLaK999579AXRsp/5zGdGIYTPihaW+a0WQ' +
      'jujZb/4xS8O73nPe8b/nvCEJ4zRYJjP6qBWoIj/MW+51y9/+cthPWJV' +
      'fc4t0d+UyGFIPtFAEBA5QbWGDOdmX+F7gmREGQcibvmbwtpV7onJyJZ' +
      'MbttaDKupzTlck4KCS1/60qPmPOyww8YURrYlr+e10LB77rnnyJRf/v' +
      'KXx+cmgEQbON9aWf1UfVXAsTe72c1GM/Woo44amfSZz3zmqHHf/e53' +
      'b9B2PsmBvuUtbxmvCYOv13rtFclzVvNj6rjNHTAXZivaAi15tatdbS' +
      'RQGBUCkmkgKD5lJkf0gNSK+n+pPT2majH3Gaxhs3Z1KhgEZExNYkxR' +
      'IrO77LLLaGISbEkT2+OyjbvvvvvIyAcccMBYxUMQiWdEoxFN/eMf/7' +
      'hwDUcooaEVBrSH4njae5nLXGY0o9GgaO4nPvGJC23leXfbbbfxWNI4' +
      '/M786nrDio7nrLmtWcdsToC4YUpMVqKHVLZgxsGUECAECbGyGYU1bw' +
      'kqsWcEFo2JSSlzpzkMY6GRuY4Ej5aCGTjHwBN9Xs1f35GMyfVo753u' +
      'dKfRlP3Wt741DlaowadsI9sDHvCA0fzE9OW5aQf+H4zmMTAQz54BLh' +
      'k9+yWDV1QDoYU/9alPjf4ooF23vvWtR+1KMcJ6H0Sxoj5na1/1STYn5' +
      'jQnCXMQMcR8hUnT/EzzUiuj5S+2fkvYMoZa1OvBTBA2TOm+1LAca8S' +
      '0NVwvUx0Ilpvf/Oaj5kTbYzpmsK9Gftm/1157DT/4wQ/GkUgwJszC/' +
      'dOa8pkQMvZNjQ4rcNLc5ZnQnPit/Pfc5z531Kj77LPP2Maf//znw3r' +
      'HivqcLT9zKoS/XpH5SQDBEcDAjEytojbSx0rzchZz5ne0n+Yv18nAE' +
      'PfnP33RCos+qkkreAavBWOigan8gfDf9a53jfth3owMJ2MxTJDn41i' +
      'YmSKBZHaub8qEc9DmCIkcZG079XfTqsACQPDQHgJGXPPzn//82NfU1' +
      '24OWBHmrIGGum9zAMwAEUNMEBEERNAEbSnj6FPJlMkANcpafcDaVzI' +
      'ex1Zty2/agladisJm/ztcCy3Pdc2r0k6Ei+kM/oOBSE+opVvvEH8a5' +
      'qRSiEgsWsxnVbObPjKvqXmOELCN+Unb9Ec5B6uA5+PaaFAG7nNMjeC' +
      'uZ6xaKmVzMmshYDZNTPJqMKZ+VTIlhGjUtfp4FTVPmOBeapJkFDanh' +
      'Wld075W2xmEgpkRMGgw2+ZMBfhyMBlMB2N6r6z+yc/99ttvePKTnzw' +
      'yDaasx2ohaD6nxcBGIEeLo9UPalE0ps+Kefuzn/1sHKJGcIn/8pz1z' +
      'KBrZoKv9ciUaig+IUKkPikDiNIgjIGYZJqabqibhJxbFrWjZbxmmqY' +
      'yHEEeg0mtNlctzbGcI7PLmAoaxmViRmKekm/M4I/MplbbYYcdho9+9' +
      'KPjs8Jsmav1+JwzKq+Tx7fMeC0OBY8WiZFfot+WN7Yi1usNK6I5kyA' +
      'qageuh85U++nXQThoFrSlxGr0lGMh9Cy3k6GyX/yuqZeplYzA8h9aM' +
      'yPfma/Uj5PRst8zEJSmM88Bw2smI3C4BgEWCF4zktEe6RPazrw/pXj' +
      '777//cJ3rXGfUZKlVvZ/BHYv2syQQocP+DE7loIha8K/PikmMiUt7M' +
      'XENPLXiHOsFK+JztszaVsetFmMmAVUTLffliBBePpIexiMtAkGTJPd' +
      'ZlPb83xqhkdeVwCEwzVU1SzJAapkq8DJamymEyphJpBkdNh+I9odB+' +
      'KTe9bvf/e5o1jL7wHOe85wNNFneF1A1xGgQ8pjkP2EiriVTeb/0O5M' +
      'OeG7SLPqnLaGtYNTklgnZbzEEmp6J43QjUhCuJ6zokLHsnFaQYrlQp' +
      'a4bRGCSn81yujQns21GG3PjOWAqGFOGymR8mm9VY9WopOaq7YVBJK4' +
      'cGlYZLBnFfa2+bjGm1zetoiAh9UHUkygzbeJ5YHprgD0vr0ehwZOe9' +
      'KRh++23H3/jvzJrgZqRYI3+d7YhmSdrc/OYbD/HO+pFTeyzcA/aB4O' +
      'i6Q04ZVvXC1alfK8iO38ek0IcaiNNGlB9szR/3BwPqUnF7/ry1QoyV' +
      'y0qr75W1SLJINaKJlGlv1eL1mkPGtjfGfjw+vnpPbIfW/3XYk6Z3cg' +
      'y3027wJhcl0DL7W9/+9HvJE1RfdXsC475/ve/P/quFiekeY0AY3OOq' +
      'Kr90x9NoVjfj8yMoKjn+B4wb4kq42qg+TlHd2GWi7XWsGJzCPm9dky' +
      'V5rOuRwdT+UFkDhNGSSwzGixIzWhgxHvLeFad1BEfyUC2r35vEWfrh' +
      'c+yFFqQKE1lVH+N9mbRwVQ/+Znf7R/7Sm1sisIKHfxMSuRgNP4jFYR' +
      'Zy1w9QP+33pMSPYrNKbaAcaxOMjiVPibaUcFlDtP9+LYK3uzXFILAq' +
      'Kx9xTE+I98ZuUI9LnRixDi1dFocaxXLPmSs9X3K9/S3n1XzoVXYjFQ' +
      '61jGZU43msKM6uqOO7JjFiPWzEks9Z+q5q9BpMU7+53mpXdMkbmnLu' +
      'mWf5Mb5MqJF4T4Lx1MWh9CDMWE0o9EIBZitpjpsJwITaG7yG21qOkm' +
      'Gtu01jaTPbZR4ygpI2G6Ow60w2gt4Nup4sQIoUkjrJ33etaxBl32wd' +
      'ZX+8453+JTSln0yp/WpEAgS0ZpMma8yYmo4MI8JK1PN+z3rOfyskcY' +
      'pi0JIcNluBVVGfCumLJBqGmqu6z/n+TASlolpFb7zPgjuZAqoCiaOZ' +
      '4Y98qCYlBzH+3HcZbav9mP2TRWAopVWSsGd32kLdAKN0HatLSwBNbd' +
      '9WZ9/rWFVNGd9OfqCdCida6emb4eZg2Q0aKJ518qjLYbxajsWsz9Rz' +
      'cdqOqrRNbUrcaWGTG2fgiY1fc0tZrvS764CUSioOEa/Nkv41CjMOgD' +
      'QYlQF3eQmNxlOPfXUhWiulkD2CccxNSWCkndU+6i2LQVvBuhAPn8ya' +
      'mrffC9TVknNDdO2Vv44aWOtmbqrFhBKH0RTzY6EMFo+IWYVTj7min7' +
      'ElGbMT7EYpmshCT6JyjbnXD+O0/RZvE+tFDJY0vJ5W0GoNKWrQEgiz' +
      'DbW84D3zr5LAjUNw2/6m8KDT37ykzOtBnKazDrAu2kJh4x8O4bViC+' +
      'f+LmM1gHU4KKpHY2SlU+t99oyfTMQp2BUqNunLUEBHCm0Fph0VUal0' +
      'AHVROPTgIe+Q250GB3JEg68vLxmTYR7vSmk9K8+VA3zJ/HnPoiMSiD' +
      'NPdtvcKVVQGA7rWLJ83jmSnwQsT4fmwRbfclkQpkvA1Zs5GIz0ISAM' +
      '20zpYFY6sCZD9zXgtFSPhW4LcvCzXfsfclt4jMiDCyGcCwnfY+bw2b' +
      'KC9i/+MeY0HkvhSHnOpidexjhdZOBNXFpP8/MeVQcmV7Cf+b+PJt9z' +
      'H2JZi8nVrTwnU+ILYlT4CcQgCCBnSYeL5F8GS8UjdqqIGkxZwsthq1' +
      'lbvpjmti8XD65J2u5eA5tpwKG43LMYTJhyxRl1ATPmNqEgEX6Uf5HK' +
      'SCRU38z/OqrX/1qcy6gKXjNe9zjHuNzeC3MUNYcmfJ9OQdmod8zAFW' +
      'PAzw7wR/eVUZPRdX6vjtpwvfsbA3cl9EmCBRoYl5giPuTMiGvWa/Nd' +
      'S28Bz4/TE41k8z+4x//eOxbBANaHIbmmWhD6/5o+M2COX1REDId3zJ' +
      '9JCIIyJcJYRjy5yV5PMScEzZhell4rj/qSPok4vTzNG3U4m5ITswsz' +
      'S4Ix2sSWLAkT2kLUbRMr9Ro7kP6MlOd2pZrEEjR1JOhc3AzbVFT8ls' +
      'tVYslqjatpi39xz081tn9Wu9KTct3BGOafXkc+2AiiJS2TQnIlgBpm' +
      'aj0+U1vetPxfc5jyAQCH9+YiqYPf/jDoxb22TPiDTKHSptVFDkzBH3' +
      'DBNkybgumcpYTKzL7HtC+d3hSK++mSea5GRWsflkC4kbSwTzp5CuR3' +
      'dS+gJfCC8liBM6VGTjWYUyaWhxDzi8DPNyHY3OiZ9tkekAfmsHKRDQ' +
      '9FzPLCpoWYUN0MJXtc/hWDfxk4ChNcdvEfwgdNv+nPZp5Ca+J1mBjv' +
      'p+a2sj3QiXOxz72sfF685bjm2XuAtrMspBquQT3REhq1tsXCQT/Xe9' +
      '613H+oDTZa8DHe0IDDlGDIXnHvH/m4W2Njklk4Gu5sGLle0bMcjoK/' +
      '6ezeVgk3te//vWFpLS+hIUFBl1qQIbfFm0nsRkY4EViHjoGEq3rXD4' +
      'mxN30dzlOaSsjwyxoG0fsy8yYRBAy96Z9ORMdz8T1uBfmrG2nLSyNW' +
      'KeSTDhNpm0zQd/SKqmlayAGYocxYfS0EBAU9d60hz5D2KHB3v/+958' +
      'tl5ymI4XmHDNlfiZzzMsN8z+WBAwPEB7EGDC/KSXUauE+tAmtzXost' +
      'FXAbExVwsiY9LtFKoVkTgUXcyPx3TZxb9pAW6w55llXYka/FYvWGtC' +
      'wKqRVjWPhMx0HwcMoGUTx5UNUqVG4Loxs1DRNOiOkOPWaMQRD8B85D' +
      'x/Q6/CJX8c5vASYkRdlIIL7ck0CQdxPguU4RkL4XAZRZBT2ozWTyJk' +
      'hoGqaSrD64bbPaTMrcUtsXiM3n19zPYVDay5XzlG40Aff+973mhVBw' +
      'kHdCJ/WYkXVp02zu+4HFKxzLSwKc6Y+ewbHAD4/s7q7eJLAJGUyaQN' +
      'ySWumkHw+mZM+YYA4I4sAffOVr3xlZE78SwFNrtQSDysWEAJ0iJJfo' +
      'tU5t9SLzoHwOc6iayN3fnKcsw6w0YEQEd+zioXrcg1enOcZabPOMzW' +
      'T5qAzGHAdfV19Qdr1ta99bTR9jEwSQOClMdmUwgJNet3rXne8F58c4' +
      'z3wmTEX56FqTpkzkb8NlLU0VNWcmrZT70qB2Frevd5TzFp6r2rVqd+' +
      '8e95lFT4tJhcf+tCHhkc+8pELv+knBClauAqz1KQKYM9BCwOYkYCZj' +
      'LtaWBGzVp9SRlSaa9JpliJ5kVh0ao6FrJJWxlFDco7laPqA5vL4jxd' +
      'FR/PiMY8IYHjvDLjAmOTsIGT2wViYNJaGGWBiw6y5973vvWBeMxKDf' +
      'Whk9hFQoUIGH4rN69Oet7/97YvqO4RJts8pNbUKFBh1ztcaKQX6nKI' +
      'eV4+vFURT8LnSn2sd0xIqeWy6OR7f+j+PEbxT+lWtBzB5oSOPF9JgD' +
      'QgJaAjGdEbD+v9KYsUGW2c9o5Mqp+llGF0CdhgQTOv0jDIsHS8DsWG' +
      'mmg8D+oPc0zlauQYMxrFODlXNRv0QXiraGKZU4/pSYQ42mBazh2FSn' +
      'g+zvva1rx0FAaYP7bjb3e62QRCHlZhbS6xXcDztTm3Hb5/R9vJJ3zg' +
      'WlOdqMUOLaWZBy6QySUWODGqZsq37z/p/yhxunZf7yX3j0wv7TSHGd' +
      '9eeEZrKCcajQn9ZobZYQbWpsSLlexmoMEkvcRlV5BPGoFPROgZvDPS' +
      'k/6D/lJFeTBGYCCJVCNDxvDCL5tGYHGeCGj+0mrX4jtRh0kb+JwJsx' +
      'BY4YgPGZT6dG93oRmNQxPPvda97DR/5yEfGFAQLuxqdpT0suc58r7O' +
      'QGrL6ifrEHpcugYl67qtQ8bhZ95pCLSao5/k+Xf0sJ76u15/FVBVTA' +
      'mGWacs50EeaobgRmQ+nb/huYAlkQAjwjplqMyuufLbNjjmBWkVt5uz' +
      'mOe2G/iQbQQCYSE2qljUfyW863uJ4rg0hY9JwHc1mNgI3aBv+p+P1Z' +
      'ZSiNbDEfqQmDMHxMAcvD41rvS8SGvPViaaOPfbYceVmB0ajSYk4Mw7' +
      'ScZFsMOXrX//6mX2lj4xGpx0+v6iRR/sNjQlDytT42LRxloZO4m4xQ' +
      'pq9szSdeWjz1IvRfPXe+T3N6tyf6aPWb+gpGc0BEkZYHQyfAwjMuwu' +
      'KEECWX2rprQZWZFSKmlPt5zjCnIxJIs4lCZyVPLWtmiI1CucbOPI6a' +
      'DyYE/+Ua+D/cQ2Oh/hhTv5Pzcn1OA9wPaQsmhbmhOgxp4kiO+rB2RO' +
      'YNPmhD33ownUOPPDABV+Z+8HojIeckr4cpwkLEWF6t45N37tGbCE2K' +
      '6l4JhgUITfFoEl01SeraZlZzOlKYPQFwiHfu+21zZWhWj6l9bS6PQo' +
      'jhTgw3pDzA/PMyWjSCdfTTOWdK0R5JxmtBVgl5oazvRnvWEksG3PKe' +
      'CnprWWU+SReX6Ba0VI4o7N1gis6OX0xc4v8b7UN0VIYk//QZGg8zTC' +
      'ICEbjOJnca5kusXAAYMpyDu3h09WvYAb2nXjiiePAXiZdboH1IvFhW' +
      '+DeamiEgUGtqWNlAINqtUibPuBZ+Y9AFL5xnfoDyFD57Olq6G9ZJTP' +
      'lMyKEOIZ34jy5dTSN/eh+zs+qnSxG9zeg4oe0CK4J40x5V+Yj5/nQ3' +
      'oP3ZPrO+lzAu89oLbBUMd2xxZaGLgeWhTmVNFkUoFNtzWaOxpDIeIl' +
      'IL7QTxzuYWnPVqpDqc/IbBvIFoxExc/mPtAeaSJ8E4karwNxolppeM' +
      'MIrs1v5wjVapYB8cl1MWybBcnSFYLgVxQZTsKABM9S+SpOq9itEJTj' +
      'eKiaj04BP2oyLQD+1Vnamb62oSh9L68M1VnL+2ywatx8IrtFPtB8Bp' +
      'LZLgm6Zx1MmMwx0//vff9h1113P1pcbA+hGYcHzOM7TweCawRmNtUD' +
      'GIFdq/dXAsjCn1UCaDPolmqz6UponMh/Q+VYj8F3Jz/XYZ/5Pzcu9r' +
      'FFl2BJEx3/kEiEaV0lmg5FhXBhT4vU6AAKDmKvP5TEZnNGktkyQe1W' +
      'CQtB4jQTnaUlkMYIWhJqswsijJr7mm4PPk0HRxBBfjUjalw4kaAVgE' +
      'HDWKGdFUH0Gz3dI1ixMpW68N5FtVg2bl1+kr12UifNxBdCuvEshc6o' +
      'MZEqFmb5o9o3aVgGVU3euBpZNc2aFjBrThVFTW1YHH8bRrJqa2QBtV' +
      '0vRLEezRA7GhDjNr7pcAv9h5hp0qs6+RJ8+Hftc8MdVuXjBEIca9uE' +
      'Pf/g4iqTiQQ960HD66aePFSvAa8qA6ROqwYwuV6nN/zAN94e4jCLTN' +
      'vOfdejZVLkfgsTFfvxfLaPA4FpErLEapgBj0Pe1imhjwP323Xff4VG' +
      'PetTZ/iNWcMYZZ4wTjDHIgDxy1cy0H9eB0jvhe6XP9DN5DhfphXFrt' +
      'FaXy/4zqj/lZiw3loU5kVJKNaB2yfB0mkgyMhqQzieqasdBIDJMXj8' +
      '1Hi8CIiKlwXUYzZ8pE67l0ncyOcfh01SYwgEGjzLlwnUgSD4NQrFeJ' +
      'UzYAm1kBSyWWc9yOdqcWstnMYLY0h7c33lk6Sujswghzkdg8Xw1CMR' +
      '99FVFLZ3M/YC+4l1gqs9iTo6hcAOBt1RQ2ghzphBBcLC0H+tsgikfs' +
      '0Z0cz+bdOSwMQOUGTwUxhJUFNLqPItgubDJDWoljdFKO09zwUm3ZLi' +
      'c64VOcVS8s6nbefqknO+qV7k6NOYsxM+0jA4kttwOLeCalDkQulWaJ' +
      'kND9FwTRoDouTZCgw2tSyE016SdzDqXxHP88cdvUJ6Hmf3Upz51oT9' +
      '4bgM/7tOyoN/0g2re0EAVn9wbf5p2OD4UOFg4kVHNer1Emvf0G/lcn' +
      'n8W0Ggw8DlJNzzucY/boP94Jtb1lDFBS3tNMab7FMwGDaETo8FTZYl' +
      'VI29WmpOHw2fTzNJM0rzUGVeT5kZHkhsk0przxnp+VhMlIDwKpg381' +
      'OhaRhATRAArNF0hNrQjjGjpXp1vFRx++OELM88BCPplL3vZ+AxEcWU' +
      '05tnBPGOZ9KyWkjH1He0j+6Ai78+GT8txRJ/xl01ppB+LRbEYJBEaX' +
      'UawzKoSQkggvCxC2Fj/jOfE8ki88pWv3KC4PM3ujCjrAnDvLE0U+uQ' +
      'GeRD8ulJTEdhM80hnmw1zSjRIP4i2RuVypLxMYx4TaU201tEIGd63w' +
      'zIAIOhwI4WL7Ug6vhIF4KXB5HVoW31GsOeeew477bTTwn6eg9W1eCY' +
      'CVC960YuGpzzlKQv/s1Q6Re8UJGQ0UHNWcx+CtRQvYfDCftAvp7+4D' +
      'v4o/WellNrBIVjzkETre8lc9BSDujoZ959KGU2h5VpQyO69NC3V/s6' +
      'yl2kX2tYStDAmfQYdcq7ClWdq3Rckc65WZZBYljixAZgcHSFqoCXnp' +
      'TXPiYSjYzBNYUSCFxA7WgJCdJk6wbjDxUo578nxrbxkFs1Pnc+GNmL' +
      'pgQQSnwCG0pwVuT7xiU8s/A/TUIxgottrGUmGEGBMI7k5BAuQt/W89' +
      'OfZ0PD0lamWTBGwVPxiUIUblkOmdKaEn9HteQOUW0irw3tAN2hsl1K' +
      '0IkwtaDDOCDX9kkXvIOMUDD/zu/nOqfVr1MZOQtYyf1cKy5bEqdX8l' +
      'ZjclHwSKp0CsyHtqVslub/ddtuN35GCaJMqnTkmc4QipWAm2jmW66X' +
      '2FVmTWrWbG+YnQZ58RjTiySefvFBo70ahu4PGAcPHDjjggIVn1vTiN' +
      '8ybBFGfk2FquX6K/anLgMaXOY1MQ+DMlbMUwAS0gSlAEpVBIX7eway' +
      '8pH2pNsMMhTGrNuY3/xvYkgmdFjVHBvGdYx74wAc278dmmSbC3QnZE' +
      'GSOWGnd2wWPnXlhtbBszJmz2CVS+ra+G/jxfJlBbYrWZKrGBIEbxuJ' +
      'lTWSdbNjfJtif9rSnNdudpYVGTflu4IrtMY95zMhkAukNswJrXZ3tj' +
      'ejqkUceucE9HvzgB4/tzVXLANIf7Wd/EHxKQDi77bbbBn5q9pFtyed' +
      '4+tOfvuRgDVFgAlu3utWtzvZfMuiZZ545RlyzRtn8okxI4QfMwWfOZ' +
      'mc6J4EQSv9eS8GSTmjANBa1zORHW+A8joV2EPYwqbPxtZ7JIgUFgcG' +
      '31cKyMCed0jINq3lbv2fRQdr6agf3wZw1XQCzoQnNeTqBlYOM+U7HU' +
      '0mE+TkrSJKlcU6tYqQYjUskMUGOjZnfYEjzgybl2QgCve9979ugf1h' +
      'OTwJVYpscNxiG/+iMfwLBoLAiCKQ5j/CgXA+rg+tyTVIREO9SQXCLd' +
      'sBQrciusCkEWZkAAA8bSURBVKCctmPlwIRWadH/1kfTJ87npBZkFFB' +
      '9l6RVeNfOvJjMmOWNd7/73UehOOULG92moJ1rIGxoJ3TSSg9xT+5jJ' +
      'H+1yvbEsmlOHyw7znxn5hqFx2l61sm8DB7xHx2OP5eAUI8++uhxyBa' +
      'EmyNeOAdNt88++wwnnXTSgplGhDdNTqDWVKulxsRsw2fMZzrttNNG5' +
      'svnqWY7G8wIcQh8qoMPPnjBzEaawwRYAdyXdjEmlLRMAqGDMGCqS0v' +
      'w0AoIHTQ128477zy88Y1vXNAo5Hwrky8G+JK2X82saVqXTeT6tAXtk' +
      '0JNEz3dCi0k53NiqpEE6ZtjjjlmTNFkv5q3ZMTPa17zmrFPrRpjOF6' +
      'FNMM7lNEJxmGqtwI9WSCz2owJlqUIQXvd7yAJN49Ls4UtTTSPz8iuZ' +
      'utRRx01miYwpcBsYUQIGxrMgdqmdRIQMQEdtAvaUMD4VtlUbYGUzgA' +
      'GzHPYYYctBHNSIGV0k++0h2PR2u4nvcLUJjA4+9C+aQbSfnxZtmwjT' +
      'Ln33nuPm5rI6UNrmonnwce9733vu0Ff5TuwjfnbfubamLa0lYm88p3' +
      'ks77jHe8YHv3oR49Ck3NlzDo8sNWvpJ7Q8DnhGIUNDMeDofARHTiPD' +
      '12H0R1xxBFjH+6xxx4b7LfUE0EHTXEeFUIMBki3JLEWmFJscs2ZCeF' +
      'krBbUbDmzuxMnY1IRpXXWgjo6gP0QHWZRC9bQou0qYyI90aL4g7yo2' +
      'ibam74rG7Mc1OguzGbVTyWY1ie+2Zve9KYNroEggeBcKgChwfMz6Je' +
      'gBe0/4YQTFsr/Kpx3ieMqY6IxKStkxgYYvyL7paZ1IGYIG+ZGK2Jt1' +
      'MJ3oLZxVW/6g7YguNLEz4mwK3jP9EOuyC0w0Ul7wKwIl+xn+gzhyrQ' +
      'i3C9nxJM5NU85j3Y4f29rcrO1hmWpEKqpjhaDTuUPIQaIEqYjCkjFD' +
      '4TJp7O9ax6RWnnIQx4yrtORJuMUuC4mIaMeIDiIMFfCAubTMjpLDg0' +
      'TNPHmN795ZCCLJ6aCLvmcHIvGzygszEX1EAyGOW5QAmLGL6ICB+Kio' +
      'IG5hxBa8wCjYA7jG1upVIVYy6VQCzrUivfInLQGnhwo71Yn7Wb0DSZ' +
      'nTgq9mIAKx2Id3O9+9xtznPPqdLkn7SKwhktBv2JNOFha2E7eoQEmX' +
      'Bn6ujVSZ61hq409Ydttt935fw97yuQFt9pqodiaTth///1H5pkHip5' +
      'f9apXjRqPDgQ1aJSSvhUEwFfBXMH/gtBhGAjVqfbRmE42nFOjmH5Qc' +
      '3kvPz0mNbczFTjHENq9CqXaL/pnaBZnX+caLgbMJ8dAOAgNhATtIU1' +
      'BUT39Skkbvh3BH4IuXIvn5TmJ7iJ0eF4rm9BeakVnEgS0dbFpAnxY7' +
      'oUWQyi1nk1gTSBIeJ4vfOELzeNa7y5TV9ANLgu+tFF6+h4BTL8QEOS' +
      '71WOAfqUfMI2dscLJ3YweA4SbprG12M5VTL8tZn6npeB/bd3lf20/d' +
      'WPOWZYKoSzcrgGhRIvZqi9T/beKTMijHZyjx7whxJnDrAzAWLdbc1n' +
      'p//o9Z3xPU5XfWgqL1RAOcYN4LHeDaSwVRDihqWFIGM2ILmYbaRICR' +
      'mgJCApCo+AcwYMJC3PrE+cAZoXIUudbdYYAGKbFnAlMTJiZ4gva3po' +
      'ZPfPHOTTOfofx8GEXY215PZ4PQWZ6xpFQ9DWCxelQYUL6AeFG/7LfZ' +
      'T/mCf+VxrJEa7X3QUrLOkax5ZPmMfquGTjKa3qNRAaYYCjTCrYp25H' +
      '1vnlMRoin7pVBKqPQ816o96y+m2acEzNjwuMLI9nxtdAA+MdIdofjo' +
      'SVgboSRkVj/q2t7nhPQZrQVzIk5zlom9f8EZrTpE4of9GONfFuCl0L' +
      'SJStMP9XlEY1HGKOokXyABYH1RVxgxx13XGBYl53A+qEfdVsMWNYpT' +
      '+fFSVYSKzb7HkgGy/3525ErKVUrg6dWa10HqJU43kWO0OZEQSEe0yV' +
      'Z9ufLyZefL6pFFLYLLTZvrRDblYICmHbIPoLhIEiCIfjKpFowXZH6+' +
      'HSYfmhsAxsWACSz11xxbfO87wm0Jwx0z3vecxQe9bp53stf/vLRvCV' +
      'tRd6TIXzmvecR/az/W24O/Yjpy5A9+mSHHXYY788nLgBt1qy1ggl64' +
      'Dw0rGV8SW91oP1qYVmYsxVsqOH3/AR2jCHvnCzZY3NTq6amrR3pYGa' +
      'rcGAAgixE9XxhSnPL3bLQOquFnLUgtWy2B0ZgCNc84koTO/d5TYnEt' +
      'Vn8H+Y0OY4Zy/GYsDyf7RZOD+p167uoQnJWewUa8S53uctojuP/tqK' +
      '/9gna6A1veMNYzUTghneZDL2pwP3op4c97GELa7u88IUvHDUo8wdTa' +
      'URfIewRYs5syG/cAMcc15LP/FxNbDLmbGnBWZoNVMJhwwxFu+UitH7' +
      'XxGldrxJe/s4JpnL59Hq+JleV8Kmlc4CuL1ZtNU8r2KasA5axvabrf' +
      'UJMaHjn6OE4AiQQG+fDHA4SwEdLArPCqtYtp2CpmPWubDf3YYk9alk' +
      'pC8z/qi9O9JXgHBN0OQiCyPOmBM//iEc8YhRSDDU85JBDRuuFGmdGD' +
      'BE9phoIq8b2WWWkL2z0v/q/6545N/YlTx2THWFZWi5IqxaVSbP6pC4' +
      'kWwfLtsxiGaIKEEvDnJUutdlU+7NQfh70cWU2x6bmrOma406LQjqJZ' +
      '0Tiw6wwKASGBsMPhfjwqdSc2VfZzhQotmWx8By0HyNcSCGhFUljVL/' +
      'c49lH1JZ0DgwKE6DdmPBsY/y5qX6F8RESCnMZExDNhWHxeVltDGGFa' +
      '4AJaz9Z6VRpZC1hycw5K5K2sU51BmLwAzA7CY5gTuYSC9a8VobNOtg' +
      'sHZNAUwhA9L6Imk+TQas53fru79RMi0EyheZznQwNqU4li0ssmAKB0' +
      'EgXkQrA98QvhTCdvIpruTJbHepU/fS6v+5rgf0EoLgH067QHlNKU9c' +
      'g30qpIYzCYHOqmjivFn+AKRM8P+kzqp3wZZ06hknBTJEJqplY3AjTV' +
      'oEH865lZqxYEnPOY8zElBlV4XG8eF6cZoZ+X/qGTuqcy6jnMCKIHaK' +
      '2aNolHbh+rnJWA04t7Zttr+H2ihzKlcjgRc4W4Cdt4lkcqwgIbpjLI' +
      '32i0OJ/0iz4zjAoEVSYlf+4NmawAiv7Np9l1vubByK3CA60IlU9VOj' +
      'kM7aAhuU8TFCqlViyD2YiDyqzVwunfkfrsu7mLW95y/Hdkx7hfOqpQ' +
      'Ub2bQv1uQx2h55o80oseLspsUkDQosxaecB5sMMmrq2kABbL9LhXkb' +
      'nMgSfbUTqO+tCMuWUGTtP4qqZvWcNzee5msTJqPqaaAQYjeisSXgIj' +
      'Od15TN8LNtPot1RLY7qmRIwmwKUIVIJRDSUMsjXve51C/dpWReAYBk' +
      'ajoouIqkwKxYB10GgYJojbEylWD4IE5I2QljRt84S8dKXvnSDdWemL' +
      'BynJl1MJH2tYZMy52K1ZAsQJtVEYGOusbH306fE7IHYIWyS/4lZDNi' +
      'yDPK/JNApLeqnZjpE52K8aFE1JcyGbwUhW33EORAcTGtlEIQHMeNXa' +
      'T0sFVPPnvsRAASmaCdtQBOyqNMUY/qdZyaKijVE6Z2Dx7EQ9Ju1lrS' +
      'IeFcwJoyKn00RBFONLqbNLEHPu+V4mHq9YdlGpWwsXv3qVw8rCdY2Y' +
      'XVpR8YnllM4GBRSqzrhNRoDTe9Cv5ipBIDY3xIe5B1JHZgOgMD5XOw' +
      'A4SlN04pWtsxNGAVTk0og5ovF5MSnbF2zgudT2/KMFA2QzzV15NIOX' +
      'IPnYUgZUeI60km0NDbtQfC++MUvHoNm66GWtmJJzFk1ZP4+J9pzJXH' +
      'ccceN0psRHyuJ1K6ati7Hj/agdA9mhHmpoSUNUP1Hl+BHmxLZdo2Vn' +
      'EEiMS/Qstjv9VzaRs6ThZwYroV2Y31Ln3MxQhqtT8qjdezU+bMCUOy' +
      'nSghNyRIZaHdnl1hvWHL53jwTZq1Hw2AKJLUjXZYTNSCTgSUjxPqoB' +
      'HrQHDCmU2WkiWcwzKIIGNNJyWqNsEGvqalbctB0bq6gVr97Tk79gsm' +
      'NsMDchCH22muvuf3RiuTPSsttDHbffffR5McnxWyeNRhhrWPZfM71E' +
      'Kp2KFSt2NnUyHSL18+8o5NZuX4J+wyAEPghyihDy1wcQwDFwFidPb5' +
      'Go/0Nct+UphSzhLCAQTGxGe5FLfChhx46mpOaptkHqfVaQbJ6jyl6q' +
      'ufy/aCDDhr9S+IJWBTVHVhvOEfM2TJha+RzNacWnIfWi9+YYNBS7uf' +
      '1rQrKwnnXjHShX9eEwXfKNJEjLGpe1oBK1ohWBs1nbDFj6/diQEoHf' +
      '5kIKikOZnHHH805aDMYNsWgS9lH0AyzGjMW14C0yXrWmOIca86WxmH' +
      'fWpruYQqmMWb5R/W5ZmEW86av6ZZDuozcom00J52CRLO0Xk8GTdO1x' +
      'ZCbkglnAZMc4UIEl0Ht22+//ZhnxCeFYatGPKcMivlPUQPR6/32229' +
      'hacfNgTHBJjNrK5NC+LwoTB7rG9caNBNnMeemDnDVXHDeV4FmbWzOb' +
      'p7DpywzNO1gsX4tqshnWCm4xgo+M7lNyvWYdI0xnqR6mM1B33ipDIp' +
      'mxLclUs06NeyjrNGVvTcXbPRbmzcTApDw6DDsfpYhIBxOMfRaAfPGQ' +
      'sxOhzKPAc/J/1P/zYpwTxFtiyHTl1xLoH2mRdBuRE4Z2kUAyQohCvi' +
      'zQKD1DPjfzMSH+YpVQRqJidJcEpE+WutMuZSZEJaFOYVTQcCgrFRM2' +
      'H05CWixGs5ZAYgwYga1pkVZyrXPKYNXpO+YPuRaGz0xDzAR9b9oNnx' +
      'C18YkF8ngbfK7OfDdkT740jAjOV9m12NUC/vxwTmH7+ulJG/NMSeAQ' +
      'ZkSglKztSTdeOmMXGCc4jzNtpyo0dJZ0dP1wIjzkIsP07+Mq5w3lxE' +
      'pL0sUraZay4HGFtbEHEIV+EhqJpPnS8lptXJjU+cv5jircHImhHPiX' +
      '85jnNb/q+kbrhaqpsPURas6yD6X7DNFBCNTSGCAbEvBNsMKYmNWCN4' +
      'UaYt5aKVQFsNEi8GWwGibAvib67EofSWwosy5MVgqcS+lCKJGmjtjd' +
      'XR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR' +
      '0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0rHkMw/8D5b0CzunNF' +
      'fAAAAAASUVORK5CYII=' 

    }
  };
  this.pdfDocGenerator = pdfMake.createPdf(this.dd, 'Luxucar_cli_' + this.compania.id);
}
//Finalizado-Por ruta tarifa Sin Iva para la unidad
//Finalizado-Por tiempo tarifa Sin Iva para la unidad
//Anulado tarifa Sin Iva para la unidad
getCalc(ser:Servicio,esParada:string):number{
  //console.log('|ooooooo getCalc  ooooooooo|>>>>>>'+ser.idservicio);
  if (ser.estado == this.global.ESTADO_FINALIZADO) {
    if(ser.tarificacionPorTiempo==0){
      let a = Number(this.getCalc1(ser,esParada));
      return  a;
    }else{
      let a = Number(this.getCalc2(ser,esParada));
      return a;
    }
  }else if(ser.estado == this.global.ESTADO_ANULADO){
    //Ver tiempo de  Anulación
    //console.log('|ooooooo ESTADO_ANULADO  ooooooooo|>>>>>>'+ser.idservicio);
    let iva = +this.propietario.iva;
    let tiempoMaxAnulacion = +this.propietario.tiempoMaxAnulacion;
    let tarifaAnulacionSinIva=this.round1(+ser.tarifaAnuCon/(1+(iva/100)),1);

    let tFR = moment(ser.tiempoFinRuta,['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).toDate().getTime();
    let fFR = moment(ser.FechaDeRecogida,['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).toDate().getTime();

    let dif = this.round1(((fFR- tFR)/60000),1);
   
    if (dif<tiempoMaxAnulacion) {
        return Number(tarifaAnulacionSinIva);
    }else{
      return 0;
    }
  }
}
//Finalizado-Por ruta tarifa y paradas
getCalc1(servicio:Servicio,esParada:string){

  let a = 0;
  let b = 0;
  let iva = +this.propietario.iva;
  let tarifaParadaSinIva=this.round1(+servicio.tarifaParadaCon/(1+(iva/100)),1);
  let tarifaSinIva=this.round1(+servicio.tarifaConductor/(1+(iva/100)),1);
  let tarifaAnulacionSinIva=this.round1(+servicio.tarifaAnuCon/(1+(iva/100)),1);


  if (esParada=='1' && servicio.parada && ((+servicio.tiempoDeEspera)>=(+this.propietario.tiempoParadaCortesia))){
    a = ((tarifaParadaSinIva)*(+servicio.tiempoDeEspera))/60;
    a= this.round1(a,1);
  }else{
    a=0;
  }
  
  if (servicio.estado== this.global.ESTADO_FINALIZADO) b= tarifaSinIva;
  else b = tarifaAnulacionSinIva;

  if(esParada =='1')return a;
  else return b;
}
//Finalizado-por tiempo tarifa * minutos diff
getCalc2(servicio:Servicio,esParada:string){

  let iva = +this.propietario.iva;
  if(esParada=='0'){
    let tarifaSinIva=this.round1(+servicio.tarifaConductor/(1+(iva/100)),1);
    let val = this.getCant(servicio,esParada);
    return this.round1((tarifaSinIva*val),1); 
  }else{
    let tarifaSinIva=this.round1(+servicio.tarifaParada/(1+(iva/100)),1);
    return this.round1((tarifaSinIva),1);
  }
}
//Finalizado-Por tiempo tarifa Sin Iva para la unidad
getCalc3(ser:Servicio,esParada:string){
  if (ser.estado == this.global.ESTADO_FINALIZADO) {
    if(ser.tarificacionPorTiempo==1){
        // quito ivas y busco la diff en minutos para la base
        let iva = +this.propietario.iva;
        let tarifaSinIva=this.round1(+ser.tarifaConductor/(1+(iva/100)),1);
        let val = 1;
        return this.round1((tarifaSinIva*val),1); 

    }else{
      //console.log('|ooooooo getCalc3 2 ooooooooo|>>>>>>'+ser.idservicio);
      return this.getCalc2(ser,esParada);
    }
  }else{
    //console.log('|ooooooo getCalc3 3 ooooooooo|>>>>>>'+ser.idservicio);
    return this.getCalc(ser,esParada);
  }
 }

 getCant(ser:Servicio,esParada:string):number{
  let val =1;
  return val;
}
//calcula el numero de unidades de una dispo
getCantDispo(ser: Servicio): number {
  let val = 1;
  if (ser.tarificacionPorTiempo == 1){
    //if (ser.idservicio=='2084') console.log('>>>>>>'+2084);
    let init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
    let finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
    let dif = 0;
    if(init && finn){
      dif = this.round1(((finn - init) / 60000), 1);
      val = this.round1(((dif / 60)), 1);//val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);
    }  
    if (val < 1) val = 1;
    if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
        val = Math.trunc(val)+1;
    }else{
      val = Math.trunc(val);
    }
  }
  return val;
}
//tarifa para la dispo, es extra
getBaseDispo(ser: Servicio){
let iva = +this.propietario.iva;
return this.round1(+ser.tarifaParadaCon / (1 + (iva / 100)), 1);
}

//calcula el valor de una dispo que ha superado el tiempo minimo
getValDispo(ser: Servicio): number {
let val = 1;
let tarifaParada=0;

//if (ser.idservicio=='2084') console.log('>>>>>>'+2084);
let init = moment(ser.tiempoInicioRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
let finn = moment(ser.tiempoFinRuta, ['YYYY-MM-DD HH:mm:ss Z', 'DD/MM/YYYY HH:mm']).toDate().getTime();
let dif = this.round1(((finn - init) / 60000), 1);//en minutos

// si es menos del tiempo de ruta se cobra una unidad
// si se supera, se estima el valor
val = this.round1(((dif / 60)-(+ser.tiempoTH )), 1);

if (val < 1) val = 1;
if ((val-Math.trunc(val))>=(+this.propietario.redondeoAlza)){
    val = Math.trunc(val)+1;
}else{
  val = Math.trunc(val);
}
  //para dispos la tarfifa extra es la tarifa parada
  let iva = +this.propietario.iva;
  tarifaParada = this.round1(+ser.tarifaParadaCon / (1 + (iva / 100)), 1);

return (val*tarifaParada);
}
round1(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
}