import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { UsuariosService } from '../servicios/usuarios.service';
import { Propietario } from '../comun/propietario';
import { AuthService } from '../servicios/auth.service';
import { ScomunService } from '../servicios/scomun.service';

@Component({
  selector: 'app-cronser',
  templateUrl: './cronser.component.html',
  styleUrls: ['./cronser.component.css'],
  providers: [UsuariosService,AuthService,ScomunService]
})
export class CronserComponent implements OnInit {
  @Input() cronserVis: boolean;
  @Input() prop: Propietario;
  intervalId :any;
  propietario: Propietario;
  tkn: string;

  constructor(private usuariosService: UsuariosService,public authService: AuthService,public scomun: ScomunService) { }
  ngOnInit() {
    this.tkn = localStorage.getItem('tkn');
    this.inicio(this.prop?this.prop.timeReNew:1800);
    
  }
  inicio(timeReNew:any){
    //if(localStorage.getItem('debug')==='1') console.log("::::::::::::::::::::::::::::>>>>>>>>>>>>>>> INICIO CRONTOL TKN "+timeReNew);
    this.intervalId=setInterval(() => this.reNewTkn(), 1000 * timeReNew * 1);
  }
  ngOnDestroy() {
    //if(localStorage.getItem('debug')==='1') console.log("::::::::::::::::::::::::::::>>>>>>>>>>>>>>> FIN CRONTOL TKN");
    clearInterval(this.intervalId);
  }
  reNewTkn () {
    let tipo = localStorage.getItem('tipo');
    this.usuariosService.getTkn(tipo).subscribe(result => {
      for (let res of result){
        if(localStorage.getItem('debug')==='1') console.log("CronserComponent::tknold="+this.tkn);
        if(localStorage.getItem('debug')==='1') console.log("CronserComponent::tknew="+ res.tkn);
        if(res && this.tkn !== res.tkn) {
          if(localStorage.getItem('debug')==='1') console.log("CronserComponent::tknew="+ res.tkn);
          this.tkn = res.tkn;
          localStorage.setItem('tkn',res.tkn);
        }else{
          if(localStorage.getItem('debug')==='1') console.log("CronserComponent::identicos");
        }
        break;
      }      
   });
  }
}
