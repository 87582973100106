import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-crud-cargas',
  templateUrl: './crud-cargas.component.html',
  styleUrls: ['./crud-cargas.component.css']
})
export class CrudCargasComponent implements OnInit {
  @Input() cargasVis: boolean;
  @Output() cerrarCargasEvento = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
