export class Ciudad {
    id: string;
    nombre: string;
    estado:string;
    visible: boolean;
    fechaAlta: string;
    fechaBaja: string;
    latitud: string;
    longitud: string;
    zoom: string;
}
