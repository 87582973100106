import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../servicios/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { PreguntasControl } from '../comun/preguntas-control';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Global } from '../comun/global';

@Component({
  selector: 'app-recordar-contrasenia',
  templateUrl: './recordar-contrasenia.component.html',
  styleUrls: ['./recordar-contrasenia.component.css'],
  providers: [AuthService, ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class RecordarContraseniaComponent implements OnInit {

  pregunta: string="Cargando la pregunta, por favor espere unos segundos";
  respuesta: string;
  respuestaU: string;
  correo: string;
  preguntasControl: PreguntasControl[] = [];
  global:Global = new Global();
  ok:any;


  constructor(public authService: AuthService, private router: Router, private confirmationService: ConfirmationService) {

  }

  ngOnInit() {
    this.getPreguntasControl();
  }

  getPreguntasControl() {

    this.authService.getPreguntasControl().subscribe(
      result => {
        this.preguntasControl = result;
        if(localStorage.getItem('debug')==='1') console.log("result = " + JSON.stringify(result));
        this.preguntaAleatoria();
      });
    return false;
  }
  preguntaAleatoria() {
    let max: any;
    if (this.preguntasControl && this.preguntasControl.length > 0) max = this.preguntasControl.length - 1;
    else max = 0;
    this.pregunta = "";
    this.respuesta = "";
    let a = Math.floor(Math.random() * (max - 1 + 1)) + 1;
    if(localStorage.getItem('debug')==='1') console.log("Math.floor(Math.random() * (max - 1 + 1)) + 1 =" + a);
    if(localStorage.getItem('debug')==='1') console.log("this.preguntasControl[a] =" + this.preguntasControl[a]);
    let p: PreguntasControl = this.preguntasControl[a];

    this.pregunta = p.pregunta;
    this.respuesta = p.respuesta;
    if(localStorage.getItem('debug')==='1') console.log("this.pregunta =" + this.pregunta);
    if(localStorage.getItem('debug')==='1') console.log("this.respuesta =" + this.respuesta);
  }
  iniciarRecuperacion() {
    
    if (this.respuestaU == this.respuesta && this.correo) {
      let msg = "En breve recibirá en su buzon de correo electrónico, las instrucciones para recuperar su acceso. Gracias"

      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.getUrlTkn();
          this.router.navigate(['/']);
        }
      });
    } else {
        let msg = "Rellene correctamente los campos pedidos";
        this.pregunta = "";
        this.respuesta = "";
        this.confirmationService.confirm({
          message: msg,
          accept: () => {
            this.router.navigate(['recordarContasenia']);
          }
        });
    }

  }
  getUrlTkn(){
    this.authService.setUrlTkn(this.correo).subscribe(result=>{
      if(result){
        if(result[0]&& result[0].error){
          if(localStorage.getItem('debug')==='1') console.log("Error::>>"+result[0].error);
        }else{
          for (let r of result){
            
            const datos={'from':"noReply@luxucar.es",'to':this.correo,'subject': "Restablezca su contraseña",
                         'url':this.global.SERVIDOR_ADM_URL+this.global.RECUPERAR_CONTRASENIA_URL,'tkn0':r.tkn,'correo':r.correo};
            this.authService.sendEmail(JSON.stringify(datos)).subscribe();
            break;
          }
        }
      }          
    });
  }
}
