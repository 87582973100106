import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { SstService } from '../../../servicios/sst.service';
import { Servicio } from '../../../comun/servicio';
import { Categoria } from '../../../comun/categoria';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { CatOfTds } from '../../../comun/cat-of-tds';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-crud-tipodeservicio-list',
  templateUrl: './crud-tipodeservicio-list.component.html',
  styleUrls: ['./crud-tipodeservicio-list.component.css'],
  providers: [SconductoresService,SstService]
})
export class CrudTipodeservicioListComponent implements OnInit {
 
  @Input() tipodeservicioListVis: boolean;
  @Input() detalleTipodeserviciovisible: boolean;
  @Output() cerrarTipodeservicioListEvento = new EventEmitter();
  @Input() tipodeservicioVis: boolean;
  @Output() cerrarTipodeservicioEvento = new EventEmitter();

  tipodeservicios: TipoDeServicios[];
  categorias:Categoria[]=[];

  selCategoriasHTML:SafeHtml[] = [];
  selCategorias:string[] = [];

  message: string[];
  msg: string;

  constructor(private sconductores: SconductoresService, private sst:SstService,
              private sanitizer: DomSanitizer) {
    this.message = [];
  }

  ngOnInit() {
    this.tipodeservicioListVis = false;
    this.detalleTipodeserviciovisible = false;
    this.getCategorias();
    
  }
  getCategorias() {
    this.sconductores.getCategorias(null).subscribe(result => {
      this.categorias = result;
      this.getTipodeservicios();
    });
  }
  getTipodeservicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipodeservicios = result;
      for(let tds of this.tipodeservicios){
        this.getCats(tds.id);
      }
    });
  }
  getCats(idTds:string){
    const config = JSON.stringify({ 'tabla': 'tds2cat', 'conEstado': '0' });
    const filtro = JSON.stringify({ 'filtro': 'idtds', 'valor': idTds , 'traza':'0'});
    
    let catOfTds:CatOfTds[];
    let str="";


    this.sst.lista(config, null,filtro).subscribe(result => {
      catOfTds = result;
      let scat='';
      for ( let cat of catOfTds){
            for (let ca of this.categorias) {
              if (ca.id == cat.idcat) {
                str +="<li><b>cat:</b>"+ca.nombre+"</li>";
                if(scat=="") scat+=(ca.id);
                else scat+=('-'+ca.id);
                break;
              }
            }
      }
      this.selCategorias[idTds]=scat;
      //if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>'+scat);
      this.selCategoriasHTML[idTds]=this.sanitizer.bypassSecurityTrustHtml(str);
    });
  }

  actualizarLista() {
    // if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>');
    this.ngOnInit();
  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizarLista();
  }

 }
