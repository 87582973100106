import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { StarifasService } from '../../../../servicios/starifas.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Preferencia } from '../../../../comun/preferencia';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PreferenciasPeticion } from '../../../../comun/preferencias-peticion';

@Component({
  selector: 'app-detalle-estados',
  templateUrl: './detalle-estados.component.html',
  styleUrls: ['./detalle-estados.component.css'],
  providers: [StarifasService]
})
export class DetalleEstadosComponent implements OnInit {
  @Input() estado: Estados;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarEstadoListEvento = new EventEmitter();

  detalleEstadosvisible: boolean;
  estadoNuevo: Estados;

  constructor(private starifas: StarifasService) { }

  ngOnInit() {
    this.estadoNuevo = new Estados();
    Object.assign(this.estadoNuevo, this.estado);
  }

  actualizaEstado() {
    this.estadoNuevo.visible = false;
    this.estado = this.estadoNuevo;
     this.starifas.upEstado(this.estadoNuevo).subscribe();
     this.cerrarDetalleVisEvento.emit();
     this.actualizarEstadoListEvento.emit();
  }
}