import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Acontecimiento } from '../../../comun/acontecimiento';
import { Horarios } from '../../../comun/horarios';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';
import { Console } from '@angular/core/src/console';

@Component({
  selector: 'app-crud-horarios',
  templateUrl: './crud-horarios.component.html',
  styleUrls: ['./crud-horarios.component.css'],
  providers: [SempresasService,
              SconductoresService]
})
export class CrudHorariosComponent implements OnInit {
  @Input() horarioVis: boolean;
  @Output() cerrarHorarioEvento = new EventEmitter();

  horarioNuevo: Horarios;
  conductores: Conductor[];
  acontecimientos: Acontecimiento[];
  message: string;

  constructor(private sempresas: SempresasService, private sconductores:  SconductoresService) { }

  ngOnInit() {
    this.horarioNuevo = new Horarios();
    this.horarioVis = false;
    this.horarioNuevo.fechaIni = moment().format('DD/MM/YYYY');
    this.horarioNuevo.fechaFin = moment().format('DD/MM/YYYY');
    this.horarioNuevo.horaIni = moment().add(1,'hours').format('HH:mm');
    this.horarioNuevo.horaFin = moment().add(2,'hours').format('HH:mm');
    this.getConductores();
    this.getAcontecimientos();
  }
  getConductores() {
    this.sconductores.getConductores(null).subscribe(result => {
      this.conductores = result;
    });
  }
  asignoConductor(idConductor: string) {
    this.horarioNuevo.idConductor = idConductor;
  }
  getAcontecimientos() {
    this.sempresas.getAcontecimientos().subscribe(result => {
      this.acontecimientos = result;
    });
  }
  asignoAcont(idEventualidad: string) {
    this.horarioNuevo.idEventualidad = idEventualidad;
  }
  new() {
    const now = moment().format('YYYY-MM-DD');
    const ini = moment(this.horarioNuevo.fechaIni, ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('YYYY-MM-DD');
    const fin = moment(this.horarioNuevo.fechaFin, ['YYYY-MM-DD HH:mm:ss Z','DD/MM/YYYY HH:mm']).format('YYYY-MM-DD');

    if ((moment(ini).toDate().getTime() >= moment(now).toDate().getTime()) 
    && (moment(fin).toDate().getTime() >= moment(ini).toDate().getTime())) {
      this.horarioNuevo.visible = false;
      this.horarioNuevo.fechaIni = ini;
      this.horarioNuevo.fechaFin = fin;
      this.horarioNuevo.horaIni = moment(this.horarioNuevo.horaIni, 'HH:mm').format('HH:mm');
      this.horarioNuevo.horaFin = moment(this.horarioNuevo.horaFin, 'HH:mm').format('HH:mm');

      this.sempresas.setHorario(this.horarioNuevo).subscribe();
      this.cerrarHorarioEvento.emit();
    }else {
      this.message = '¡¡Atencion, solo pude añadir una eventualidad a futuro correcta!!';
        setTimeout(function() {
          this.message = null;
        }.bind(this), 2500);
    }
  }
}
