import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Empresas } from '../../../comun/empresas';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-empresa-list',
  templateUrl: './crud-empresa-list.component.html',
  styleUrls: ['./crud-empresa-list.component.css'],
  providers: [SempresasService]
})
export class CrudEmpresaListComponent implements OnInit {

  @Input() empresaListVis: boolean;
  @Input() detalleEmpresavisible: boolean;
  @Output() cerrarEmpresaListEvento = new EventEmitter();

  empresas: Empresas[];
  message: string[];
  msg: string;
  idCiudad: string;

  constructor(private sempresas: SempresasService) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.empresaListVis = false;
    this.detalleEmpresavisible = false;
    this.getEmpresas();
  }
  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  borraEmpresa(id: string) {

    this.sempresas.borraEmpresa(id).subscribe(result => {
      this.msg = result.message;
    });
    //if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>' + this.msg);
    if (this.msg === 'OK' ) {
      this.message[id] = '¡¡Atencion, ha inactivado la empresa!!';
    }else if (this.msg === 'NOK' ) {
      this.message[id] = '¡¡Atencion, se ha producido un error!!';
    }else {
      this.message[id] = this.msg;
    }
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 1500);
    this.msg = '';
    this.actualizarLista();
  }

  actualizarLista() {
    //if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>');
    this.ngOnInit();
  }
}
