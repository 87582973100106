import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Global } from '../comun/global';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class SfactService {
  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios por cliente----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setCliFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/cliFechFacI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/cliFechFacI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  getCliFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/cliFechFacS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/cliFechFacS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios por conductor----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setCondFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/condFechFacI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/condFechFacI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  getCondFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/condFechFacS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/condFechFacS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios por comercial----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setComFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/comFechFacI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/comFechFacI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  getComFact(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/comFechFacS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantFacturacion/comFechFacS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getEmpXCom(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }

  getEmpFullXCom(comercial: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empFull2comS.php?tkn=' + (JSON.stringify(this.tkn))
      + '&comercial=' + comercial);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/empFull2comS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + comercial);
  }

  setEmpXCom(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }

  delEmpXCom(datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + datos);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantEmpresas/emp2comD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + datos);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Empresas para facturar por fecha ----------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getEmpresasAfacturar(ser: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactEntreFech.php?tkn=' + (JSON.stringify(this.tkn))
      + '&ser=' + ser);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/ser/serFactEntreFech.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&ser=' + encodeURIComponent(ser));
  }
}
