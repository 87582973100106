import { Injectable, NgZone } from '@angular/core';
import { Inject } from '@angular/core';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Global } from '../comun/global';
import { HttpClient, HttpHeaders } from "@angular/common/http";

interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

@Injectable()
export class SstService {
  headers = new HttpHeaders();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  global: Global = new Global();
  carpeta: string = '/std/';
  recognition: any;

  constructor(private http: HttpClient, private zone: NgZone) {

    this.headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    //this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Servicios standart -----------------------------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  lista(config: string, datos: string, filtro: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');

    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'listar.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + config : '')
      + (datos ? '&datos=' + datos : '')
      + (filtro ? '&filtro=' + filtro : ''));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'listar.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : '')
      + (datos ? '&datos=' + encodeURIComponent(datos) : '')
      + (filtro ? '&filtro=' + encodeURIComponent(filtro) : ''));


  }

  actualizaBorrado(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'actualizarBorrado.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + config : '')
      + (datos ? '&datos=' + datos : ''));


    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'actualizarBorrado.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : '')
      + (datos ? '&datos=' + encodeURIComponent(datos) : ''));

  }


  nuevo(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'nuevo.php?tkn=' + (JSON.stringify(this.tkn))
      + '&config=' + config + '&datos=' + datos);


    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'nuevo.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&config=' + encodeURIComponent(config) + '&datos=' + encodeURIComponent(datos));

  }


  actualiza(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'actualizar.php?tkn=' + (JSON.stringify(this.tkn))
      + (config ? '&config=' + (config) : '')
      + (datos ? '&datos=' + (datos) : ''));


    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'actualizar.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (config ? '&config=' + encodeURIComponent(config) : '')
      + (datos ? '&datos=' + encodeURIComponent(datos) : ''));

  }


  borra(config: string, datos: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'borrar.php?tkn=' + (JSON.stringify(this.tkn))
      + '&config=' + config + '&datos=' + datos);


    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.carpeta + 'borrar.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&config=' + encodeURIComponent(config) + '&datos=' + encodeURIComponent(datos));

  }

  //////////////////////////////////////////////////////////////////////////////////
  ////               reconocimiento de voz                             ////////////
  /////////////////////////////////////////////////////////////////////////////////
  startRecord(idioma: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    return Observable.create(observer => {

      const { webkitSpeechRecognition }: IWindow = <IWindow><any>window;

      this.recognition = new webkitSpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;

      this.recognition.onresult = e => this.zone.run(() => observer.next(e.results.item(e.results.length - 1).item(0).transcript));
      this.recognition.onerror = () => observer.complete();
      this.recognition.lang = idioma;
      this.recognition.start();

    });
  }
  stopRecord() {
    this.recognition.stop();
  }

}
