import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Cliente } from '../../../../comun/cliente';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../../comun/empresas';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-cond-pref',
  templateUrl: './cond-pref.component.html',
  styleUrls: ['./cond-pref.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SempresasService, SconductoresService,ConfirmationService]
})
export class CondPrefComponent implements OnInit {

  @Input() cliente: Cliente;
  @Input() conductores: Conductor[];


  conductoresSelect:Conductor[] = [];
  selectedCond:Conductor;

  constructor(private sempresas: SempresasService, private scon: SconductoresService, private confirmationService: ConfirmationService) { }


  ngOnInit() {
      this.getCondutoresPreferidos();
  }

  getCondutoresPreferidos() {
    this.conductoresSelect = [];
    const cli = ({ 'idCliente': this.cliente.id.toString() });
    this.sempresas.getconductores2Cliente(JSON.stringify(cli)).subscribe(result => {
      const res = result;
      for (const r of res) {
        r.conductores=''+r.conductores;

        if (r.conductores && r.conductores.length>0 && r.conductores.indexOf('-') > 0) {
          const spl = r.conductores.split('-');
          for (const s of spl) {
            for (let c of this.conductores) {
              if (c.id == s) this.conductoresSelect.push(c);
            }
          }
        } else if (r.conductores && r.conductores.length>0 && r.conductores.indexOf('-') == -1) {
          for (let c of this.conductores) {
            if (c.id == r.conductores) this.conductoresSelect.push(c);
          }

        }
      }
    });

  }
}
