import { Component } from '@angular/core';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Estados } from '../../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit {

  @Input() usuarioVis: boolean;
  @Output() cerrarUsuariosEvento = new EventEmitter();
  altaUsuarioConductorVis: boolean;
  altaUsuarioAdminVis: boolean;
  altaUsuarioClienteVis: boolean;
  altaUsuarioGestorVis:any;
  altaUsuarioComercialVis:any;
  listaUsuariosGestorVis:any;
  listaUsuariosComercialVis:any;



  constructor() { }

  ngOnInit() {
  }

}
