import { Component } from '@angular/core';
import { SsesionService } from '../../../../servicios/ssesion.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Estados } from '../../../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lista-usuarios-admin',
  templateUrl: './lista-usuarios-admin.component.html',
  styleUrls: ['./lista-usuarios-admin.component.css'],
  providers: [SsesionService]
})
export class ListaUsuariosAdminComponent implements OnInit {
  @Input() listaUsuariosAdminVis: boolean;
  @Output() cerrarlistaUsuariosAdminEvento = new EventEmitter();
  usus = [];
  visible = [];

  constructor(private ssesion: SsesionService) { }

  ngOnInit() {
    this.getUsuarios();
  }
  getUsuarios() {
    this.ssesion.getUsuAdm().subscribe(result => {
      this.usus = result;
      for (const usu of this.usus) {
          this.visible[usu.id] = false;
      }
    });
  }
}
