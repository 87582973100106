import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { DataScroller } from 'primeng/primeng';
import { Cliente } from '../comun/cliente';
import { Empresas } from '../comun/empresas';
import { ClientePeticion } from '../comun/cliente-peticion';
import { EmpresaPeticion } from '../comun/empresa-peticion';
import { Preferencia } from '../comun/preferencia';
import { PreferenciasPeticion } from '../comun/preferencias-peticion';
import { Horarios } from '../comun/horarios';
import { HorarioPeticion } from '../comun/horario-peticion';
import { Global } from '../comun/global';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class SsesionService {

  headers = new Headers();
  options: RequestOptions;
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  cliente: Cliente;
  empresas: Empresas[];
  global: Global = new Global();

  constructor(private httpClient: HttpClient) {

    this.headers = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=utf-8'
    });
    this.options = new RequestOptions({ headers: this.headers });
    this.tkn = new Tkn();
    // objeto tkn
    this.tkn.tkn = localStorage.getItem('tkn');
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // sesion ----------------------------------------------------------------------------------------------------------------------------
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setSesionCli(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionCliI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&usuario=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionCliI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&usuario=' + usuario);
  }

  setSesionCon(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionConI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&usuario=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionConI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&usuario=' + usuario);
  }
  setSesionCom(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionComI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&usuario=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionComI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&usuario=' + usuario);
  }
  setSesionGes(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionGesI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&usuario=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionGesI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&usuario=' + usuario);
  }

  setSesionAdm(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionI.php?tkn=' + (JSON.stringify(this.tkn))
      + '&usuario=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/sesionI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&usuario=' + usuario);
  }
  getUsuAdm(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuAdminS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuAdminS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  getUsuCli(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuCliS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuCliS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  getUsuCon(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuConS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuConS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  setUsuAdm(usuario: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuAdminU.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuAdminU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + usuario);
  }
  usuCliD(id: string): Observable<any> {
    let usuario = JSON.stringify({'id': id});
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuCliD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuCliD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + usuario);
  }
  usuComD(id: string): Observable<any> {
    let usuario = JSON.stringify({'id': id});
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuComD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuComD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + usuario);
  }
  getUsuCom(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuComS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuComS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  usuConD(id: string): Observable<any> {
    let usuario = JSON.stringify({'id': id});
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuConD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuConD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + usuario);
  }
  getUsuGes(): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuGesS.php?tkn=' + (JSON.stringify(this.tkn)));


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuGesS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn)));

  }
  usuGesD(id: string): Observable<any> {
    let usuario = JSON.stringify({'id': id});
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuGesD.php?tkn=' + (JSON.stringify(this.tkn))
      + '&datos=' + usuario);


    return this.httpClient.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + '/mantSesion/usuGesD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&datos=' + usuario);
  }
}
