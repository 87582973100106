import { Component,ViewEncapsulation, OnInit } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import { HttpModule } from '@angular/http';
import { Empresas } from '../../comun/empresas';
import { Propietario } from '../../comun/propietario';
import { Compania } from '../../comun/compania';
import { Global } from '../../comun/global';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import { StarifasService } from '../../servicios/starifas.service';
import { Ciudad } from '../../comun/ciudad';

@Component({
  selector: 'app-hist-emp',
  templateUrl: './hist-emp.component.html',
  styleUrls: ['./hist-emp.component.css'],
  providers: [SserService,StarifasService],
  encapsulation: ViewEncapsulation.None
})
export class HistEmpComponent implements OnInit {
  filesTree: TreeNode[]=[];
  global:Global = new Global();
  idCiudad:string="";
  ciudades: Ciudad[] = [];

  constructor(private staf: StarifasService,private sser: SserService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getCiudades();

  }
  getFiles(){
    var datos ={ dir:this.global.FAC_ENPRESAS_URL,"url":this.global.SERVIDOR_URL};
    this.sser.getFiles(JSON.stringify(datos)).subscribe(ser=>{
      
      this.filesTree=ser.data;
    });
  }
  getCiudades() {
    const ciu = new Ciudad();
    // ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      this.getFiles();
    });
  }
  getNombreCiudad(idCiudad): string {
    
    let res = '';
    let c = this.ciudades.filter(item => item.id == idCiudad);
    if (c && c.length > 0) {
      res = c[0].nombre;
      
    }
    return res;
  }
  isDoc(nodoData:string):boolean{
    if(nodoData=="Document") return true;
    else return false;
  }
  getLink(url:string):string{
    return url;
  }
}
