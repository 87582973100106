import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ScheduleModule } from 'primeng/primeng';
import { SserService } from '../servicios/sser.service';
import { SconductoresService } from '../servicios/sconductores.service';
import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Horarios } from '../comun/horarios';
import { Evento } from '../comun/evento';
import { Global } from '../comun/global';
import * as moment from 'moment';
import { ToggleButtonModule } from 'primeng/primeng';
import { Routes, RouterModule, Router } from '@angular/router';
import 'fullcalendar';
import 'fullcalendar-scheduler';
declare let $: any;

@Component({
  selector: 'app-cm',
  templateUrl: './cm.component.html',
  styleUrls: ['./cm.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService, SconductoresService]
})
export class CmComponent implements OnInit {

  conductores: Conductor[];
  servicios: Servicio[];
  eventos:any[] = [];
  resources:any[] = [];
  global: Global = new Global();
  idCiudad:string="";

  constructor(private sser: SserService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getConductores();
  }
  
  getServicios(conductor: string): void {

    this.eventos = [];
    this.resources = [];
    const fechaFin = moment().add(1, 'month').format('YYYY-MM-DD');
    const fechaIni = moment().subtract(1, 'month').format('YYYY-MM-DD');
    var datos;
    if (conductor == null) { datos = { 'fechaIni': fechaIni, 'fechaFin': fechaFin,'idCiudad':this.idCiudad }; }
    else { datos = { 'fechaIni': fechaIni, 'fechaFin': fechaFin, 'idConductor': conductor,'idCiudad':this.idCiudad }; }

    this.sser.getServicios(JSON.stringify(datos)).subscribe(result => {
      this.servicios = result;


      let resource= null;
      let iop=0;

      for (const servicio of this.servicios) {
        iop++;
        
        let evento = {
          'id':iop,
          'resourceId': servicio.idConductor?servicio.idConductor:'0',
          'start':moment(servicio.FechaDeRecogida).subtract(servicio.tiempoMedioRecogida, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
          'end': moment(servicio.FechaDeRecogida).add(servicio.tiempoMedioServicio, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
          'title': '(' + servicio.idservicio + ') --' + (servicio.Empresa) + ' -- ' + this.fech2time(servicio.FechaDeRecogida),
          'color': this.generaColor(servicio.estado),
          'textColor': 'white',
        };
        this.eventos.push(evento);
        resource= null;
        let yaEsta=false;
        if(this.resources && this.resources.length>0){
          for(let res of this.resources){
            if(res.id==servicio.idConductor) yaEsta=true;
          }
        }
        if(!yaEsta){
          resource ={
            'id': servicio.idConductor?servicio.idConductor:'0', 'title': servicio.Conductor?servicio.Conductor:"No asignado",
            'compania':this.getCompania(servicio.idConductor)
          };
          this.resources.push(resource);
        }

      }
      /* if(localStorage.getItem('debug')==='1') console.log('resorces:::>>>'+JSON.stringify(this.resources));
      if(localStorage.getItem('debug')==='1') console.log('eventos::::>>>'+JSON.stringify(this.eventos)); */
      this.optionscfg();
    });
  }
  getCompania(idCond){
    for(let c of this.conductores){
      if(c.id==idCond) return c.compania
    }
  }
  getConductores(){
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sconductores.getConductores(cond).subscribe(result => {
      this.conductores = result;
      this.getServicios(null);
    });
  }
  generaColorAle(){ 
    let hexadecimal = new Array("0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F") 
    let color_aleatorio = "#"; 
    for (let i=0;i<6;i++){ 
       let posarray = this.aleatorio(0,hexadecimal.length) 
       color_aleatorio += hexadecimal[posarray] 
    } 
    return color_aleatorio 
 }
 aleatorio(inferior,superior){ 
  let numPosibilidades = superior - inferior 
  let aleat = Math.random() * numPosibilidades 
  aleat = Math.floor(aleat) 
  return parseInt(inferior) + aleat 
}
optionscfg() {

    $('#calendar').fullCalendar({
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      editable: false,
       nowIndicator: true,
      aspectRatio: 2.8,
      scrollTime: moment().subtract(30,'minutes').format('HH:mm'),
      resourceGroupField: 'compania',
      header: {
        left: 'today',
        center: 'title',
        right: 'timelineDay,timelineTenDay'
      },
      defaultView: 'timelineDay',
      views: {
        timelineDay: {
          buttonText: 'hoy',
          slotDuration: '00:15'
        },
        timelineTenDay: {
          type: 'timeline',
          buttonText: 'hoy y mañana',
          duration: { days: 2 }
        }
      },
      navLinks: true,
      resourceAreaWidth: '15%',
      resourceLabelText: 'Compañia/Conductores',
      resourceColumns: [
        {
          labelText: 'Conductores',
          field: 'title'
        }
      ],
      resources: this.getResources(),
      events: this.getEvents()
    });
  }
  getResources(){
    return this.resources;
  }
  getEvents(){
    return this.eventos;
  }
  fech2time(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('HH:mm');
  }
  fech2date(fechaDeRecogida: string): string {
    return moment(fechaDeRecogida).format('YYYY-MM-DD');
  }
  generaColor(estado: string): string {
    var color = '#000000';
    if (estado && estado == this.global.ESTADO_EN_PETICION) {
      color = "#06052f";
    }
    if (estado && estado == this.global.ESTADO_ASIGNADO) {
      color = "#900C3F";
    }
    if (estado && estado == this.global.ESTADO_EN_CAMINO) {
      color = "#3d1c0a";
    }
    if (estado && estado == this.global.ESTADO_FINALIZADO) {
      color = "#3f9f40";
    }
    if (estado && estado == this.global.ESTADO_ANULADO) {
      color = "#ff3f3f";
    }
    if (estado && estado == this.global.ESTADO_ADMINISTRANDO) {
      color = "#ffbc3f";
    }
    if (estado && estado == this.global.ESTADO_EN_RUTA) {
      color = "#0a3909";
    }
    if (estado && estado == this.global.ESTADO_RESERVADO) {
      color = "#6ba5c1";
    }
    if (estado && estado == this.global.ESTADO_EN_POSICION) {
      color = "#ff00fc";
    }

    return color;
  }

}
