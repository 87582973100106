import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Estados } from '../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements OnInit {
  serListVis: boolean;
  @Input() serVis: boolean;
  @Output() activarUrgenteEvento = new EventEmitter();
  @Output() actUrgEvento = new EventEmitter();
  @Output() desactivarUrgenteEvento = new EventEmitter();
  @Output() actCronserEvento = new EventEmitter();
  @Output() desCronserEvento = new EventEmitter();
  serNewVis: boolean;
  planServicioVis:any;
  serNewTarVis:any;
  mapaVis:any;
  actUrg:any;
  cmVis:any;
  mapaGlo:any;
  constructor() { }

  ngOnInit() {
    this.serListVis = false;
    this.serVis = false;
  }
 activar(){
    //if(localStorage.getItem('debug')==='1') console.log('ServicesComponent:: activar URGENTES ');
    //this.activarUrgenteEvento.emit();
    this.actUrgEvento.emit();
    this.actCronserEvento.emit();

  }
  desactivar(){
    //if(localStorage.getItem('debug')==='1') console.log('ServicesComponent:: desactivar URGENTES ');
    this.desactivarUrgenteEvento.emit();
    this.desCronserEvento.emit();
  }
}
