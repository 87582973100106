import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { SstService } from '../../../servicios/sst.service';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Compania } from '../../../comun/compania';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Horarios } from '../../../comun/horarios';
import { Vehiculo } from '../../../comun/vehiculo';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Categoria } from '../../../comun/categoria';
import { Veh2cat } from '../../../comun/veh2cat';

@Component({
  selector: 'app-crud-vehiculo-list',
  templateUrl: './crud-vehiculo-list.component.html',
  styleUrls: ['./crud-vehiculo-list.component.css'],
  providers: [SconductoresService, SstService]
})
export class CrudVehiculoListComponent implements OnInit {
  @Input() vehiculoListVis: boolean;
  @Input() detalleVehiculovisible: boolean;
  color: string;

  vehiculoVis: boolean;
  detalleVis: boolean;
  vehiculos: Vehiculo[];
  companias: Compania[];
  categorias: Categoria[] = [];
  veh2cat: Veh2cat[] = [];

  selCategoriasHTML: SafeHtml[] = [];
  selCategorias: string[] = [];

  idCiudad: string;

  message: string[];

  constructor(private sconductores: SconductoresService, private sst: SstService,
    private sanitizer: DomSanitizer) {
    this.message = [];
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.vehiculoVis = true;
    this.detalleVis = false;
    this.vehiculoListVis = false;
    this.detalleVehiculovisible = false;
    this.getveh2cat();
    this.getCategorias();
  }

  borraVehiculo(id: string) {
    const vehiculo = {'id':id};
    this.sconductores.borraVehiculo(JSON.stringify(vehiculo)).subscribe(result => {
      this.escribemsg(id,result.message);
    });
    this.actualizarLista();

  }
  escribemsg(id,msg){
    this.message[id] =msg;
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 2500);
  }
  actualizaLista(id: any) {
    if ( id === '0') {
      this.sconductores.getVehiculos(null).subscribe(result => {
        this.vehiculos = result;

        for(let veh of this.vehiculos){
          this.getCats(veh.id);
        }
      });
    }else {
      const vehiculo = {'idCompania': id};
      this.sconductores.getVehiculos(JSON.stringify(vehiculo)).subscribe(result => {
        this.vehiculos = result;
      });
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
  getCategorias() {
    this.sconductores.getCategorias(null).subscribe(result => {
      this.categorias = result;
      this.getCompanias();
    });
  }
  getCompanias() {
    let config = null;
    if(this.idCiudad!=null) config = JSON.stringify({ 'estado': 'activo', 'idCiudad': this.idCiudad });
    this.sconductores.getCompaniaS(config).subscribe(result => {
      this.companias = result;
      this.companias.unshift({nombre: 'todos', id: '0', cif: '', email: '', visible: false,
                              telefono: '', direccion: '', estado: '', fechaAlta: '', fechaBaja: '',
                               contacto: '', poblacion: ''});
      this.actualizaLista('0');                        
    });
  }

  getNombreCompania(idcompania: string){
    for(let tpc of this.companias){
      //console.log('--->tpc.id='+tpc.id+' idcompania='+idcompania);
      if(tpc.id == idcompania) return tpc.nombre;
    }

  }
  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizarLista();
  }

  getveh2cat() {
    const config = JSON.stringify({ 'tabla': 'veh2cat', 'conEstado': '0' });

    this.sst.lista(config, null, null).subscribe(result => {
      this.veh2cat = result;
    });
  }

  getCats(idveh: string) {

    let str = "";
    let vc: Veh2cat[] = [];

    vc = this.veh2cat.filter(item => item.idveh === idveh);

    if (vc && vc.length > 0) {
      let scat='';
      for ( let cat of vc){
            for (let ca of this.categorias) {
              if (ca.id == cat.idcat) {
                str +="<li><b>cat:</b>" + ca.nombre + "</li>";
                if(scat=="") scat+=(ca.id);
                else scat+=('-'+ca.id);
                break;
              }
            }
      }
      this.selCategorias[idveh] = scat;
      // console.log('------>>>> >>>>'+scat);
      this.selCategoriasHTML[idveh] = this.sanitizer.bypassSecurityTrustHtml(str);
    }
  }
}