import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ScomercialgestorService } from '../../../../servicios/scomercialgestor.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SfactService } from '../../../../servicios/sfact.service';
import { Comercial } from '../../../../comun/comercial';
import { Estados } from '../../../../comun/estados';
import { Empresas } from '../../../../comun/empresas';
import { Global } from '../../../../comun/global';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-comercial',
  templateUrl: './detalle-comercial.component.html',
  styleUrls: ['./detalle-comercial.component.css'],
  providers: [ScomercialgestorService, SempresasService,SfactService]
})
export class DetalleComercialComponent implements OnInit {

  @Input() comercial: Comercial;
  detallecomercialvisible: boolean;
  comercialNueva: Comercial;
  @Output() guardarComercialEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarComercialListEvento = new EventEmitter();
  dealleVis: boolean;
  idCiudad:string="";

  estados: Estados[];

  empresas: Empresas[] = [];

  selectedEmpresas: Empresas[] = [];
  notSelectedEmpresas: Empresas[] = [];


  constructor(private sser: ScomercialgestorService, private sempresas: SempresasService
              ,private sfact: SfactService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getEmpresas();
    this.comercialNueva = JSON.parse(JSON.stringify(this.comercial,this.replacer));
    this.comercialNueva.visible = false;
    this.getEstadosbd();
  }

  getEmpresas() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
      this.getEmpFullxCom();
     });
  }
  getEmpFullxCom() {
    const com = JSON.stringify({ 'comercial': this.comercial.id });
    this.sfact.getEmpFullXCom(com).subscribe(result => {
      let emps = result;
      this.notSelectedEmpresas =this.empresas;
      for (let emp of emps) {
          let ups = this.empresas.filter(item => item.id == emp.idEmpresa);
          if(ups && ups.length>0){
            for (let up of ups) {
              this.selectedEmpresas.push(up);
              this.notSelectedEmpresas.splice(this.notSelectedEmpresas.indexOf(up),1);
            }
          }
        }
        if(localStorage.getItem('debug')==='1') console.log('this.selectedEmpresas--->'+JSON.stringify(this.selectedEmpresas));
        if(localStorage.getItem('debug')==='1') console.log('this.notSelectedEmpresas--->'+JSON.stringify(this.notSelectedEmpresas));
     });
  }

  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  actualizaComercial() {
    if (this.selectedEmpresas.length > 0) { 
      let emp = "";
      for (let e of this.selectedEmpresas) {
         emp += e.id+"-";
      }
      emp = emp.substring(0,emp.length-1);
      let datos =JSON.stringify({ 'idComercial': this.comercial.id, 'idEmpresa': emp });
      this.sfact.setEmpXCom(datos).subscribe();
    }else{
      let datos =JSON.stringify({ 'idComercial': this.comercial.id });
      this.sfact.delEmpXCom(datos).subscribe();
    }
    this.sser.upComercial(JSON.stringify(this.comercialNueva)).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarComercialListEvento.emit();
  }
  
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }

  asignoEstado(estado: string) {
    this.comercialNueva.estado = estado;
    if (estado === 'inactivo') {
      this.comercialNueva.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.comercialNueva.fechaBaja = '0000-00-00';
      this.comercialNueva.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
}
