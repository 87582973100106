import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Empresas } from '../../../comun/empresas';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { StarifasService } from '../../../servicios/starifas.service';
import { Ciudad } from '../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-crud-empresa',
  templateUrl: './crud-empresa.component.html',
  styleUrls: ['./crud-empresa.component.css'],
  providers: [SempresasService,StarifasService,ConfirmationService]
})
export class CrudEmpresaComponent implements OnInit {

  @Input() empresaVis: boolean;
  @Output() cerrarEmpresaEvento = new EventEmitter();

  empresaNueva: Empresas = new Empresas();
  ciudades: Ciudad[] = [];
  selectedCiudades: Ciudad[] = [];
  mantCiudadVis:any;
  ok:any;

  constructor(private sempresasService: SempresasService,private staf: StarifasService,  private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.empresaVis = false;
    this.getCiudades();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  new() {
    this.empresaNueva.estado = 'activo';
    this.empresaNueva.fechaAlta = moment().format('YYYY-MM-DD');
    this.empresaNueva.fechaBaja = '0000-00-00';
    this.empresaNueva.visible = false;
    
    

    this.sempresasService.setEmpresa(this.empresaNueva).subscribe(result=>{
            
      let msg ="Se ha creado la empresa";
      
      if (result.error){
        msg = "No se ha creador la empresa debido al siguiente error: "+result.error;
      }else if (result.id){
        
        if (this.selectedCiudades.length > 0) { 
          let aa = "[";
          for (let cs of this.selectedCiudades) {
             aa += "{\"idEmpresa\": \""+result.id+"\", \"idCiudad\": \""+cs.id+"\"},";
          }
          aa = aa.substring(0,aa.length-1)+"]";
          console.log("::::::::::::::::::::::::::::>>>>>>"+aa);
          this.sempresasService.setEmp2ciu(aa).subscribe();

        }

      }

      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.cerrarEmpresaEvento.emit();
        }
      });
 
    });
  }
}
