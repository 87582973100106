import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { StarifasService } from '../../../servicios/starifas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import { Tarifa } from '../../../comun/tarifa';
import { Ruta } from '../../../comun/ruta';

@Component({
  selector: 'app-crud-tarifas',
  templateUrl: './crud-tarifas.component.html',
  styleUrls: ['./crud-tarifas.component.css'],
  providers: [SempresasService, StarifasService, SconductoresService]
})
export class CrudTarifasComponent implements OnInit {
  @Input() tarifasVis: boolean;
  @Output() cerrarTarifasEvento = new EventEmitter();
  empresas: Empresas[];
  nuevaTarifa: Tarifa = new Tarifa();
  rutas: Ruta[];
  checked: boolean = false;
  idCiudad: string = "";
  tipoDeServicios: TipoDeServicios[];
  constructor(private sempresas: SempresasService, private starifas: StarifasService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    //console.log("IdCiudad=" + this.idCiudad);
    this.getTipoDeServicios();
    this.tarifasVis = false;
    this.getEmpresas();
    this.getRutas();
  }

  getEmpresas() {
    let cond = JSON.stringify({'idCiudad':this.idCiudad});
    this.sempresas.getEmpresas(cond).subscribe(result => {
      this.empresas = result;
    });
  }
  asignoEmpresa(idEmpresa: string) {
    this.nuevaTarifa.idempresa = idEmpresa;
  }
  getRutas() {
    this.starifas.getRutas(this.idCiudad).subscribe(result => {
      this.rutas = result;
    });
  }
  asignoRuta(idRuta: string) {
    this.nuevaTarifa.idruta = idRuta;
    let c = this.rutas.filter(item => item.id == idRuta);
    if (c && c.length > 0) {
      this.nuevaTarifa.idCiudad = c[0].idCiudad;
    }

  }
  new() {
    if(this.checked) this.nuevaTarifa.tarificacionPorTiempo=1;
    this.nuevaTarifa.estado = 'activo';
    this.nuevaTarifa.idCiudad = localStorage.getItem("IdCiudad");
    this.nuevaTarifa.fechaAlta = moment().format('YYYY-MM-DD');
    this.nuevaTarifa.fechaBaja = '0000-00-00';
    this.starifas.setTarifa(this.nuevaTarifa).subscribe();
    //if(localStorage.getItem('debug')==='1') console.log('this.nuevaTarifa--->' + JSON.stringify(this.nuevaTarifa));
    this.tarifasVis = false;
    this.cerrarTarifasEvento.emit();
  }
  getTipoDeServicios() {
    this.sconductores.getTipoDeServicios().subscribe(result => {
      this.tipoDeServicios = result;
    });
  }
  asignoTipoDeServicios(idTipoDeServicio: string) {
    this.nuevaTarifa.idTipoDeServicio = idTipoDeServicio;
  }
}
