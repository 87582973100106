import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-incidencias',
  templateUrl: './mant-incidencias.component.html',
  styleUrls: ['./mant-incidencias.component.css']
})
export class MantIncidenciasComponent implements OnInit {
  @Input() mantIncidenciasVis: boolean;
  @Output() cerrarMantIncidenciasEvento = new EventEmitter();
  incidenciasListVis:boolean;
  incidenciasVis:boolean;

  constructor() { }

  ngOnInit() {
  }

}
