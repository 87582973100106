import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SsesionService } from '../../../../servicios/ssesion.service';
import { ScomercialgestorService } from '../../../../servicios/scomercialgestor.service';
import { Servicio } from '../../../../comun/servicio';
import { Comercial } from '../../../../comun/comercial';
import { Cliente } from '../../../../comun/cliente';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { Global } from '../../../../comun/global';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-nuevo-usuario-comercial',
  templateUrl: './nuevo-usuario-comercial.component.html',
  styleUrls: ['./nuevo-usuario-comercial.component.css'],
  providers: [SsesionService, ScomercialgestorService]
})
export class NuevoUsuarioComercialComponent implements OnInit {
  @Input() altaUsuarioConductorVis: boolean;
  @Output() cerrarAltaUsuarioComercialEvento = new EventEmitter();

  comerciales: Comercial[];
  selectedValues = [];
  contrasena: string;

  constructor(private sser: ScomercialgestorService, private ssesion: SsesionService) { }

  ngOnInit() {
    this.getConductores();
  }
  getConductores() {
    this.sser.getComerciales(null,"activos").subscribe(result => {
      this.comerciales = result;
    });
  }
  alta() {
    for ( const selectedValue of this.selectedValues) {
      if ( this.contrasena && this.contrasena.length > 0) {
        const usuario = ({ 'idComercial': selectedValue.id.toString(), 'hash':  Md5.hashStr(this.contrasena.toString())});
        // if(localStorage.getItem('debug')==='1') console.log('usuario= ' + JSON.stringify(usuario));
        this.ssesion.setSesionCom(JSON.stringify(usuario)).subscribe(result => {
          // this.conductor = result;
        });
        this.cerrarAltaUsuarioComercialEvento.emit();
      }
    }
  }
}