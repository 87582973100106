export class Servicio {
    public idservicio: string;
    public Ciudad: string;
    public Conductor: string;
    public idConductor: string;
    public Color: string;
    public TipoDeServicio: string;
    public idTipoDeServicio: string;
    public Origen: string;
    public Destino: string;
    public FechaDeRecogida: string;
    public idEmpresa: string;
    public Empresa: string;
    public FechaDePeticion: string;
    public estado: string;
    public estaAsignado: boolean;
    public esUrgente: boolean;
    public visible: boolean;
    public visible1: boolean;
    public visible2: boolean;
    public idtarifa: string;
    public visible3: boolean;
    public visible4: boolean;
    public nombreTarifa: string;
    public tarifa: string;
    public tarifaConductor: string;
    public tarifaConductorRed: string;
    public tarifaAnuCon: string;
    public tarifaParadaCon: string;
    public tarifaParada: string;
    public tarifaAnulacion: string;
    public tarifaOnline: string;
    public tiempoMedioServicio: string;
    public tiempoMedioRecogida: string;
    public cliente: string;
    public idCliente: string;
    public pasajero: string;
    public vuelo: string;
    public parada: string;
    public cartel: string;
    public comentarioCon: string;
    public comentarioCli: string;
    public tiempoDeEspera: string;
    public tarificacionPorTiempo: number;
    public fechaParadaIni: string;
    public fechaParadaFin: string;
    public tiempoInicioRuta: string;
    public tiempoFinRuta: string;
    public tiempoTH: string;
    public imgCartel: string;
    public idCiudad: string;
}
