import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Coordinador } from '../../../comun/coordinador';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Global } from '../../../comun/global';
import { Compania } from '../../../comun/compania';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Horarios } from '../../../comun/horarios';
import { Vehiculo } from '../../../comun/vehiculo';

@Component({
  selector: 'app-crud-coordinador-list',
  templateUrl: './crud-coordinador-list.component.html',
  styleUrls: ['./crud-coordinador-list.component.css'],
  providers: [SempresasService, SconductoresService]
})
export class CrudCoordinadorListComponent implements OnInit {
  @Input() detalleCoordinadorvisible: boolean;

  detalleVis: boolean;
  coordinadores: Coordinador[]=[];
  companias: Compania[]=[];
  message: string[];
  idCiudad:string;
  id0:any;

  constructor(private sconductores: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.detalleVis = false;
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.getCompanias();
    this.actualizaLista('1');
  }
  actualizaLista(id: any) {
    this.id0=id;
    if ( id === '1') {
      this.sconductores.getCoordinadores(null).subscribe(result => {
        this.coordinadores = result;
      });
    }else {
      let coor =JSON.stringify({'idCompania':id});
      this.sconductores.getCoordinadores(coor).subscribe(result => {
        this.coordinadores = result;
      });
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
  setNombreCompania(id:string):string{
    let nombre='';
    for (let comp of this.companias){
      if(comp.id == id) nombre = comp.nombre;
    }
    return nombre;
  }
  getCompanias() {
    let config = null;
    if(this.idCiudad!=null) config = JSON.stringify({ 'estado': 'activo', 'idCiudad': this.idCiudad });
    this.sconductores.getCompaniaS(config).subscribe(result => {
      this.companias = result;
      this.companias.unshift({nombre: 'todos', id: '1', cif: '', email: '', visible: false,
                              telefono: '', direccion: '', estado: '', fechaAlta: '', fechaBaja: '',
                               contacto: '', poblacion: ''});
    });
  }

  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
  actualizarConEspera(){
    this.wait(1000);
    this.actualizarLista();
  }
}
