import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-estados',
  templateUrl: './mant-estados.component.html',
  styleUrls: ['./mant-estados.component.css']
})
export class MantEstadosComponent implements OnInit {
  @Input() mantEstadosVis: boolean;
  @Output() cerrarMantEstadosEvento = new EventEmitter();

  @Input() estadosListVis: boolean;
  @Input() estadosVis: boolean;
  mantUsuariosVis: boolean;
  estadoListVis: boolean;
  estadoVis: boolean;
  

  constructor() { }

  ngOnInit() {
    this.mantEstadosVis = false;
    this.estadosVis = false;
    this.estadosListVis = false;
  }

}
