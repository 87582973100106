import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Global } from '../../../comun/global';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Horarios } from '../../../comun/horarios';

@Component({
  selector: 'app-crud-horarios-list',
  templateUrl: './crud-horarios-list.component.html',
  styleUrls: ['./crud-horarios-list.component.css'],
  providers: [SempresasService, SconductoresService]
})
export class CrudHorariosListComponent implements OnInit {
  @Input() horarioListVis: boolean;
  @Input() detalleHorariovisible: boolean;
  // @Output() cerrarhorarioListEvento = new EventEmitter();
  horarioVis: boolean;
  detalleVis: boolean;
  horarios: Horarios[];
  conductores: Conductor[];

  message: string[];

  constructor(private sempresas: SempresasService, private sconductoresService: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.horarioVis = true;
    this.detalleVis = false;
    this.horarioListVis = false;
    this.detalleHorariovisible = false;
    this.getConductores();
    this.getHorarios();
  }
  getConductores() {
    this.sconductoresService.getConductores(null).subscribe(result => {
      this.conductores = result;
      this.conductores.unshift({nombre: 'todos', id: '1', tipoDeServicio: '', idsesion: '', telefono: '',
      email: '', estado: '', tarifaMinima: '', idCompania: '', fechaAlta: '', fechaBaja: '', color:"",
      dni: '', /* vehiculo: '', matricula: '', idTipoDeServicio: '', */
      compania: '', permisoConducir: '', visible: false,idVehiculo:'',urlFoto:'',idCiudad:''});

    });
  }
  getFormatFecha(fechaIni: string): string {
    return moment(fechaIni, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }S
  getFormatHora(horaIni: string): string {
    return moment(horaIni, 'HH:mm').format('HH:mm');
  }
  getFormatFech(): string {
    return moment().format('YYYY-MM-DD');
  }
  getHorarios() {
    const horario = { 'fechaIni': this.getFormatFech(), 'fechaFin': this.getFormatFech()};
    this.sempresas.getHorarios(JSON.stringify(horario)).subscribe(result => {
      this.horarios = result;
    });
  }
  getHorario2Conductor(idConductor: string) {
    const horario = { 'idConductor':idConductor,'fechaIni': this.getFormatFech(), 'fechaFin': this.getFormatFech()};
    this.sempresas.getHorario2Conductor(JSON.stringify(horario)).subscribe(result => {
      this.horarios = result;
    });
  }

  borraHorario(id: string) {
    this.message[id] = '¡¡Atencion, ha inactivado el horario!!';
    setTimeout(function() {
      this.message[id] = null;
    }.bind(this), 2500);
    this.sempresas.borraHorarios(id).subscribe();
    this.actualizarLista();

  }
  actualizaLista(id: any) {
    if ( id === '1') {
      this.getHorarios();
    }else {
      this.getHorario2Conductor(id);
    }
  }
  actualizarLista() {
    this.ngOnInit();
  }
}
