import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Compania } from '../../../../comun/compania';
import { Conductor } from '../../../../comun/conductor';
import { CompaniaPeticion } from '../../../../comun/compania-peticion';
import { Acontecimiento } from '../../../../comun/acontecimiento';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';
import { Console } from '@angular/core/src/console';
import { ColorPickerModule } from 'primeng/colorpicker';
import { Vehiculo } from '../../../../comun/vehiculo';
import { Global } from '../../../../comun/global';
import { SserService } from '../../../../servicios/sser.service';
import { StarifasService } from '../../../../servicios/starifas.service';
import { Ciudad } from '../../../../comun/ciudad';


@Component({
  selector: 'app-detalle-conductor',
  templateUrl: './detalle-conductor.component.html',
  styleUrls: ['./detalle-conductor.component.css'],
  providers: [SconductoresService,SserService,StarifasService]
})
export class DetalleConductorComponent implements OnInit {

  @Input() conductor: Conductor;
  @Input() ciudades: Ciudad[];
  detalleConductorvisible: boolean;

  @Output() guardarConductotEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarConductorListEvento = new EventEmitter();


  dealleVis: boolean;
  companias: Compania[];
  tipoDeServicios: TipoDeServicios[];
  estados: Estados[];
  conductorNuevo: Conductor  = new Conductor();
  vehiculos: Vehiculo[];
  imgCond:string = 'assets/img/nohayfoto.jpg';
  uploadedFiles: any[] = [];
  fileToUpload: File = null;
  global:Global = new Global();
  idCiudad:string ="";

  constructor(private sser: SserService,private sempresas: SempresasService, private sconductores: SconductoresService,private staf: StarifasService) { }

  ngOnInit() {
    this.idCiudad = this.idCiudad = localStorage.getItem("IdCiudad");
    this.conductorNuevo = JSON.parse(JSON.stringify(this.conductor, this.replacer));
    //if(localStorage.getItem('debug')==='1') console.log('conductorNuevo.idVehiculo================='+this.conductorNuevo.idVehiculo);
    this.getCiudades();
    this.getVehiculos(this.conductorNuevo.idCompania);
    this.getCompanias();
    this.getEstadosbd();
    
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }

  asignoCiudad(idciudad: string) {
    this.conductorNuevo.idCiudad = idciudad;
  }
  actualizaConductor() {

    this.conductor=this.conductorNuevo;
    //Borra datos json
    this.conductorNuevo.visible=null;
    this.conductorNuevo.compania=null;
    this.conductorNuevo.tipoDeServicio=null;
    this.conductorNuevo.color=null;
    if(this.fileToUpload){
      let e = this.conductorNuevo.email;
      var re = /\@/g;
      e = e.replace(re, '_');

      let ext = this.fileToUpload.name;
      let em = ext.split('.');
      ext = em[(em.length - 1)];

      this.conductorNuevo.urlFoto = this.global.SERVIDOR_URL+this.global.DIRBASE_IMG_COND+e+'/'+this.global.COND_LOG_NAME + '.' +ext;
      this.postFile();
    }
     
    this.conductorNuevo = JSON.parse(JSON.stringify(this.conductorNuevo, this.replacer));
    
    //if(localStorage.getItem('debug')==='1') console.log('::::::::::::::::::::::::::::>>>>>>'+JSON.stringify(this.conductorNuevo));

    this.sconductores.upConductor(JSON.stringify(this.conductorNuevo)).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarConductorListEvento.emit();
  }
  

  getCompanias() {
    const companiaPeticion: CompaniaPeticion = new CompaniaPeticion();

    companiaPeticion.estado = 'activo';
    this.sconductores.getCompanias(companiaPeticion).subscribe(result => {
      this.companias = result;
    });
  }
  asignoCompania(idCompania: string) {
    this.getVehiculos(idCompania);
    this.conductorNuevo.idCompania = idCompania;
    for(let p of this.companias){
        if(p.id==idCompania) this.conductorNuevo.compania=p.nombre;
    }
  }
  asignoEstado(estado: string) {
    this.conductorNuevo.estado = estado;
    if (estado === 'inactivo') {
      this.conductorNuevo.fechaBaja = moment().format('YYYY-MM-DD');
    } else {
      this.conductorNuevo.fechaBaja = '0000-00-00';
      this.conductorNuevo.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  getVehiculos(id) {
    const vehiculo = { 'idCompania': id };
    this.sconductores.getVehiculos(JSON.stringify(vehiculo)).subscribe(result => {
      this.vehiculos = result;
    });
  }
  actualizaVehiculo(idVehiculo: string) {
    this.conductorNuevo.idVehiculo = idVehiculo;
    for (let veh of this.vehiculos) {
      if (idVehiculo == veh.id) {
        //this.conductorNuevo.idTipoDeServicio = veh.idTipoDeServicio;
        break;
      }
    }

  }
  getImage(){
    if(this.fileToUpload){
      this.imgCond = this.fileToUpload.name;
    }else this.imgCond = 'assets/img/nohayfoto.jpg';
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  postFile() {
    if(this.fileToUpload){

      let e = this.conductorNuevo.email;
      var re = /\@/g;
      e = e.replace(re, '_');

      this.sser.envioFoto(this.fileToUpload,e+'/').subscribe(
        res => {
          if(localStorage.getItem('debug')==='1') console.log('res='+JSON.stringify(res));
        });
      }
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
}
