import { Component, OnInit } from '@angular/core';
import { ConductorOcupado } from '../../comun/conductor-ocupado';
import { Conductor} from '../../comun/conductor';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Estados } from '../../comun/estados';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../../servicios/sser.service';
import { SconductoresService } from '../../servicios/sconductores.service';
import { SempresasService } from '../../servicios/sempresas.service';
import { SfactService } from '../../servicios/sfact.service';
import {HttpModule} from '@angular/http';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { Servicio } from '../../comun/servicio';
import { Empresas } from '../../comun/empresas';
import { Cliente } from '../../comun/cliente';
import { TipoDeServicios } from '../../comun/tipo-de-servicios';
import { Propietario } from '../../comun/propietario';
import { Compania } from '../../comun/compania';


@Component({
  selector: 'app-conductores',
  templateUrl: './conductores.component.html',
  styleUrls: ['./conductores.component.css'],
  providers: [SserService, SconductoresService, SfactService]
})
export class ConductoresComponent implements OnInit {
  @Input() facConVis: boolean;
  @Output() cerrarfacConEvento = new EventEmitter();

  conductores: Conductor[];
  companias: Compania[];
  selectedValuesCond = [];
  ssCondMails:any[]=[];
  idCiudad:string="";
  fechaIni: string;
  fechaFin: string;
  fechaUltFact: string;
  companiaFact: Compania;
  propietario: Propietario;
  message: string[];
  msgs = [];
  aleVis= false;
  
  constructor(private sser: SserService, private sconductores: SconductoresService, private sfact: SfactService) { }


  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.fechaIni = moment().format('DD/MM/YYYY');
    this.fechaFin = moment().format('DD/MM/YYYY');
    this.getConductores();
    this.getcompanias();
    this.getPropietario();
  }

  getMailsCondCli(esCond:string){
    var datos = JSON.stringify({"esCondCli":esCond,"compania":this.companiaFact.id});
    this.sser.getMailsCondNSCli(datos).subscribe(result=>{
      //if(localStorage.getItem('debug')==='1') console.log('#######>>>>'+JSON.stringify(result));
       this.ssCondMails= result;
       this.selectedValuesCond = this.ssCondMails;
    });
  }

  iniciar() {
    this.msgs = [];
    this.fechaIni = moment(this.fechaIni, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.fechaFin = moment(this.fechaFin, 'DD/MM/YYYY').format('DD/MM/YYYY');
    this.aleVis = !this.aleVis;
  }
    // fechas Ini
    actualizaIniConFechaHoy() {
      this.fechaIni = moment().format('DD/MM/YYYY');
    }
    actualizaIniConFechaUltimaFacturacion() {
       if (this.fechaUltFact == '') {
        this.fechaIni = moment().format('DD/MM/YYYY');
      }else {
        this.fechaIni = moment(this.fechaUltFact).add(1,'days').format('DD/MM/YYYY');
      }
    }
    actualizaIniConFechaAyer() {
      this.fechaIni = moment().subtract(1, 'days').format('DD/MM/YYYY');
    }
  actualizaFinConFechaUltimaFacturacionplus1() {
    if (this.fechaUltFact == '') {
      this.fechaFin = moment().add(1, 'M').format('DD/MM/YYYY');
    }else {
      this.fechaFin = moment(this.fechaUltFact).add(1, 'days').add(1, 'M').format('DD/MM/YYYY');
    }
  }
  actualizaFinConFechaHoy() {
    this.fechaFin = moment().format('DD/MM/YYYY');
  }
  actualizafinConFechaManana() {
    this.fechaFin = moment(this.fechaIni, 'DD/MM/YYYY').add(14, 'days').format('DD/MM/YYYY');
  }
  actualizafinConFecha15d() {
    this.fechaFin = moment(this.fechaIni , 'DD/MM/YYYY').add(1, 'M').subtract(1, 'days').format('DD/MM/YYYY');
  }

  getConductores() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sconductores.getConductores(cond).subscribe(result => {
      this.conductores = result;
  /*     this.conductores.unshift({nombre: 'todos', id: '1', color: '', tipoDeServicio: '', idsesion: '', telefono: '',
      email: '', estado: '', tarifaMinima: '', idCompania: '', fechaAlta: '', fechaBaja: '',
      dni: '', vehiculo: '', matricula: '', idTipoDeServicio: '',
      compania: '', permisoConducir: '', visible: false}); */

    });
  }
  getcompanias() {
    let cond =JSON.stringify({'idCiudad':this.idCiudad});
    this.sconductores.getCompaniaS(cond).subscribe(result => {
      this.companias = result;
      for (const bc of this.companias) {
        // if(localStorage.getItem('debug')==='1') console.log('bc---->' + JSON.stringify(bc));
        if (bc ) {
          this.companiaFact = bc;
          this.getMailsCondCli("1");
          this.actualizoCompania(bc.id);
          break;
        }
      }
    });
  }
  actualizoCompania(idCompania: any) {
    // if(localStorage.getItem('debug')==='1') console.log('idEmpresa---->' + idEmpresa);
    this.fechaUltFact = '';
    

    let cif;
    for (const cond of this.companias) {
      if (cond && cond.id == idCompania) {
        cif = cond.cif;
        this.companiaFact = cond;
        this.getMailsCondCli("1");
      }
    }
    const datos = JSON.stringify({cif: cif});
    this.sfact.getCondFact(datos).subscribe(result => {
      for (const res of result) {
        if (res) {
           this.fechaUltFact = res.fechaFin;
        }
      }
    });
    //if(localStorage.getItem('debug')==='1') console.log('------->>>>>>');
    if (this.aleVis == true) this.aleVis= !this.aleVis;
  }
  getPropietario () {
    let  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         //if(localStorage.getItem('debug')==='1') console.log('this.prop---->' + prop);
         break;
      }
    });
  }
}
