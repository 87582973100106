import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-tarifas',
  templateUrl: './mant-tarifas.component.html',
  styleUrls: ['./mant-tarifas.component.css']
})
export class MantTarifasComponent implements OnInit {
  @Input() mantTarifasVis: boolean;
  @Output() cerrarMantTarifasEvento = new EventEmitter();
  tarifasListVis: boolean;
  tarifasVis: boolean;


  constructor() { }

  ngOnInit() {
  }

}
