import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { StarifasService } from '../../../servicios/starifas.service';
import { Ciudad } from '../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-ciudad-list',
  templateUrl: './crud-ciudad-list.component.html',
  styleUrls: ['./crud-ciudad-list.component.css'],
  providers: [StarifasService, ConfirmationService]
})
export class CrudCiudadListComponent implements OnInit {
  @Input() ciudadListVis: boolean;
  @Input() detalleCiudadvisible: boolean;


  @Input() cerrarCiudadListEvento: boolean;
  @Output() actualizarCiudadListEvento = new EventEmitter();

  detalleVis: boolean;
  ciudades: Ciudad[] = [];
  message: string[];
  selectedValues = [];
 

  constructor(private sser: StarifasService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.message = [];
    this.getCiudades();
  }
  estaActivo(ciudad: Ciudad) {
    if (ciudad !=null && ciudad.estado == "activo") return true;
    else return false;
  }
  getCiudades() {
    const ciu = new Ciudad();
    // ciu.estado = 'activo';
    this.sser.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      //if(localStorage.getItem('debug')==='1') console.log('----------------------------------->>>>>>>>>>>'+JSON.stringify(this.ciudades));
    });
  }
  actualizarLista() {
    this.ngOnInit();
  }
  borraCiudad(ciudad: Ciudad) {
    ciudad.estado = "inactivo";
    ciudad.fechaBaja = moment().format('YYYY-MM-DD');
    ciudad.fechaAlta = '0000-00-00';
    this.sser.upCiudad(ciudad).subscribe();
  }
  activarCiudad(ciudad: Ciudad) {
    ciudad.estado = "activo";
    ciudad.fechaAlta = moment().format('YYYY-MM-DD');
    ciudad.fechaBaja = '0000-00-00';
    this.sser.upCiudad(ciudad).subscribe();
  }

}
