import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SstService } from '../../../../servicios/sst.service';
import { TipoSer2prefS } from '../../../../comun/tipo-ser2pref-s';
import { Conductor } from '../../../../comun/conductor';
import { Preferencia } from '../../../../comun/preferencia';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Categoria } from '../../../../comun/categoria';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PreferenciaCb } from '../../../../comun/preferencia-cb';


@Component({
  selector: 'app-detalle-tipodeservicio',
  templateUrl: './detalle-tipodeservicio.component.html',
  styleUrls: ['./detalle-tipodeservicio.component.css'],
  providers: [SconductoresService, SempresasService, SstService]
})
export class DetalleTipodeservicioComponent implements OnInit {

  @Input() tipodeservicio: TipoDeServicios;
  @Input() categorias: Categoria[];
  @Input() categoriaIds: string;

  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarTipodeservicioListEvento = new EventEmitter();

  detalleTipodeserviciovisible: boolean;
  tipodeservicioNuevo: TipoDeServicios;

  selectedCategorias: Categoria[] = [];
  notSelectedCategorias: Categoria[] = [];

  constructor(private sconductores: SconductoresService, private sempresas: SempresasService, private sst: SstService) { }

  ngOnInit() {
    this.tipodeservicioNuevo = JSON.parse(JSON.stringify(this.tipodeservicio, this.replacer));
    this.getCatNotSel(this.categoriaIds);
    this.getCatSel(this.categoriaIds);
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  actualizaTipodeservicio() {


    if (this.selectedCategorias.length > 0) {

      let config = JSON.stringify({ 'tabla': 'tds2cat', 'conEstado': '0' });
      let datos = JSON.stringify({ 'nombre': 'idtds', 'campo': this.tipodeservicioNuevo.id });

      this.sst.borra(config, datos).subscribe(result => {
        for (let f of this.selectedCategorias) {
          datos = JSON.stringify({'idcat': f.id, 'idtds': this.tipodeservicioNuevo.id});
          this.sst.actualiza(config, datos).subscribe();
        }
      });

      // actualizo tipo de servicio
      this.tipodeservicio = JSON.parse(JSON.stringify(this.tipodeservicioNuevo, this.replacer));
      this.sconductores.upTipodeservicio(this.tipodeservicio).subscribe();
      this.cerrarDetalleVisEvento.emit();
      this.actualizarTipodeservicioListEvento.emit();
    }
  }
  getCatSel(categoriaIds: string) {
    //console.log('::::::::::::::::::::::::>>' + categoriaIds);
    this.selectedCategorias = [];
    let ids = [];
    if (categoriaIds) {
      if (categoriaIds.indexOf('-') == -1) {
        ids.push(categoriaIds);
      } else {
        ids = categoriaIds.split('-');
      }
      for (let id of ids) {
        for (let tds of this.categorias) {
          if (tds.id == id) {
            this.selectedCategorias.push(tds);
          }
        }
      }
    }
  }
  getCatNotSel(categoriaIds: any) {
    this.notSelectedCategorias = this.categorias;
    //console.log('::::::::::::::::::::::::>>'+JSON.stringify(this.notSelectedTipoDeServicio));
    let ids = [];
    if (categoriaIds) {
      if (categoriaIds.indexOf('-') == -1) {
        ids.push(categoriaIds);
      } else {
        ids = categoriaIds.split('-');
      }
      for (let id of ids) {
        for (let tds of this.categorias) {
          if (tds.id == id) {
            this.notSelectedCategorias = this.notSelectedCategorias.filter(item => item !== tds);
          }
        }
      }
    }
  }
}
