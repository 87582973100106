import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { StarifasService } from '../../../../servicios/starifas.service';
import { Servicio } from '../../../../comun/servicio';
import { Compania } from '../../../../comun/compania';
import { Empresas } from '../../../../comun/empresas';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { EmpresaPeticion } from '../../../../comun/empresa-peticion';
import { Ciudad } from '../../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-detalle-compania',
  templateUrl: './detalle-compania.component.html',
  styleUrls: ['./detalle-compania.component.css'],
  providers: [SconductoresService, StarifasService, SempresasService, ConfirmationService]
})
export class DetalleCompaniaComponent implements OnInit {
  @Input() compania: Compania;
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarCompaniaListEvento = new EventEmitter();
  ok:any;

  detallecompaniavisible: boolean;
  companiaNueva: Compania;
  estados: Estados[];
  selectedCiudades: Ciudad[] = [];
  notSelectedCiudades: Ciudad[] = [];
  ciudades: Ciudad[] = [];


  constructor(private sconductores: SconductoresService, private sempresas: SempresasService, private staf: StarifasService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getCiudades();

    this.companiaNueva = new Compania();
    Object.assign(this.companiaNueva, this.compania);

    this.getEstadosbd();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      this.notSelectedCiudades = result;
      this.getComp2ciu();
    });
  }


  update() {
    let todok = false;
    if (this.selectedCiudades.length == 0) {
      this.confirmationService.confirm({
        message: "Debe dar valor a todos los campos ",
        accept: () => {
        }
      });
    } else {
        todok = true;
    }
    if (todok) {
      this.companiaNueva.visible = false;
      this.compania = this.companiaNueva;

      this.sconductores.upCompania(this.companiaNueva).subscribe(result => {

        let msg = "Se ha modificado la compañia";

        if (result.error) {
          msg = "No se ha modificado la compañia debido al siguiente error: " + result.error;
        } else if (result.id) {

          if (this.selectedCiudades.length > 0) {
            let aa = "[";
            for (let cs of this.selectedCiudades) {
              aa += "{\"idCompania\": \"" + this.companiaNueva.id + "\", \"idCiudad\": \"" + cs.id + "\"},";
            }
            aa = aa.substring(0, aa.length - 1) + "]";
            this.sconductores.upComp2ciu(aa).subscribe();
          }
        }

        this.confirmationService.confirm({
          message: msg,
          accept: () => {
            this.cerrarDetalleVisEvento.emit();
            this.actualizarCompaniaListEvento.emit();
          }
        });
      });
    }
  }

getEstadosbd() {
  this.sempresas.getEstadosbd().subscribe(result => {
    this.estados = result;
  });
}
asignoEstado(estado: string) {
  this.companiaNueva.estado = estado;
  if (estado === 'inactivo') {
    this.companiaNueva.fechaBaja = moment().format('YYYY-MM-DD');
  } else {
    this.companiaNueva.fechaBaja = '0000-00-00';
    this.companiaNueva.fechaAlta = moment().format('YYYY-MM-DD');
  }
}

getComp2ciu() {
  this.sconductores.getComp2ciu(this.compania.id).subscribe(result => {
    let cp: Ciudad[] = [];
    for (let res of result) {
      let a = this.ciudades.find(item => item.id == res.idCiudad)
      if (!cp[a.id]) {
        cp[a.id] = a;
        this.selectedCiudades.push(a);
      }
    }
    for (let res of this.selectedCiudades) {

      this.notSelectedCiudades = this.notSelectedCiudades.filter(item => item.id !== res.id)
    }
  });
}

}
