import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { StarifasService } from '../../../../servicios/starifas.service';
import { Ciudad } from '../../../../comun/ciudad';
import { ConfirmationService } from 'primeng/api';
import { Estados } from '../../../../comun/estados';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-ciudad',
  templateUrl: './detalle-ciudad.component.html',
  styleUrls: ['./detalle-ciudad.component.css'],
  providers: [StarifasService, SempresasService]
})
export class DetalleCiudadComponent implements OnInit {

  @Input() ciudad: Ciudad;
  detalleCiudadvisible: boolean;
  ciudadNueva: Ciudad;
  @Output() guardarCiudadEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarCiudadListEvento = new EventEmitter();
  dealleVis: boolean;

  estados: Estados[];

  constructor(private sser: StarifasService, private sempresas: SempresasService) { }

  ngOnInit() {
    this.getEstadosbd();
    this.ciudadNueva = this.ciudad;
  }

  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }
  asignoEstado(estado: string) {
    this.ciudadNueva.estado = estado;
    if (estado === 'inactivo') {
      this.ciudadNueva.fechaBaja = moment().format('YYYY-MM-DD');
    }else {
      this.ciudadNueva.fechaBaja = '0000-00-00';
      this.ciudadNueva.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  actualizaCiudad() {

    this.sser.upCiudad(this.ciudadNueva).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarCiudadListEvento.emit();
  }

}
