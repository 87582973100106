import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { StarifasService } from '../../../servicios/starifas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Ruta } from '../../../comun/ruta';
import { Ciudad } from '../../../comun/ciudad';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-rutas-list',
  templateUrl: './crud-rutas-list.component.html',
  styleUrls: ['./crud-rutas-list.component.css'],
  providers: [StarifasService]
})
export class CrudRutasListComponent implements OnInit {

  @Input() rutaListVis: boolean;
  @Input() detalleRutavisible: boolean;
  @Output() cerrarRutaListEvento = new EventEmitter();
  id0:any;

  rutas: Ruta[]=[];
  message: string[];
  msg: string;
  ciudades: Ciudad[] = [];
  idCiudad:String="";
 

  constructor(private starifas: StarifasService) {
    this.message = [];
    this.getRutas();
  }

  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    /* this.rutaListVis = false;
    this.detalleRutavisible = false; */
    this.getCiudades();
    this.getRutas();
  }
  

  borraRuta(id: string) {
    const rut: Ruta = new Ruta();
    rut.id = id;
    rut.fechaAlta = moment().format('YYYY-MM-DD');
    this.starifas.borraRuta(rut).subscribe();
    this.getRutas();
  }
 
  getCiudad(idCiudad): string {
    let res = '';
    let c = this.ciudades.filter(item => item.id == idCiudad);
    if (c && c.length > 0) {
      res = c[0].nombre;
    }
    return res;
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  getRutas() {
    this.starifas.getRutas(this.idCiudad).subscribe(result => {
      this.rutas = result;
    });
  }
  actualizarLista() {
    this.ngOnInit();
  }

}
