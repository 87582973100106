import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-tipodeservicio',
  templateUrl: './mant-tipodeservicio.component.html',
  styleUrls: ['./mant-tipodeservicio.component.css']
})
export class MantTipodeservicioComponent implements OnInit {
  @Input() mantTipodeservicioVis: boolean;
  @Output() cerrarMantTipodeservicioEvento = new EventEmitter();

  tipodeservicioListVis: boolean;
  tipodeservicioVis: boolean;


  constructor() { }

  ngOnInit() {
    this.mantTipodeservicioVis = false;
    this.tipodeservicioVis = false;
    this.tipodeservicioListVis = false;
  }

}
