import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { StarifasService } from '../../../servicios/starifas.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Ciudad } from '../../../comun/ciudad';
import { Ruta } from '../../../comun/ruta';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-rutas',
  templateUrl: './crud-rutas.component.html',
  styleUrls: ['./crud-rutas.component.css'],
  providers: [StarifasService]
})
export class CrudRutasComponent implements OnInit {
  @Input() rutaVis: boolean;
  @Output() cerrarRutaEvento = new EventEmitter();

  rutaNueva: Ruta = new Ruta();
  ciudades: Ciudad[] = [];

  constructor(private sser: StarifasService) { }

  ngOnInit() {
    this.rutaVis = false;
    this.getCiudades();
  }
  new() {
    this.rutaNueva.estado = 'activo';
    this.rutaNueva.fechaAlta = moment().format('YYYY-MM-DD');
    this.rutaNueva.fechaBaja = '0000-00-00';
    this.rutaNueva.visible = false;

    this.sser.setRuta(this.rutaNueva).subscribe();
    this.cerrarRutaEvento.emit();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.sser.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
  asignoEstado(idciudad: string) {
    this.rutaNueva.idCiudad = idciudad;
  }
}
