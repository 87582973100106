import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ScomercialgestorService } from '../../../servicios/scomercialgestor.service';
import { Gestor } from '../../../comun/gestor';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-gestor-list',
  templateUrl: './crud-gestor-list.component.html',
  styleUrls: ['./crud-gestor-list.component.css'],
  providers: [ScomercialgestorService, ConfirmationService]
})
export class CrudGestorListComponent implements OnInit {
  @Input() clienteListVis: boolean;
  @Input() cerrarGestorListEvento: boolean;
  @Output() actualizarGestorListEvento = new EventEmitter();

  detalleVis: boolean;
  gestores: Gestor[]=[];
  message: string[];
  selectedValues = [];

  constructor(private sser: ScomercialgestorService, private confirmationService: ConfirmationService) {
    this.message = [];
  }

  ngOnInit() {
    this.getGestores();
  }
  estaActivo(gestor:Gestor){
    if (gestor.estado=="activo") return true;
    else return false;
  }
  getGestores(){
    this.sser.getGestores(null,null).subscribe(result=>{
      this.gestores=result;
      if(localStorage.getItem('debug')==='1') console.log("result="+JSON.stringify(result));
    });
  }
 
  actualizarLista() {
    this.ngOnInit();
  }
  borraGestor(gestor:Gestor){
    gestor.estado="inactivo";
    gestor.fechaBaja = moment().format('YYYY-MM-DD');
    gestor.fechaAlta = '0000-00-00';
    this.sser.upGestor(JSON.stringify(gestor)).subscribe();
  }
  activarGestor(gestor:Gestor){
    gestor.estado="activo";
    gestor.fechaAlta = moment().format('YYYY-MM-DD');
    gestor.fechaBaja = '0000-00-00';
    this.sser.upGestor(JSON.stringify(gestor)).subscribe();
  }
  borrarGestor(gestor:Gestor){
    this.sser.delGestor(JSON.stringify(gestor)).subscribe();
    this.actualizarLista();
  }
}

