import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { SserService } from '../servicios/sser.service';
import { Propietario } from '../comun/propietario';
import { Servicio } from '../comun/servicio';

@Component({
  selector: 'app-urgentes',
  templateUrl: './urgentes.component.html',
  styleUrls: ['./urgentes.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SserService]
})
export class UrgentesComponent implements OnInit, OnDestroy {
  @Input() urgentesVis: boolean;
  intervalId :any;
  propietario: Propietario;
  contador:number;
  visibleSidebar3;
  servicios:Servicio[];
  idCiudad: string = "";
  constructor(private sser: SserService) { }
  ngOnInit() {
    //////////////////////////////////////////////////////////////////
    // ADM para recibir todos los urgentes comentar siguiente linea //
    //////////////////////////////////////////////////////////////////
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.contador=1;
    this.getPropietario();
    this.intervalId=setInterval(() => this.getServicios(this.propietario), 1000 * 60 * 1);
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
  getPropietario () {
    var  propietarios: Propietario[];
    this.sser.getPropietario().subscribe(result => {
      propietarios = result;
      for (const prop of propietarios) {
         this.propietario = prop;
         this.getServicios(prop);
         break;
      }
    });
  }
  getServicios (prop:Propietario) {
    var  datos ={};
    if(this.idCiudad){
      datos ={'horas': prop.horasUrgente,'idCiudad': this.idCiudad};
    }else{
      datos ={'horas': prop.horasUrgente};
    }
    this.sser.getServiciosUrg(JSON.stringify(datos)).subscribe(result => {
      this.servicios = result;
      if(this.servicios.length>0){
        this.visibleSidebar3 = true;
      }
    });
  }
}
