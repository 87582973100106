import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-horarios',
  templateUrl: './mant-horarios.component.html',
  styleUrls: ['./mant-horarios.component.css']
})
export class MantHorariosComponent implements OnInit {
  @Input() mantHorariosVis: boolean;
  @Output() cerrarMantHorariosEvento = new EventEmitter();
  horarioListVis: boolean;
  horarioVis: boolean;


  constructor() { }

  ngOnInit() {
    this.mantHorariosVis = false;
    this.horarioVis = false;
    this.horarioListVis = false;
  }

}
