import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mant-coordinador',
  templateUrl: './mant-coordinador.component.html',
  styleUrls: ['./mant-coordinador.component.css']
})
export class MantCoordinadorComponent implements OnInit {
  coordinadorVis:any;
  coordinadorListVis:any;

  constructor() { }

  ngOnInit() {
  }

}
