import { Component } from '@angular/core';
import { Servicio } from '../comun/servicio';
import { Conductor } from '../comun/conductor';
import { Estados } from '../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant',
  templateUrl: './mant.component.html',
  styleUrls: ['./mant.component.css']
})
export class MantComponent implements OnInit {
  // mantVis: boolean;
  @Input() mantVis: boolean;
  @Output() cerrarMantEvento = new EventEmitter();
  mantClientesVis: boolean;
  mantEmpresaVis: boolean;
  mantHorariosVis: boolean;
  mantConductoresVis: boolean;
  mantCompaniaVis: boolean;
  mantTiposdSerVis: boolean;
  mantTarifasVis: boolean;
  mantRutasVis: boolean;
  mantEstadosVis: boolean;
  mantPreferenciasVis: boolean;
  estadoListVis: boolean;
  estadoVis: boolean;
  mantCoordinadoresVis:any;
  mantCiudadVis:any;
  
  mantUsuariosVis: boolean;
  mantVehiculoVis:boolean;
  mantCargasVis:boolean;
  MantComponent:boolean;
  mantIncidenciasVis:boolean;
  mantCategoriasVis:any;
  mantGestorVis:any;
  mantComercialVis:any;
  ser:any;
  


  constructor() {
    this.mantVis = false;
  }

  ngOnInit() {
  }

}
