export class Tarifa {
    id: string;
    nombre: string;
    idempresa: string;
    empresa: string;
    idruta: string;
    ruta: string;
    tarifa: string;
    tarifaAnulacion: string;
    tarifaConductor: string;
    tarifaConductorRed: string;
    tarifaAnuCon: string;
    tarifaParada: string;
    tarifaParadaCon: string;
    estado: string;
    fechaAlta: string;
    fechaBaja: string;
    tarificacionPorTiempo:number;
    tiempoTH:string;
    idCiudad:string;
    precioPagoTarjeta: string;
    idTipoDeServicio: string;
}
