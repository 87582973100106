import { Injectable, OnInit } from '@angular/core';
import { Sesion } from '../comun/sesion';
import { Tkn } from '../comun/tkn';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { Global } from '../comun/global';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ScomercialgestorService implements OnInit {
  sesion: Sesion = new Sesion();
  data: any;
  tkn: Tkn;
  global: Global = new Global();
  base: string = "/comercialGestor/";
  constructor(private http: HttpClient) {
    this.tkn = new Tkn();
    this.tkn.tkn = localStorage.getItem('tkn');
  }

  ngOnInit() {

  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getGestores(id: string, estado: string): Observable<any> {

    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorS.php?tkn=' + JSON.stringify(this.tkn)
      + (id ? '&gestor={"id":"' + id + '"' + (estado ? ',"estado":"activo"' : '') + '}' : '&gestor={' + (estado ? '"estado":"activo"' : '') + '}'));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (id ? '&gestor={"id":"' + id + '"' + (estado ? ',"estado":"activo"' : '') + '}' : '&gestor={' + (estado ? '"estado":"activo"' : '') + '}'));
  }

  setGestor(gestor: string): Observable<any> {

    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + gestor);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + encodeURIComponent(gestor));
  }

  upGestor(gestor: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + gestor);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + encodeURIComponent(gestor));
  }

  delGestor(gestor: string): Observable<any> {

    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + gestor);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'gestorD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&gestor=' + encodeURIComponent(gestor));
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getComerciales(id: string, estado: string): Observable<any> {

    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialS.php?tkn=' + JSON.stringify(this.tkn)
      + (id ? '&comercial={"id":"' + id + '"' + (estado ? ',"estado":"activo"' : '') + '}' : '&comercial={' + (estado ? '"estado":"activo"' : '') + '}'));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (id ? '&comercial={"id":"' + id + '"' + (estado ? ',"estado":"activo"' : '') + '}' : '&comercial={' + (estado ? '"estado":"activo"' : '') + '}'));
  }
  getComercialeS(cond: string): Observable<any> {

    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialS.php?tkn=' + JSON.stringify(this.tkn)
      + (cond ? '&comercial=' + cond : ''));

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialS.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + (cond ? '&comercial=' + encodeURIComponent(cond) : ''));
  }

  setComercial(comercial: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + comercial);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialI.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + encodeURIComponent(comercial));
  }

  upComercial(comercial: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + comercial);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialU.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + encodeURIComponent(comercial));
  }

  delComercial(comercial: string): Observable<any> {
    this.tkn.tkn = localStorage.getItem('tkn');
    if (localStorage.getItem('debug') === '1') console.log(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + comercial);

    return this.http.get(this.global.SERVIDOR_URL + this.global.ADM_SCRIPTS + this.base + 'comercialD.php?tkn=' + encodeURIComponent(JSON.stringify(this.tkn))
      + '&comercial=' + encodeURIComponent(comercial));
  }
}
