import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { SserService } from '../../servicios/sser.service';
import { MapsAPILoader } from '@agm/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { SconductoresService } from '../../servicios/sconductores.service';
import { Conductor } from '../../comun/conductor';
import * as moment from 'moment';
//import { } from '@types/googlemaps';
declare const google: any;
import { Ciudad } from '../../comun/ciudad';
import { StarifasService } from '../../servicios/starifas.service';


@Component({
  selector: 'app-ruta-cond',
  templateUrl: './ruta-cond.component.html',
  styleUrls: ['./ruta-cond.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SconductoresService, SserService, StarifasService]
})
export class RutaCondComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  markers: google.maps.Marker[] = [];
  conductores: Conductor[];
  idCondAct: string;
  extbool: boolean;
  fechaIni: string;
  fechaFin: string;
  olatLng: any;
  idCiudad: string = "";
  ciudades: Ciudad[] = [];
  constructor(private sser: SserService, private sconductores: SconductoresService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private starifas: StarifasService) { }
  ngOnInit() {
    this.idCiudad = localStorage.getItem("IdCiudad");
    this.fechaIni = moment().subtract(1, "day").format('DD/MM/YYYY HH:mm');//.format('YYYY-MM-DD HH:mm:ss')
    this.fechaFin = moment().subtract(1, "day").add(3, "hours").format('DD/MM/YYYY HH:mm');
    this.getConductores();
    this.getCiudades();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.starifas.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      let c = this.ciudades.filter(item => item.id == this.idCiudad);
      if (c && c.length > 0) {
        this.latitude = +c[0].latitud;
        this.longitude = +c[0].longitud;
        this.zoom = +c[0].zoom;
        this.getMap();
      }
    });
  }
  getMap() {
    var mapProp = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }
  find() {
    if (this.idCondAct && this.idCondAct != "1") {
      this.olatLng = null;
      this.getPosGlob();
    }
  }

  setMapType(mapTypeId: string) {
    this.map.setMapTypeId(mapTypeId)
  }
  setCenter() {
    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
    this.map.setZoom(9);
  }
  getConductores() {
    let cond = JSON.stringify({ 'idCiudad': this.idCiudad });
    this.sconductores.getConductores(cond).subscribe(result => {
      this.conductores = result;
      this.conductores.unshift({
        'nombre': 'Elija un conductor', 'id': '1', 'idsesion': '', 'telefono': '', 'email': '', 'estado': '',
        'tarifaMinima': '', 'idCompania': '', 'fechaAlta': '', 'fechaBaja': '', 'dni': '',
        'permisoConducir': '', 'visible': false, 'color': '', 'compania': '', 'tipoDeServicio': '', 'idVehiculo': '',
        urlFoto: '', idCiudad: ''
      });

      //this.getPosiciones('1');
    });
  }


  round1(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }


  recargarPos() {
    this.dropMarkets();
    this.getPosGlob();
  }


  dropMarkets() {
    for (let m of this.markers) {
      m.setMap(null);
    }
  }
  actualizaConductor(id: string) {
    this.idCondAct = id;
    this.dropMarkets();
  }

  getPosGlob() {
    let fechaIni = moment(this.fechaIni, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    let fechaFin = moment(this.fechaFin, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    let idConductor = this.idCondAct;
    let datos = { "fechaIni": fechaIni, "fechaFin": fechaFin, "idConductor": idConductor };

    let flightPlanCoordinates = [];

    this.sser.getPosGlob(JSON.stringify(datos)).subscribe(result => {
      if (result && result.length > 0) {
        for (let res of result) {

          let latLng = new google.maps.LatLng(res.latitud, res.longitud);
          if (this.olatLng == null) {
            this.olatLng = latLng;
            this.map.setCenter(this.olatLng);
          }
          flightPlanCoordinates.push(latLng);
        }
      }
      this.pintarPolyline(flightPlanCoordinates);
    });

  }

  pintarPolyline(fp: any) {
    let flightPlanCoordinates = fp;

    var flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    flightPath.setMap(this.map);

  }
}
