import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Conductor } from '../../../comun/conductor';
import { CompaniaPeticion } from '../../../comun/compania-peticion';
import { Compania } from '../../../comun/compania';
import { Acontecimiento } from '../../../comun/acontecimiento';
import { Vehiculo } from '../../../comun/vehiculo';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { SstService } from '../../../servicios/sst.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';

import { ColorPickerModule } from 'primeng/colorpicker';
import { Categoria } from '../../../comun/categoria';
import { StarifasService } from '../../../servicios/starifas.service';
import { Ciudad } from '../../../comun/ciudad';

@Component({
  selector: 'app-crud-vehiculo',
  templateUrl: './crud-vehiculo.component.html',
  styleUrls: ['./crud-vehiculo.component.css'],
  providers: [SconductoresService, SstService, StarifasService]
})
export class CrudVehiculoComponent implements OnInit {

  @Input() vehiculoVis: boolean;
  @Output() cerrarVehiculoEvento = new EventEmitter();


  color: string;
  conductor: any;
  vehiculoNuevo: Vehiculo = new Vehiculo();
  tipoDeServicios: TipoDeServicios[];
  companias: Compania[];
  ciudades: Ciudad[] = [];
  categorias: Categoria[] = [];
  selectedCategorias: Categoria[] = [];
  idCiudad: string;

  constructor(private sconductores: SconductoresService, private staf: StarifasService, private sst: SstService) { }

  ngOnInit() {
    this.getCiudades();
    this.vehiculoVis = false;
    this.getCategorias();

  }

  getCategorias() {
    this.sconductores.getCategorias(null).subscribe(result => {
      this.categorias = result;
    });
  }

  asignoTipoDeServicios(idTipoDeServicio: string) {
    this.vehiculoNuevo.idTipoDeServicio = idTipoDeServicio;
  }

  getCompanias() {
    let cond = JSON.stringify({'idCiudad': this.idCiudad});
    this.sconductores.getCompaniaS(cond).subscribe(result => {
      this.companias = result;
    });
  }

  asignoCompania(idCompania: string) {
    this.vehiculoNuevo.idCompania = idCompania;
  }
  new() {

    if (this.selectedCategorias.length > 0) {
      this.vehiculoNuevo.estado = 'activo';
      this.vehiculoNuevo.fechaAlta = moment().format('YYYY-MM-DD');
      this.vehiculoNuevo.fechaBaja = '0000-00-00';

      let vehiculo = JSON.parse(JSON.stringify(this.vehiculoNuevo, this.replacer));
      this.sconductores.setVehiculo(JSON.stringify(vehiculo)).subscribe(result => {
        let id = result.id;
        if (id) {
          let config = JSON.stringify({ 'tabla': 'veh2cat', 'conEstado': '0' });
          let datos = JSON.stringify({ 'nombre': 'idveh', 'campo': id });

          this.sst.borra(config, datos).subscribe(result => {
            for (let f of this.selectedCategorias) {
              datos = JSON.stringify({ 'idcat': f.id, 'idveh': id });
              this.sst.actualiza(config, datos).subscribe();
            }
          });
        }
      });
      this.cerrarVehiculoEvento.emit();
    }
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }
  asignoCiudad(idciudad: string) {
    this.idCiudad = idciudad;
    this.getCompanias();
  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.staf.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
    });
  }
}
