import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Preferencia } from '../../../comun/preferencia';
import { Estados } from '../../../comun/estados';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Categoria } from '../../../comun/categoria';

@Component({
  selector: 'app-crud-categorias-list',
  templateUrl: './crud-categorias-list.component.html',
  styleUrls: ['./crud-categorias-list.component.css'],
  providers: [SconductoresService]
})
export class CrudCategoriasListComponent implements OnInit {
  @Input() categoriasListVis: boolean;
  @Input() detalleCategoriasvisible: boolean;
  @Output() cerrarCategoriasListEvento = new EventEmitter();
  @Input() categoriasVis: boolean;
  @Output() cerrarCategoriasEvento = new EventEmitter();

  categorias: Categoria[]=[];

  message: string[];
  msg: string;

  constructor(private sconductores: SconductoresService) {
    this.message = [];
  }

  ngOnInit() {
    this.categoriasListVis = false;
    this.detalleCategoriasvisible = false;
    this.getCategorias();
  }

  getCategorias() {
    this.sconductores.getCategorias(null).subscribe(result => {
      this.categorias = result;
    });
  }

  actualizarLista() {
    // if(localStorage.getItem('debug')==='1') console.log('------>>>>>>>>');
    this.ngOnInit();
  }
 }
