import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Ciudad } from '../../comun/ciudad';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { StarifasService } from '../../servicios/starifas.service';

@Component({
  selector: 'app-mant-rutas',
  templateUrl: './mant-rutas.component.html',
  styleUrls: ['./mant-rutas.component.css'],
  providers: [StarifasService]
})
export class MantRutasComponent implements OnInit {
  @Input() mantRutasVis: boolean;
  @Output() cerrarMantRutasEvento = new EventEmitter();
  rutaListVis: boolean;
  rutaVis: boolean;
  ciudades:Ciudad[] = [];

  constructor(private sser: StarifasService) { }

  ngOnInit() {
    this.mantRutasVis = false;

  }
  getCiudades() {
    const ciu = new Ciudad();
    ciu.estado = 'activo';
    this.sser.getCiudades(ciu).subscribe(result => {
      this.ciudades = result;
      //if(localStorage.getItem('debug')==='1') console.log('----------------------------------->>>>>>>>>>>'+JSON.stringify(this.ciudades));
    });
  }
}
