import { Component } from '@angular/core';
import { SsesionService } from '../../../../servicios/ssesion.service';
import { Servicio } from '../../../../comun/servicio';
import { Conductor } from '../../../../comun/conductor';
import { Estados } from '../../../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-lista-usuarios-comerciales',
  templateUrl: './lista-usuarios-comerciales.component.html',
  styleUrls: ['./lista-usuarios-comerciales.component.css'],
  providers: [SsesionService, ConfirmationService]
})
export class ListaUsuariosComercialesComponent implements OnInit {
  @Input() listaUsuariosClientesVis: boolean;
  @Output() cerrarlistaUsuariosComercialEvento = new EventEmitter();
  usus = [];
  visible = [];

  constructor(private ssesion: SsesionService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getUsuarios();
  }
  getUsuarios() {
    this.ssesion.getUsuCom().subscribe(result => {
      this.usus = result;
      for (const usu of this.usus) {
        this.visible[usu.id] = false;
      }
    });
  }
  borrar(idCom) {
    console.log('::::>>>>>' + idCom);
    this.confirmationService.confirm({
      message: 'Va a quitar el acceso a la app comercial a este usuario ¿Esta seguro?',
      accept: () => {
        this.ssesion.usuComD(JSON.stringify(idCom)).subscribe();
        this.cerrarlistaUsuariosComercialEvento.emit();
      },
      reject: () => {
        this.cerrarlistaUsuariosComercialEvento.emit();
      }
    });
  }
}
