import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import {HttpModule} from '@angular/http';
import { SempresasService } from '../../../servicios/sempresas.service';
import { SconductoresService } from '../../../servicios/sconductores.service';
import { Servicio } from '../../../comun/servicio';
import { Conductor } from '../../../comun/conductor';
import { Cliente } from '../../../comun/cliente';
import { Estados } from '../../../comun/estados';
import { Global } from '../../../comun/global';
import { Cargas } from '../../../comun/cargas';
import { TipoDeServicios } from '../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Empresas } from '../../../comun/empresas';
import { SserService } from '../../../servicios/sser.service';
@Component({
  selector: 'app-crud-cargas-list',
  templateUrl: './crud-cargas-list.component.html',
  styleUrls: ['./crud-cargas-list.component.css'],
  providers: [SserService]
})
export class CrudCargasListComponent implements OnInit {
  @Input() cargasListVis: boolean;
  @Input() cerrarCargasListEvento: boolean;

  cargas:Cargas;

  constructor(private sser:SserService) { }

  ngOnInit() {
    this.getCargas();
  }
  getCargas(){
    this.sser.getCargas(null).subscribe(result => {
      this.cargas = result;
    });
  }
}
