import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { HttpModule } from '@angular/http';
import { SempresasService } from '../../../../servicios/sempresas.service';
import { SconductoresService } from '../../../../servicios/sconductores.service';
import { Compania } from '../../../../comun/compania';
import { Coordinador } from '../../../../comun/coordinador';
import { CompaniaPeticion } from '../../../../comun/compania-peticion';
import { Acontecimiento } from '../../../../comun/acontecimiento';
import { Estados } from '../../../../comun/estados';
import { TipoDeServicios } from '../../../../comun/tipo-de-servicios';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogModule } from 'primeng/primeng';
import { Console } from '@angular/core/src/console';
import { ColorPickerModule } from 'primeng/colorpicker';
import { Vehiculo } from '../../../../comun/vehiculo';
import { Global } from '../../../../comun/global';
import { SserService } from '../../../../servicios/sser.service';
import {Md5} from 'ts-md5/dist/md5';


@Component({
  selector: 'app-detalle-coordinador',
  templateUrl: './detalle-coordinador.component.html',
  styleUrls: ['./detalle-coordinador.component.css'],
  providers: [SconductoresService,SempresasService,SserService]
})
export class DetalleCoordinadorComponent implements OnInit {

  @Input() coordinador: Coordinador;
  detalleCoordinadorvisible: boolean;

  @Output() guardarCoordinadorEvent = new EventEmitter();
  @Output() cerrarDetalleVisEvento = new EventEmitter();
  @Output() actualizarCoordinadorListEvento = new EventEmitter();

  dealleVis: boolean;
  companias: Compania[];
  contrasenia:string="";

  estados: Estados[];
  coordinadorNuevo: Coordinador  = new Coordinador();

  imgCond:string = 'assets/img/nohayfoto.jpg';
  uploadedFiles: any[] = [];
  fileToUpload: File = null;
  global:Global = new Global();



  constructor(private sser: SserService,private sempresas: SempresasService, private sconductores: SconductoresService) { }

  ngOnInit() {
    this.coordinadorNuevo = JSON.parse(JSON.stringify(this.coordinador, this.replacer));

    this.getCompanias();
    this.getEstadosbd();
  }
  replacer(key, value) {
    if (value === null) {
      return undefined;
    }
    else {
      return value;
    }
  }

   getCompanias() {
    const companiaPeticion: CompaniaPeticion = new CompaniaPeticion();

    companiaPeticion.estado = 'activo';
    this.sconductores.getCompanias(companiaPeticion).subscribe(result => {
      this.companias = result;
    });
  }
  asignoCompania(idCompania: string) {
    this.coordinadorNuevo.idCompania = idCompania;
  }
  asignoEstado(estado: string) {
    this.coordinadorNuevo.estado = estado;
    if (estado === 'inactivo') {
      this.coordinadorNuevo.fechaBaja = moment().format('YYYY-MM-DD');
    } else {
      this.coordinadorNuevo.fechaBaja = '0000-00-00';
      this.coordinadorNuevo.fechaAlta = moment().format('YYYY-MM-DD');
    }
  }
  getEstadosbd() {
    this.sempresas.getEstadosbd().subscribe(result => {
      this.estados = result;
    });
  }

  actualizaCoordinador() {

    this.coordinador=this.coordinadorNuevo;
    this.coordinadorNuevo.visible=null;
    if (this.contrasenia=="") this.coordinadorNuevo.contrasenia = null;
    else this.coordinadorNuevo.contrasenia = Md5.hashStr(this.contrasenia.toString()).toString();
     
    this.coordinadorNuevo = JSON.parse(JSON.stringify(this.coordinadorNuevo, this.replacer));
    

    this.sconductores.upCoordinador(JSON.stringify(this.coordinadorNuevo)).subscribe();
    this.cerrarDetalleVisEvento.emit();
    this.actualizarCoordinadorListEvento.emit();
  } 
}
