import { Component } from '@angular/core';
import { Servicio } from '../../comun/servicio';
import { Conductor } from '../../comun/conductor';
import { Estados } from '../../comun/estados';
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mant-comercial',
  templateUrl: './mant-comercial.component.html',
  styleUrls: ['./mant-comercial.component.css']
})
export class MantComercialComponent implements OnInit {
  
  @Output() cerrarMantGestorEvento = new EventEmitter();
  gestorListVis: boolean;
  gestorVis: boolean;

  constructor() { }

  ngOnInit() {

    this.gestorVis = false;
    this.gestorListVis = false;
  }

}